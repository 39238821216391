<template>
<div class="container mb-1 ms-1" >
          <a
          @click="$router.back()"
          class="text-primary fw-bold text-decoration-none cursor-pointer"
        >
          <i class="fas fa-angle-left"></i> Back
        </a>
</div>
    <div class="container">
        <div class="card p-3 w-100 shadow rounded ">
    <div class="nav-wrapper  position-relative end-0 d-flex justify-content-between align-items-center" >
        <div class="d-flex align-items-center">
                <div class="me-4">   
                    <p class="m-0" >{{Username}}</p>
                       <p class="m-0" > Account ID: {{Code}}</p>
                </div>
                <div :class="Usertstatus===1?'lblprimary':'lblsuspend'">{{Usertstatus===1?"Active":"Suspend"}}</div>
           
        </div>
   <ul class="nav nav-pills w-50 nav-fill p-1" role="tablist">
         <li class="nav-item mx-2" @click="active = 'account'" >
         <a class="nav-link mb-0  px-1 py-1  "   :class="{'nav-active':active === 'account'}" data-bs-toggle="tab" href="#" role="tab" aria-controls="profile" aria-selected="true">
            Account
         </a>
      </li>
      <li class="nav-item mx-2"  @click="active = 'addresses'">
         <a class="nav-link mb-0 px-1 py-1 " :class="{'nav-active':active === 'addresses'}" data-bs-toggle="tab" href="#" role="tab" aria-controls="profile" aria-selected="true">
         Addresses
         </a>
      </li>
      <li class="nav-item mx-2"  @click="active = 'order'" >
         <a class="nav-link mb-0 px-1 py-1  " :class="{'nav-active':active === 'order'}" data-bs-toggle="tab" href="#" role="tab" aria-controls="dashboard" aria-selected="false">
        Order
         </a>
      </li>
    </ul>
  </div>
        </div>


<div class="card rounded shadow mt-3">
  <div class="card-body">
    
   <Account v-if="active ==='account'" :userId="decodeURIComponent(this.$route.params.id)" ></Account>
   <DeliveryAddressList v-if="active ==='addresses'" :personId="decodeURIComponent(this.$route.params.id)"></DeliveryAddressList>
   <OrderVue  v-if="active ==='order'" :customerId="decodeURIComponent(this.$route.params.id)" ></OrderVue>
</div>
</div>

    </div>
    
</template>
<style >
.nav-active{

    background-color:var(--bs-primary)!important;
    color: white !important;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%) !important;
}
.lblprimary{
    display: inline-block;
    font-weight: 700;
    line-height: 1.667;
    color: white;
    text-align: center;
    vertical-align: middle;
        padding: 0.625rem 1.5rem;
    font-size: 0.75rem;
    border-radius: 8rem;
background: rgb(78,167,195);
background: linear-gradient(13deg, rgba(78,167,195,1) 35%, rgba(0,255,188,1) 100%);
}
</style>
<script >
import Account from "../views/Account.vue"
import OrderVue from "./Order.vue"
import DeliveryAddressList from "../views/components/Address/DeliveryAddressList.vue"
export default {
data(){
  return { 
    active: "account",
    Username:null,
    Code:null,
    Usertstatus:null
  }
},
components:{
    Account,
    OrderVue,
    DeliveryAddressList
},
methods:{
        async getdata() {
      await this.postApi("api/user/Detail", {
        id: decodeURIComponent(this.$route.params.id),
      }).then(async(result) => {
        if (result.flag === 1) {
          this.Username = result.data[0].name;
          this.Code=result.data[0].userCode;    
          this.Usertstatus=result.data[0].status;    
        }else{
          alert(result.msg);
        }
      });
    },
},
beforeMount(){
    this.getdata()
}

}
</script>
