<template>
  <Tag v-if="status === 'pending'" type="danger" class="my-auto text-nowrap"
    >Pending Payment</Tag
  >
  <Tag v-else-if="status === 'ongoing'" type="success" class="my-auto text-nowrap"
    >On Going</Tag
  >
  <Tag v-else-if="status === 'complete'" type="secondary" class="my-auto text-nowrap"
    >Completed</Tag
  >
  <Tag v-else-if="status === 'expired'" type="light" class="my-auto text-nowrap">Expired</Tag>
</template>

<script>
import Tag from "./Tag.vue";
export default {
  components: { Tag },
  props: { status: String },
};
</script>
