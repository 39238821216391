<template>
  <div class="d-flex justify-content-end">
    <button class="btn btn-outline-secondary" @click="clickAddNewAddress">
      Add New Address
    </button>
  </div>
  <div
    v-for="address in dtAddress"
    :key="address.id"
    class="address-list-item my-2"
    @click="clickAddress(address.id)"
    style="cursor: pointer"
  >
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">
          <div class="fw-bold">{{ address.name }}</div>
          <div v-if="address.isDefault" class="badge-addr-default bg-gray-200">
            Default
          </div>
        </div>
        <div>+{{ address.dialCode + address.mobile }}</div>
        <div>{{ address.buildingName }}</div>
        <div>{{ address.floorUnit }}</div>
        <div>{{ address.address }}</div>
      </div>
    </div>
  </div>
  <div
    v-if="this.dtAddress == null || this.dtAddress.length === 0"
    class="text-muted"
  >
    No record found.
  </div>
  <DeliveryAddressNew
    ref="DeliveryAddressNew"
    :personId="personId"
    @list-address="listAddresses"
  />
</template>

<script>
import DeliveryAddressNew from "@/views/components/Address/DeliveryAddressNew.vue";
export default {
  props: {
    personId: { type: String, required: true },
  },
  components: { DeliveryAddressNew },
  data() {
    return {
      dtAddress: null,
    };
  },
  methods: {
    listAddresses() {
      this.postApi("api/UserAddress/GetAddresses", { personId: this.personId })
        .then((result) => {
          console.log(result);
          if (result.flag == 1) {
            this.dtAddress = result.data.dtAddress;
          } else {
            alert(result.msg);
          }
        })
        .finally(() => {});
    },
    clickAddress(addressId) {
      this.$refs.DeliveryAddressNew.show(addressId);
    },
    clickAddNewAddress() {
      this.$refs.DeliveryAddressNew.show();
    },
  },
  mounted() {
    this.listAddresses();
  },
};
</script>
<style scoped>
.address-list-item .card {
  border: 1px solid rgb(222, 226, 230);
}
.address-list-item:hover .card,
.address-list-item.active .card,
.address-list-item:hover .badge-addr-default {
  background-color: green !important;
  color: #fff;
}
.badge-addr-default {
  background-color: #ccc;
  padding: 5px 8px;
  border-radius: 5px;
}
</style>