<template>
  <div>
    <slot name="header">
      <div class="row m-0">
        <div class="fw-bold col-lg-3"></div>
        <div class="fw-bold col-lg-6">Activity</div>
        <div class="fw-bold col-lg-1">Pax</div>
        <div class="fw-bold col-lg-2">Price</div>
        <!-- <div class="fw-bold col d-none d-sm-block">Action</div> -->
      </div>
    </slot>
    <div v-if="items && items.length > 0">
      <template v-for="i in items" :key="i.id">
        <slot name="content" :data="i">
          <CartItem :data="i" />
        </slot>
      </template>
    </div>
    <template v-else>
      <slot name="empty">
        <div class="row mx-0">
          <div class="col-12 fst-italic">No item found.</div>
        </div>
      </slot>
    </template>
  </div>
</template>

<script>
import CartItem from "./CartItem.vue";

export default {
  components: { CartItem },
  props: {
    items: Array,
  },
};
</script>
