<template>
  <span class="py-1 px-3 mx-2" :class="classes[type]">
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    type: { type: String, default: "secondary" },
  },
  data() {
    return {
      classes: {
        primary: "bg-primary text-white",
        secondary: "bg-secondary text-white",
        danger: "bg-danger text-white",
        success: "bg-success text-white",
        light: "bg-light text-black",
      },
    };
  },
};
</script>

<style scoped>
span {
  border-radius: 50px;
  font-size: small;
}
</style>
