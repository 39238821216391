<template>
  <div ref="dragList" class="drag-list">
    <slot></slot>
  </div>
</template>

<script>
export default {
  emits: ["change"],
  data() {
    return {
      draggable: null,
      observer: null,
    };
  },

  methods: {
    items() {
      // return HTML DOM element
      return this.draggable.$items;
    },
  },

  mounted() {
    this.draggable = new DragNSwap({
      container: this.$refs.dragList,
      itemClass: "drag-item",
      dragStartClass: "drag-start",
      dragEnterClass: "drag-enter",
    });
    this.draggable.handleChange = (event, dragItem, dropItem) =>
      this.$emit("change", event, dragItem, dropItem);
    this.draggable.init();

    //https://austingil.com/watching-changes-vue-js-component-slot-content/
    // monitor DOM changes(add/delete) in slot
    const observer = new MutationObserver((mutations) => {
      // console.log("mutations", mutations);
      const draggable = this.draggable;
      mutations.forEach((mutationRecord) => {
        // [].forEach.call(mutationRecord.addedNodes, function (node) {
        //   if (node.nodeType === Node.ELEMENT_NODE) {
        //     // console.log('node',node);
        //     node.classList.add(draggable.$config.itemClass);
        //   }
        // });
        draggable.bind(mutationRecord.addedNodes);
        draggable.updateItems();
      });
    });
    observer.observe(this.$refs.dragList, { childList: true });
    this.observer = observer;
  },
  beforeUnmount() {
    this.observer.disconnect();
  },
};

// source: https://codepen.io/acauamontiel/pen/NPBWyM
class DragNSwap {
  constructor(config) {
    this.$activeItem = null;
    this.$config = config;
    this.$container = config.container;
    this.dragStartClass = config.dragStartClass;
    this.dragEnterClass = config.dragEnterClass;
    this.updateItems();
  }
  updateItems() {
    this.$items = this.$container.querySelectorAll("." + this.$config.itemClass);
  }
  removeClasses() {
    [].forEach.call(
      this.$items,
      function ($item) {
        $item.classList.remove(this.dragStartClass, this.dragEnterClass);
      }.bind(this)
    );
  }

  onDragStart(_this, event) {
    _this.$activeItem = this; // "_this" = DragNSwap object, "this" = event DOM element

    this.classList.add(_this.dragStartClass);
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", this.innerHTML);
  }
  onDragEnd(_this) {
    this.classList.remove(_this.dragStartClass);
  }
  onDragEnter(_this) {
    this.classList.add(_this.dragEnterClass);
  }
  onDragLeave(_this) {
    this.classList.remove(_this.dragEnterClass);
  }
  onDragOver(_this, event) {
    if (event.preventDefault) {
      event.preventDefault();
    }

    event.dataTransfer.dropEffect = "move";

    return false;
  }
  onDrop(_this, event) {
    if (event.stopPropagation) {
      event.stopPropagation();
    }

    if (_this.$activeItem !== this) {
      //   console.log('drop',this);
      const activeSibling = _this.$activeItem.nextElementSibling;
      const targetSibling = this.nextElementSibling;
      _this.$container.insertBefore(_this.$activeItem, targetSibling);
      _this.$container.insertBefore(this, activeSibling);

      if (_this.handleChange) _this.handleChange(event, _this.$activeItem, this);
    }

    _this.removeClasses();

    return false;
  }
  bindDragEvents(element) {
    if (
      element.nodeType === Node.ELEMENT_NODE &&
      element.getAttribute("draggable") === "true" &&
      element.getAttribute("draggable-event") !== "true" // prevent duplicate event listener
    ) {
      element.addEventListener("dragstart", this.onDragStart.bind(element, this), false);
      element.addEventListener("dragend", this.onDragEnd.bind(element, this), false);
      element.addEventListener("dragover", this.onDragOver.bind(element, this), false);
      element.addEventListener("dragenter", this.onDragEnter.bind(element, this), false);
      element.addEventListener("dragleave", this.onDragLeave.bind(element, this), false);
      element.addEventListener("drop", this.onDrop.bind(element, this), false);

      element.classList.add(this.$config.itemClass);
      element.setAttribute("draggable-event", true);
    }
  }
  bind($items) {
    [].forEach.call($items, this.bindDragEvents.bind(this));
  }
  init() {
    this.bind(this.$items);
  }
}
</script>

<style scoped>
:slotted([draggable]) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.drag-list {
  overflow: hidden;
}
:slotted(.drag-item) {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  -o-transition: 0.25s;
  -ms-transition: 0.25s;
  transition: 0.25s;
}
:slotted(.drag-start) {
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
:slotted(.drag-enter) {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}
</style>
