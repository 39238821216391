<template>
  <div>
    <div class="h5">
      Change Delivery Date & Time
      <span class="float-end me-1 cursor-pointer" @click="$emit('cancel')">
        <i class="fa fa-times"></i>
      </span>
    </div>
    <VmdAlert
      class="font-weight-light show"
      color="danger"
      :dismissible="error.show"
      v-on:update="(e) => (error.show = e)"
    >
      <div v-html="error.msg"></div>
    </VmdAlert>
    <div class="row m-0">
      <MultiDatePicker
        ref="datePicker"
        class="col"
        :min-date="minDate"
        :max-date="maxDate"
        :marker="cMarker"
        :dates="cSelectedDates"
        :disabled="cDisableAllDates"
        :disabled-weekdays="[1, 7]"
        :disabled-dates="cDisabledDates"
        @change="onChangeSelectedDates"
      />
      <div class="col-lg-6 bg-primary text-white p-2 position-relative">
        <select
          class="form-select bg-white"
          v-model="mealTime.current"
          @change="onChangeMealTime"
        >
          <option value="L">All Lunch</option>
          <option value="D">All Dinner</option>
          <option value="LD">All Lunch & Dinner</option>
          <option value="C">Custom</option>
        </select>

        <small class="mt-3 d-block" style="opacity: 0.75"
          >(Selected {{ cMealSelected }}/{{ totalMeal }} Meal)</small
        >
        <div class="custom-date-list px-4 pb-2 pb-lg-0">
          <div
            class="row"
            v-show="mealTime.current === 'C'"
            v-for="d in cSelectedDates"
            :key="d.toDateString()"
          >
            <div
              class="col-6 p-0 my-2 d-flex align-content-center justify-content-around"
            >
              <span class="m-auto">
                {{ d.format() }}
              </span>
            </div>
            <div class="col-6 my-2">
              <select
                class="form-select bg-white"
                :class="[d < new Date(minDate) && 'disabled']"
                v-model="this.dateList[d.toDateString()]"
                @change="validate()"
                :disabled="d < new Date(minDate)"
              >
                <option value="L">Lunch</option>
                <option value="D">Dinner</option>
                <option value="LD">Lunch & Dinner</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <SubmitButton
        class="btn btn-primary mx-auto col-6"
        :disabled="cMealSelected < totalMeal"
        @click="onComplete"
      >
        {{ btnText }}
      </SubmitButton>
    </div>
  </div>
</template>

<script>
import SubmitButton from "./SubmitButton.vue";
import VmdAlert from "../../../components/VmdAlert.vue"; // https://vue3datepicker.com/
import MultiDatePicker from "../../../components/MultiDatePicker.vue";

export default {
  components: { SubmitButton, VmdAlert, MultiDatePicker },
  emits: ["complete", "cancel", "error", "init-complete"],
  props: {
    btnText: { type: String, default: "OK" },
    fnSave: Function,
  },
  data() {
    return {
      areaId: "",
      itemId: "",
      dateList: {}, // object with format = { date.toDateString(): 'L'/'D'/'LD', ... }, each key is a selected date
      mealTime: { current: "L", previous: "L" }, // modelValue for top right <select>

      minDate: "",
      maxDate: "",
      offDay: [],
      totalMeal: 0,
      deliveryCount: { dates: null, lunchExceed: [], dinnerExceed: [] },
      maxLunch: null,
      maxDinner: null,

      error: { show: false, msg: "" },
    };
  },
  computed: {
    cDisabledDates() {
      // 1. off day
      // 2. fully booked date
      return this.offDay.concat(this.cDeliveryFull);
    },
    cDisableAllDates() {
      if (this.mealTime.current === "") return true; // does not selected meal time yet
      if (!this.cHasSufficientMeal) return true; // does not have sufficient meal

      return false;
    },
    cSelectedDates() {
      return Object.keys(this.dateList)
        .map((d) => new Date(d))
        .sort((a, b) => a.valueOf() - b.valueOf());
    },

   

    cDefaultMealTime() {
      return this.mealTime.current === "C" ? this.mealTime.previous : this.mealTime.current;
    },
    cMealSelected() {
      // number of meal selected
      return Object.values(this.dateList).reduce(
        (prev, curr) => (prev += curr === "LD" ? 2 : 1),
        0
      );
    },
    cHasSufficientMeal() {
      return this.cMealSelected < this.totalMeal;
    },

    cDeliveryFull() {
      // for calendar disabled dates
      if (this.deliveryCount.dates) {
        if (this.mealTime.current === "L") {
          return this.deliveryCount.lunchExceed;
        } else if (this.mealTime.current === "D") {
          return this.deliveryCount.dinnerExceed;
        } else if (this.mealTime.current === "LD") {
          const dates = this.deliveryCount.lunchExceed.concat(
            this.deliveryCount.dinnerExceed
          );
          return [...new Set(dates)];
        }
      }
      return [];
    },
    cDeliveryFullSelected() {
      // to inform user selected dates already full
      if (this.deliveryCount.dates) {
        const lunchFull = this.deliveryCount.lunchExceed.map((d) => d.toDateString());
        const dinnerFull = this.deliveryCount.dinnerExceed.map((d) => d.toDateString());
        const fullyBooked = this.cSelectedDates.filter((d) => {
          const dateStr = d.toDateString();
          return (
            (this.dateList[dateStr] !== "D" && lunchFull.includes(dateStr)) ||
            (this.dateList[dateStr] !== "L" && dinnerFull.includes(dateStr))
          );
        });
        return fullyBooked;
      }

      return [];
    },

    cMarker() {
      const offDay = this.offDay.map((d) => ({
        popover: { label: "Day Off" },
        bar: { style: { backgroundColor: "gray", height: "4px" } },
        dates: d,
      }));
      const deliveryLimit = this.cDeliveryFull.map((d) => ({
        popover: { label: "Fully Booked" },
        bar: { style: { backgroundColor: "red", height: "4px" } },
        dates: d,
      }));

      return offDay.concat(deliveryLimit || []);
    },
  },

  methods: {
    async loadDeliveryCount() {
      // ==================== get daily delivery count ====================
      const url = `api/order/delivery-count?areaid=${encodeURIComponent(
        this.areaId
      )}&itemid=${encodeURIComponent(this.itemId)}`;
      const res = await this.getApi(url);
      // console.log('order/delivery-count', res);
      if (res.flag !== 1) {
        this.$emit("error", res.msg);
        return;
      }

      const data = res.data[0];
      const dates = data.dates.map((d) => ({
        date: new Date(d.deliveryDate),
        lunch: d.lunch,
        dinner: d.dinner,
      }));
      this.maxLunch = data.maxLunch;
      this.maxDinner = data.maxDinner;

      this.deliveryCount = {
        dates,
        lunchExceed: dates.filter((d) => d.lunch >= this.maxLunch).map((d) => d.date), // user cant select these dates for lunch
        dinnerExceed: dates.filter((d) => d.dinner >= this.maxDinner).map((d) => d.date), // user cant select these dates for dinner
      };

      this.$emit("init-complete");
    },
    validate() {
      if (this.cMealSelected > this.totalMeal) {
        this.showError("Meal limit exceed.");
        return false;
      }
      if (this.cDeliveryFullSelected?.length > 0) {
        this
          .showError(`These dates are fully booked, please choose other dates for your meal:<br />
                <b>
                  ${this.cDeliveryFullSelected.reduce(
                    (prev, curr, idx) =>
                      (prev += (idx === 0 ? "" : ", ") + curr.format()),
                    ""
                  )}
                </b>`);
        return false;
      }

      this.hideError();
      return true;
    },
    showError(msg) {
      this.error.show = true;
      this.error.msg = msg;
    },
    hideError() {
      this.error.show = false;
    },

    // ===== event handler =====
    onChangeMealTime(event) {
      const mt = event.target.value;
      if (mt !== "C")
        // if not custom, change all meal time
        this.cSelectedDates.forEach((d) => {
          if (d >= new Date(this.minDate)) this.dateList[d.toDateString()] = mt;
        });

      this.validate();
    },
    onChangeSelectedDates(newDates) {
      // when user selected a date on calendar, update dateList
      const newList = {};
      if (newDates) {
        newDates.forEach((d) => {
          const dDate = d.toDateString();
          newList[dDate] =
            this.dateList[dDate] != null ? this.dateList[dDate] : this.cDefaultMealTime;
        });
      }

      this.dateList = newList;
      this.validate();
    },
    async onComplete(stopLoading) {
      if (!this.validate()) {
        stopLoading();
        return;
      }

      const returnData = {
        dateList: this.dateList,
        mealSelected: this.cMealSelected,
      };

      if (this.fnSave) {
        await this.fnSave(returnData);
      } else {
        this.$emit("complete", returnData);
      }

      stopLoading();
    },

    // ===== public method =====
    async bind(dateList = {}, totalMeal = 0, areaId = "", itemId = "") {
      this.dateList = Object.assign({}, dateList);

      const meals = Object.values(dateList);
      if (meals.every((m) => m === "L")) this.mealTime.current = "L";
      else if (meals.every((m) => m === "D")) this.mealTime.current = "D";
      else if (meals.every((m) => m === "LD")) this.mealTime.current = "LD";
      else this.mealTime.current = "C";

      this.totalMeal = totalMeal;
      this.areaId = areaId;
      this.itemId = itemId;
      await this.loadDeliveryCount();

      if (this.cSelectedDates.length > 0) this.validate();
      await this.$nextTick();
      this.$refs.datePicker.moveTo(this.minDate); // show calendar first date month
    },
  },
  watch: {
    "mealTime.current": function(newTime, oldTime) {
      if (oldTime !== "C") this.mealTime.previous = oldTime;
    },
  },

  async created() {
    // ==================== get off day ====================
    let res = await this.getApi("api/order/off-day");
    if (res.flag === 1) {
      this.offDay = res.data.map((d) => new Date(d.off));
    } else {
      this.$emit("error", res.msg);
      return;
    }

    res = await this.getApi("api/order/delivery-min-date");
    if (res.flag === 1) {
      this.minDate = new Date(res.data).toDateString();
      this.maxDate = new Date(this.minDate).addDays(365).toDateString();
    } else {
      this.$emit("error", res.msg);
      return;
    }
  },
};
</script>

<style scoped>
.custom-date-list {
  overflow: hidden auto;
  width: 95%;
}
@media (min-width: 992px) {
  .custom-date-list {
    height: 60%;
    position: absolute;
  }
}
.custom-date-list span {
  font-size: larger;
}
</style>

