<script>
/* Notes:
- Delivery Fee: UI select Yes or No, but backend store as number of delivery
*/
import { AgGridVue } from "@ag-grid-community/vue3";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "jquery/dist/jquery.min.js";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import paxinput from "../components/Paxinput.vue";
import $ from "jquery";
// Import Vue FilePond
import vueFilePond, { setOptions } from "vue-filepond";
import VmdAlert from "@/components/VmdAlert.vue";
// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageResize from "filepond-plugin-image-resize";

import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import "axios";
import { Modal } from "bootstrap";



// more datatable information  https://www.ag-grid.com/vue-data-grid/getting-started/
// var equalsFilterParams = {
//    suppressAndOrCondition: true,

//   filterOptions: [
//     'equals',
//     {
//       displayKey: 'betweenExclusive',
//       displayName: 'Between (Exclusive)',
//       predicate: ([fv1, fv2], cellValue) => {
//         if (cellValue == null) return true;
//         var parts = cellValue.split('/');
//         var cellDate = new Date(
//           Number(parts[2]),
//           Number(parts[1] - 1),
//           Number(parts[0])
//         );
//         return (
//           cellDate.getTime() > fv1.getTime() &&
//           cellDate.getTime() < fv2.getTime()
//         );
//       },
//       numberOfInputs: 2,
//     },
//   ],
//   comparator: function (filterLocalDateAtMidnight, cellValue) {
//     var dateAsString = cellValue;
//     if (dateAsString == null) return -1;
//     var dateParts = dateAsString.split('/');
//     var cellDate = new Date(
//       Number(dateParts[2]),
//       Number(dateParts[1]) - 1,
//       Number(dateParts[0])
//     );
//     if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
//       return 0;
//     }
//     if (cellDate < filterLocalDateAtMidnight) {
//       return -1;
//     }
//     if (cellDate > filterLocalDateAtMidnight) {
//       return 1;
//     }
//   },
//   browserDatePicker: true,
// };

const ToggleComponent = {
  template: '<Toggle   v-model="value" on-label="On" off-label="Off" />'};



const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginImageTransform,
  FilePondPluginImageResize
);
setOptions({
  server: {
    url: process.env.VUE_APP_UPLOAD_URL + "api/wkrUpload/upload",
    process: (fieldName, file, metadata, load, error, progress, abort) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fieldName", file.name);
      formData.append("cmd", "1");

      const request = new XMLHttpRequest();
      request.open(
        "POST",
        process.env.VUE_APP_UPLOAD_URL + "api/wkrUpload/upload"
      );
      // Setting computable to false switches the loading indicator to infinite mode
      request.upload.onprogress = (e) => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      request.onload = function () {
        if (request.status >= 200 && request.status < 300) {
          load(request.responseText); // the load method accepts either a string (id) or an object
        } else {
          error("Error during Upload!");
        }
      };

      request.send(formData);
      //expose an abort method so the request can be cancelled
      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button
          request.abort();
          // Let FilePond know the request has been cancelled
          abort();
        },
      };
    }, // we've not implemented these endpoints yet, so leave them null!
  },
});
export default {
  data() {
    return {
      api: "api/product",
      value:true,
      paginationPageSize: 20,
      rowHeight: 80,
      columnDefs: [],
      rowData: [],
      gridApi: null,
      columnApi: null,
      myFiles: [],
      formData: {
        cmd: 1,
        productid: null,
        Preview: 0,
        productType: 1, //default tiffin
        Productname: { value: null }, //formData.Productname.value
        numberofPack: { value: 1 },
        delivery: 0,
        visiable:true,
        Discount: { value: 0 }, //formData.Discount.value
        price: [
          //formData.price
          { Pxid: null, Pax: 1, Price: 0.0 },
          { Pxid: null, Pax: 2, Price: 0.0 },
          { Pxid: null, Pax: 3, Price: 0.0 },
          { Pxid: null, Pax: 4, Price: 0.0 },
          { Pxid: null, Pax: 5, Price: 0.0 },
          { Pxid: null, Pax: 6, Price: 0.0 },
          { Pxid: null, Pax: 7, Price: 0.0 },
          { Pxid: null, Pax: 8, Price: 0.0 },
          { Pxid: null, Pax: 9, Price: 0.0 },
          { Pxid: null, Pax: 10, Price: 0.0 },
        ],
        photolist: [],
        photoRemove: [],
        content: null,
      },
      Validation: {
        vldProductname: true, //Validation.vldProductname
        vldprice: [true, true, true, true, true, true, true, true, true, true], //Validation.vldprice
      },
      SuccessAlert: false,
      dangerAlert: false,
      returnmsg: null,
      userRemove: false,
      deleteProductModal: null,
      deleteProductName: "",
      deleteProductError: { show: false, msg: "" },
    };
  },
  components: {
    AgGridVue,
    QuillEditor,
    FilePond,
    paxinput,
    VmdAlert,
    ToggleComponent
  },

  setup() {
    return {
      defaultColDef: {
        resizable: true,
      },
      modules: [ClientSideRowModelModule],
    };
  },
  methods: {
    async getdata() {
      await this.getApi(this.api).then((result) => {
        if (result.flag === 1) {
           //console.log(result.data);
          this.rowData = result.data;
        } else {
          alert(result.msg);
        }
      });
    },
    async deleteProduct() {
      await this.postApi("api/product/delete", {
        productId: this.formData.productid,
        masterPassword: document.getElementById("txtMasterPassword").value,
      }).then((result) => {
        if (result.flag === 1) {
          // console.log(result.data);
          this.getdata();
          this.deleteProductModal.hide();
          this.SuccessAlert = true;
          this.returnmsg = "Product is Deleted.";
        } else {
          //alert(result.msg);
          this.deleteProductError.show = true;
          this.deleteProductError.msg = result.msg;
        }
      });
    },
    handleFilePondInit: function () {
      console.log("FilePond has initialized");

      // example of instance method call on pond reference
      this.$refs.pond.getFiles();
    },

    async saveProduct(Preview) {
      this.formData.Preview = Preview;

      if (this.checkValidation()) {
        this.dangerAlert = true;
        this.returnmsg = "Please fill up all the required fields.";

        return;
      }
      JSON.parse(JSON.stringify(this.myFiles));
      Object.values(this.myFiles).forEach((val) => {
        console.log("fileid", val.FileId);
        this.formData.photolist.push({
          PhotoId: val.FileId || "",
          PhotoName: val.fileName,
          PhotoSize: val.fileSize,
          PhotoPath: val.filePath,
        });
      });

      await this.postApi("api/product/save", this.formData).then((result) => {
        if (result.flag === 1) {
          if (this.formData.Preview == 9) {
            this.clearfield();
            console.log((this.formData.productid = result.data));
            this.formData.cmd = 2;
            this.ProductSelected();
            window.open(
              process.env.VUE_APP_MEMBER_URL +
                "Package/Detail/" +
                encodeURIComponent(this.formData.productid),
              "_blank"
            );
          } else {
            this.returnmsg = "Product save success";
            this.SuccessAlert = true;
            this.$refs.btnClose.click();
            this.clearfield();
            this.getdata();
          }
        } else {
          this.dangerAlert = true;
          this.returnmsg = result.msg;
        }
      });
    },
    checkValidation() {
      var isEmpty = false;

      if (!this.formData.Productname.value) {
        this.Validation.vldProductname = false;
        isEmpty = true;
      } else {
        this.Validation.vldProductname = true;
        isEmpty = false;
      }

      this.formData.price.forEach((element, index) => {
        if (element.Price == 0) {
          if (this.formData.productType == 1) this.Validation.vldprice[index] = index <= 4 ? false : true; //ignore pax 6 till 10
          else if (this.formData.productType == 3) this.Validation.vldprice[index] = index == 0 ? true : false; //ignore pax 1
          else this.Validation.vldprice[index] = false;
        } else {
          this.Validation.vldprice[index] = true;
        }
      });
      this.Validation.vldprice.forEach((element) => {
        if (!element) {
          isEmpty = true;
        }
      });

      return isEmpty;
    },

    onprocessfile(error, file) {
      // file success uploaded
      if (error == null) {
        const result = JSON.parse(file.serverId); //json return once upload
        this.myFiles[file.id] = result.data;
        console.log(this.myFiles);
      } else {
        console.error(error);
      }
    },
    onremovefile(error, file) {
      if (error == null && this.userRemove == true) {
        if (this.myFiles[file.id] != null) {
          delete this.myFiles[file.id];
          // let filePath = this.myFiles[file.id].FilePath;
          //deleteFile(filePath, '#errRereckUpload');
        } else {
          this.formData.photoRemove.push({
            PhotoId: file.getMetadata("FileID"),
          });
          console.log(this.formData.photoRemove);
        }
      }
    },
    onactivatefile(file) {
      // when the file is clicked

      const path = this.extractFilePath(file);
      if (path.length > 0) window.open(this.formatImageUrl(path), "_blank");
    },
    async refresh(data) {
      //   const item= document.querySelectorAll('.filepond--action-remove-item')
      // console.log( item.length)
      //  item.forEach(box => {
      //     box.addEventListener('click', () => alert("asdsa"));
      //   })

      await this.$refs.pond.removeFiles();

      await data.forEach(async (file) => {
        this.$refs.pond.addFile(file.FilePath, {
          type: "local",
          file: {
            id: file.fileID,
            name: file.fileName,
            size: file.size,
            path: file.filePath,
          },
          metadata: {
            FilePath: file.filePath,
            url: file.filePath,
            FileID: file.fileID,
          },
        });
      });
      setTimeout(
        () => console.log("userremove", (this.userRemove = true)),
        500
      );
    },
    extractFilePath(file) {
      let imgPath = file.getMetadata("FilePath");
      if ($.trim(imgPath).length === 0) {
        const result = JSON.parse(file.serverId);
        imgPath = result ? result.data.FilePath : "";
      }

      return imgPath;
    },
    onPageSizeChanged() {
      var value = document.getElementById("page-size").value;
      this.paginationPageSize = Number(value);
      console.log(this.paginationPageSize);
      this.$forceUpdate();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.ColumnApi = params.columnApi;
      this.sizeToFit();
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },

    autoSize() {
      this.ColumnApi.autoSizeAllColumns(false);
    },
    onEditorReady(e) {
      e.container.querySelector(".ql-blank").innerHTML = this.formData.content;
    },
    UpdateAlert(value) {
      this.SuccessAlert = value;
      this.dangerAlert = value;
    },

    clearfield() {
    this.userRemove = false;
      this.formData.productid = null;
      this.formData.cmd = 1;
      this.formData.productType = 1;
      this.formData.Productname.value = null;
      this.formData.numberofPack.value = 1;
      this.formData.delivery = 0;
      this.formData.Discount.value = null;
    
      this.formData.price.forEach((element) => {
        element.Price = 0.0;
      });
      this.formData.photolist = [];
      this.formData.content = null;
      document.querySelector(".ql-editor").innerHTML = this.formData.content;
      this.formData.photoRemove = [];
      this.myFiles = [];
    },
    async ProductSelected() {
      await this.postApi("api/product/Detail", {
        id: this.formData.productid,
      }).then(async (result) => {
        if (result.flag === 1) {
          var Data = result.data[0];
          this.formData.productType = Data.type;
          this.formData.Productname.value = Data.name;
          this.formData.numberofPack.value = Data.meals;
          this.formData.delivery = Data.delivery > 0 ? 1 :0; //special cater
          this.formData.Discount.value = Data.discount;
          this.formData.content = Data.description;
          this.formData.visiable =Data.visiable==1;
          document.querySelector(".ql-editor").innerHTML =
        this.formData.content;
          this.formData.price = JSON.parse(Data.productPax);
          await this.refresh(JSON.parse(Data.productPhoto));
        } else {
          alert(result.msg);
        }
      });
    },

    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
  },
  async beforeMount() {
    await this.getdata();
    this.columnDefs = [
      {
        headerName: "Type",
        field: "productType",
        sortable: true
      },
      {
        headerName: "Name",
        field: "name",
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          //console.log(params);
          return `<b>${params.value}</b>`;
        },
      },
      {
        headerName: "Meals", field: "meals", sortable: true
      },
      { headerName: "Delivery Fee", field: 'delivery', sortable: true },
      { headerName: "Price", field: "price" },
      {
        headerName: "Discount %",
        field: "discount",
        // filter: "agDateColumnFilter",
        // filterParams: equalsFilterParams,sortable: true
      },
      {
        headerName: "Visible",
        field: "status",
        cellRenderer:(params)=>{
        
          var div =document.createElement("div");
          div.className = "switchToggle";
          var input =document.createElement("input");
          input.setAttribute('id',params.data.id);
          input.type = "checkbox";
          input.checked=params.value==1;
          input.addEventListener("change",async (e) => {

         await   this.postApi("api/product/visible", {productId:params.data.id,isVisible:input.checked}).then((result) => {
              if (result.flag === 1) {
                  this.SuccessAlert=true;
                  this.returnmsg = "Update Successful"
              } else {
                this.dangerAlert = true;
                this.returnmsg = result.msg;
                input.checked=!input.checked;
              }
            });           
          });   

          var label = document.createElement("label");
          label.setAttribute('for',params.data.id);
          label.innerHTML="Toggle";
          div.appendChild(input);
          div.appendChild(label);

        
          // var html ='<div class="form-check form-switch">';
          //   html+='  <input class="form-check-input" type="checkbox"  @change="checkExist($event)" ';
          //   html+= params.value==1?'Checked':"" +'>';
          //   html+='  </div>';
          return div;
  
        },
 
      },
      
      {
        headerName: "Edit",
        field: "id",
        cellRenderer: (params) => {
          var link = document.createElement("a"); // edit btn
          link.href = "#";
          link.className = "fa fa-pen d-block text-dark m-0 p-3";
          link.style.cssText = " border-right: 1px solid #c8c8d1;";

          link.addEventListener("click", (e) => {
            e.preventDefault();
            this.clearfield();
            this.$refs.btnAddProduct.click();
            this.formData.productid = params.data.id;

            this.formData.cmd = 2;
            this.ProductSelected();
          }); // onClick function

          var linkdelete = document.createElement("a"); // delete btn
          linkdelete.href = "#";
          linkdelete.className = "fa fa-trash d-block text-danger m-0 p-3";

          // linkdelete.setAttribute("data-bs-toggle", "modal");
          // linkdelete.setAttribute("data-bs-target", "#DeleteModal");
          linkdelete.addEventListener("click", (e) => {
            e.preventDefault();
            this.formData.productid = params.data.id;
            this.deleteProductName = params.data.name;
            document.getElementById("txtMasterPassword").value = "";
            this.deleteProductError.show = false;
            this.deleteProductError.msg = "";
            this.deleteProductModal.show();
          }); // onClick function
          var div = document.createElement("div");
          div.className = "d-flex";
          div.appendChild(link);
          div.appendChild(linkdelete);
          return div;
        },
      },
    ];
  },
  mounted() {
    this.deleteProductModal = new Modal(this.$refs.DeleteModal);
  },
};
</script>

<template>
  
  <div class="py-4 container-fluid">
    <vmd-alert
      class="font-weight-light"
      color="success"
      :dismissible="SuccessAlert"
      v-on:update="UpdateAlert($event)"
    >
      <span class="text-sm">{{ returnmsg }}</span>
    </vmd-alert>

 
    <div class="d-md-flex justify-content-between mx-3 align-items-center">
      <div class="d-md-flex align-items-center">
      <div class="d-flex align-items-center ">
        <span class="d-block text-nowrap me-1">Page Size:</span>
        <select
          v-on:change="onPageSizeChanged()"
          id="page-size"
          class="form-select  "
        >
          <option value="20" selected="">20</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
        </select>
        
      </div>
      
    <div class="row ms-md-2  mt-3  mb-3 mt-md-0 mb-md-0">
          <div class="col-12">
            <button class="btn btn-secondary mb-0 me-1" @click="sizeToFit()">
              Size to Fit
            </button>
            <button class="btn btn-secondary mb-0" @click="autoSize()">
              Auto-Size
            </button>
          </div>
        </div>
      </div>
      <div>
        <button
          class="btn btn-primary"
          ref="btnAddProduct"
          data-bs-toggle="modal"
          data-bs-target="#AddModal"
          v-on:click="clearfield"
        >
          Add New
        </button>
      </div>
    </div>


    <ag-grid-vue
      style="width: auto; height: 70vh"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowHeight="rowHeight"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :modules="modules"
      :key="paginationPageSize"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      @grid-ready="onGridReady"
      @first-data-rendered="onFirstDataRendered"
    >
    </ag-grid-vue>

    <!--Delete product Modal -->
    <div
      class="modal fade"
      ref="DeleteModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Delete Product</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <VmdAlert
              class="font-weight-light show"
              color="danger"
              :dismissible="deleteProductError.show"
              v-on:update="(e) => (deleteProductError.show = e)"
            >
              <span class="text-sm">{{ deleteProductError.msg }}</span>
            </VmdAlert>
            Are you sure you want to delete this product ({{ deleteProductName }})? Enter master password
            to proceed.
            <input
              type="password"
              class="form-control mt-2"
              placeholder="Master Password"
              maxlength="20"
              id="txtMasterPassword"
              autocomplete="off"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-danger"
              v-on:click="deleteProduct"
            >
              Confirm Delete
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- add new product Scrollable modal -->
    <div
      class="modal fade"
      id="AddModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header d-inline">
            <h5 class="modal-title ms-2" id="exampleModalLabel">
          {{  formData.cmd ==2?'Edit Product':"Add New Product"  }}  
            </h5>
            <vmd-alert
              class="font-weight-light"
              color="danger"
              :dismissible="dangerAlert"
              v-on:update="UpdateAlert($event)"
            >
              <span class="text-sm">{{ returnmsg }}</span>
            </vmd-alert>
          </div>
          <div class="modal-body">
            <!-- start product name -->
            <div class="row d-flex mb-2">
              <div class="col-lg-6">
                <label for="" class="form-label">Type of Product</label>
                <select class="form-select" v-model="formData.productType">
                  <option value="1">Tiffin/Container</option>
                  <option value="2">Bento</option>
                  <option value="3">Mini Bento</option>
                </select>
              </div>

              <div class="col-lg-6">
                <label for="productname" class="form-label">Product Name</label>
                <input
                  v-model="formData.Productname.value"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': !Validation.vldProductname }"
                  placeholder="Happy meals"
                />
                <div
                  class="invalid-feedback text-end mt-0"
                  v-bind:class="{ 'd-block': !Validation.vldProductname }"
                >
                  This field is requierd
                </div>
              </div>

            </div>
            <div class="row d-flex mb-2">
              <div class="col-12 col-lg-6">
                <label fro="numberpax" class="form-label">Meals</label>
                <input
                  v-model="formData.numberofPack.value"
                  type="number"
                  class="form-control"
                  placeholder="Meals"
                  min="1"
                />
              </div>
              <div class="col-12 col-lg-6">
                <label class="form-label">Charge Delivery Fee</label>
                <select class="form-select" v-model="formData.delivery">
                  <option value="0">No (Free Delivery)</option>
                  <option value="1">Yes (Charge based on number of delivery)</option>
                </select>
              </div>
            </div>
            <!-- End product name -->
            <div class="row align-items-end">
            <!--start discount row -->

            <div class="col-12 col-lg-6 mb-2">
              <label for="productname" class="form-label">Discount % </label>
              <input
                v-model="formData.Discount.value"
                type="number"
                class="form-control"
                placeholder="Discount"
                min="0"
                max="100"
              />
            </div>
            <!--end discount row -->

            <!--start visible row -->
            <div class="col-12 col-lg-6 mb-2 d-flex align-items-center">
              <div class="switchToggle" id="ModelToggleSwitch">
                <input  type="checkbox" id="ToggleSwitch"  v-model="formData.visiable">
                <label for="ToggleSwitch" ></label>
              </div>
              <div class="ms-3">Allow customer view this product</div>
            </div>
            <!--end visible row -->
          </div>

            <!-- start price row -->
            <h6 class="ms-1 mt-4">Price</h6>
            <div class="row mb-2">
              <div class="col-12 col-lg-6">

                <paxinput
                  v-model="formData.price[0].Price"
                  :isInvalid="Validation.vldprice[0]"
                  label="1 pax"
                  v-show="this.formData.productType != 3"
                ></paxinput>

                <paxinput
                  v-model="formData.price[1].Price"
                  :isInvalid="Validation.vldprice[1]"
                  label="2 pax"
                ></paxinput>

                <paxinput
                  v-model="formData.price[2].Price"
                  :isInvalid="Validation.vldprice[2]"
                  label="3 pax"
                ></paxinput>

                <paxinput
                  v-model="formData.price[3].Price"
                  :isInvalid="Validation.vldprice[3]"
                  label="4 pax"
                ></paxinput>

                <paxinput
                  v-model="formData.price[4].Price"
                  :isInvalid="Validation.vldprice[4]"
                  label="5 pax"
                ></paxinput>
              </div>
              <div v-show="this.formData.productType == 2 || this.formData.productType == 3" class="col-12 col-lg-6">
                <paxinput
                  v-model="formData.price[5].Price"
                  :isInvalid="Validation.vldprice[5]"
                  label="6 pax"
                ></paxinput>

                <paxinput
                  v-model="formData.price[6].Price"
                  :isInvalid="Validation.vldprice[6]"
                  label="7 pax"
                ></paxinput>

                <paxinput
                  v-model="formData.price[7].Price"
                  :isInvalid="Validation.vldprice[7]"
                  label="8 pax"
                ></paxinput>

                <paxinput
                  v-model="formData.price[8].Price"
                  :isInvalid="Validation.vldprice[8]"
                  label="9 pax"
                ></paxinput>

                <paxinput
                  v-model="formData.price[9].Price"
                  :isInvalid="Validation.vldprice[9]"
                  label="10 pax"
                ></paxinput>
              </div>
            </div>

            <!-- End price row -->

            <!-- start upload image -->
            <h6 class="ms-1 mt-4">Picture (Max. 5)</h6>
            <div>
              Min. dimensions: 600 pixels (Width) x 800 pixels (Height), max.
              size 3MB
            </div>
            <file-pond
              name="filepond"
              ref="pond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="true"
              accepted-file-types="image/jpeg, image/png"
              v-bind:files="myFiles"
              imageResizeTargetWidth="600"
              imageResizeTargetHeight="800"
              imageResizeMode="contain"
              max-files="5"
              maxFileSize="3MB"
              v-on:init="handleFilePondInit"
              label-max-file-size-exceeded="That exceeds max allowed limit"
              label-max-file-size="No more than 3MB please"
              v-on:processfile="onprocessfile"
              v-on:removefile="onremovefile"
              v-on:activatefile="onactivatefile"
            />
            <!-- end upload image -->
            <!-- start html editor row -->
            <h6 class="ms-1 mt-4">Description (Optional)</h6>
            <QuillEditor
              theme="snow"
              class="mt-2"
              v-model:content="formData.content"
              contentType="html"
              @ready="onEditorReady($event)"
            />
            <!-- End html editor row -->
          </div>
          <div class="modal-footer d-flex justify-content-between">
            <div>
              <!-- <button
                type="button"
                class="btn btn-primary"
                v-on:click="saveProduct(9)"
              >
                Preview
              </button> -->
            </div>
            <div>
              <button
                type="button"
                class="btn btn-secondary me-2"
                ref="btnClose"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                v-on:click="saveProduct(1)"
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}
.fa::before {
  margin-right: 10px !important;
}
.form-control:focus {
  border-color: var(--bs-primary) !important;
}

.form-control {
  border: 1px solid var(--bs-secondary) !important;

  padding: 0.5rem 0.75rem !important;
}
.form-control.is-invalid {
  border-color: #fd5c70 !important;
}
.form-select {
  border: 1px solid var(--bs-secondary) !important;
  padding: 0.5rem 0.75rem !important;
  background-position: right 7px center !important;
}
.form-select:focus {
  border-color: var(--bs-primary) !important;
}

.switchToggle input[type=checkbox]{height: 0; width: 0; visibility: hidden; position: absolute; }
.switchToggle label {cursor: pointer; text-indent: -9999px; width: 70px; max-width: 70px; height: 30px; background: #d1d1d1; display: block; border-radius: 100px; position: relative; }
.switchToggle label:after {content: ''; position: absolute; top: 2px; left: 2px; width: 26px; height: 26px; background: #fff; border-radius: 90px; transition: 0.3s; }
.switchToggle input:checked + label, .switchToggle input:checked + input + label  {background: #4EA952; }
.switchToggle input + label:before, .switchToggle input + input + label:before {content: 'Off'; position: absolute; top: -6px; left: 35px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
.switchToggle input:checked + label:before, .switchToggle input:checked + input + label:before {content: 'On'; position: absolute; top: -6px; left: 10px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
.switchToggle input:checked + label:after, .switchToggle input:checked + input + label:after {left: calc(100% - 2px); transform: translateX(-100%); }
.switchToggle label:active:after {width: 60px; } 

#ModelToggleSwitch  input + label:before, .switchToggle input + input + label:before { top: 5px !important ;}
#ModelToggleSwitch input + label:before, .switchToggle input + input + label:before { top: 5px!important ;  }
#ModelToggleSwitch label{margin-bottom: 5px;}
</style>
