<template>
  <div class="container-fluid mb-3">
    <VmdAlert
      class="font-weight-light show"
      color="danger"
      :dismissible="errorMsg.length > 0"
      v-on:update="(e) => (errorMsg = '')"
    >
      <span class="text-sm">{{ errorMsg }}</span>
    </VmdAlert>

    <PaymentSearch @search="onSearch" @error="(msg) => (errorMsg = msg)" />

    <div class="row mt-3">
      <div class="col-12 d-flex">
        <div class="fs-5">
          <span class="fw-bold">Total Amount: </span>
          <span>THB {{ cTotalAmount.round(2, true) }}</span>
        </div>
        <button class="btn btn-secondary mb-0 ms-auto me-1" @click="sizeToFit()">
          Size to Fit
        </button>
        <button class="btn btn-secondary mb-0" @click="autoSize()">Auto-Size</button>
      </div>
    </div>
    <AgGridVue
      style="width: auto;"
      class="ag-theme-alpine mt-1 ag-custom"
      domLayout="autoHeight"
      :rowData="gridData"
      :columnDefs="gridSetting.columnDefs"
      :defaultColDef="gridSetting.defaultColDef"
      :modules="gridSetting.modules"
      :pagination="true"
      :paginationPageSize="gridSetting.pageSize"
      :enable-cell-text-selection="true"
      :ensure-dom-order="true"
      @grid-ready="onGridReady"
      @sort-changed="(e) => e.api.refreshCells()"
    >
    </AgGridVue>
  </div>
</template>

<script>
import VmdAlert from "../components/VmdAlert.vue";
import PaymentSearch from "./components/PaymentSearch.vue";
import { AgGridVue } from "@ag-grid-community/vue3";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { PaymentStatus } from "./components/Report/PaymentStatus.js";

export default {
  components: { VmdAlert, PaymentSearch, AgGridVue },
  data() {
    return {
      searchOptions: {},
      gridApi: null,
      gridColumnApi: null,
      gridData: [],
      gridSetting: {
        modules: [ClientSideRowModelModule],
        pageSize: 50,
        defaultColDef: { resizable: true, sortable: true },
        columnDefs: [
          {
            headerName: "#",
            field: "",
            suppressSizeToFit: true,
            suppressMovable: true,
            sortable: false,
            valueGetter: (params) => params.node.rowIndex + 1,
          },
          {
            headerName: "Date",
            field: "created",
            cellRenderer: (params) => params.data.created.format(),
            comparator: (a, b) => {
              return a.valueOf() - b.valueOf();
            },
          },
          {
            headerName: "Booking #",
            field: "code",
            cellRenderer: (params) =>
              `<a href="/booking/${encodeURIComponent(
                params.data.orderId
              )}" class="text-decoration-underline">${params.data.code}</a>`,
            comparator: (a, b) => a.localeCompare(b),
          },
          {
            headerName: "Customer",
            field: "customer",
            cellRenderer: (params) =>
              `<a href="/customer/${encodeURIComponent(
                params.data.custId
              )}" class="text-decoration-underline">${params.data.customer}</a>`,
            comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
          },
          {
            headerName: "Bank",
            field: "bankName",
          },
          {
            headerName: "Amount",
            field: "amount",
            cellRenderer: (params) =>
             ` ${params.data.symbol} ` + ` ${params.data.amount.round(2, true)} `, 
            comparator: (a, b) => a - b,
          },
          {
            headerName: "Amount",
            field: "totalPrice",
            cellRenderer: (params) =>
              `THB ${params.data.totalPrice.round(2, true)} `,
            comparator: (a, b) => a - b,
          },
          {
            headerName: "Status",
            field: "status",
            cellRenderer: (params) => PaymentStatus(params.data.status, true),
          },
      {
        headerName: "Action",
        field: "pid",
        cellRenderer: (params) => {
          var link = document.createElement("a"); // edit btn
          link.href = "#";
          link.className = "fa fa-pen d-block text-dark m-0 p-3";
          link.addEventListener("click", (e) => {
            e.preventDefault();
            window.location.href = `/payment/${encodeURIComponent(
              params.data.pid
            )}`;
          }); // onClick function

          var div = document.createElement("div");
          div.className = "d-flex";
          div.appendChild(link);

          return div;
        },
      },
        ],
      },
      errorMsg: "",
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    autoSize() {
      this.gridColumnApi.autoSizeAllColumns(false);
    },

    onSearch(searchValues, stopLoading) {
      const [startDate, endDate] = (searchValues.date || [])?.map((d) =>
        d.format("yyyy-MM-dd")
      );
      const data = { ...searchValues, startDate, endDate };

      this.postApi("api/payment/search", data).then((res) => {
        if (res.flag === 1) {
          this.errorMsg = "";
          this.gridData = res.data.map((d) => ({
            ...d,
            created: new Date(d.created),
          }));
          this.autoSize();
          this.sizeToFit();
        } else {
          this.errorMsg = res.msg;
        }

        if (stopLoading) stopLoading();
      });
    },
  },
  computed: {
    cTotalAmount() {
      return this.gridData.reduce((total, current) => (total += current.totalPrice), 0);
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>