<script>
import VmdInput from "@/components/VmdInput.vue";
import VmdButton from "@/components/VmdButton.vue";
import VmdAlert from "@/components/VmdAlert.vue";
export default {
  name: "sign-in",
  data() {
    return {
      email: null,
      password: null,
      emailValid: true,
      passwordValid: true,
      msg: null,
      alertshow: false,
    };
  },
  methods: {
    async onSubmit() {
      if (this.email && this.password) {
        await this.postApi("api/login/login", {
          username: this.email,
          password: this.password,
        }).then((result) => {
   
          if (result.flag === 1) {
            this.email = null;
            this.password = null;
            this.$cookie.setCookie("LEVEL", result.data[0].personlevel);  
            this.$router.push({ name: "Dashboard" });
          } else {
            this.msg = result.msg;
            this.alertshow = true;
          }
        });
        return;
      }
      if (!this.email) {
        this.emailValid = false;
      }
      if (!this.password) {
        this.passwordValid = false;
      }

      return false;
    },

    UpdateAlert(value){
        this.alertshow=value;
    }
  },
  components: {
    VmdInput,
    VmdButton,
        VmdAlert,
  },
  beforeMount() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
  },
};
</script>

<template>
  <div class="row">
    <div class="page-header align-items-start min-vh-100 col-12 col-lg-7">
      <span class="mask bg-gradient-light opacity-6"></span>
      <div class="container my-auto">
        <div class="row">
          <div class="col-lg-7 col-md-8 col-12 mx-auto">
            <div class="card z-index-0 fadeIn3 fadeInBottom">
              <!-- <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div class="bg-gradient-warning shadow-warning border-radius-lg py-3 pe-1">
                  <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                    Sign in
                  </h4>
                </div>
              </div> -->
              <div class="card-body">
                <!-- alert error message -->
             <vmd-alert  class="font-weight-light" color="danger" :dismissible="alertshow"   v-on:update="UpdateAlert($event)" >
              <span class="text-sm">    <strong>{{ msg }}</strong></span >
            </vmd-alert>
                <!-- End alert error message -->

                <form @submit.prevent="onSubmit">
                  <h4 class="text-primary">Login</h4>
                  <div class="mb-3">
                    <vmd-input
                      type="text"
                      :class="{ 'is-invalid': !emailValid }"
                      label="Email"
                      name="email"
                      v-model="email"
                      :isInvalid="!emailValid"
                      errMsg="This field is required"
                    />
                  </div>
                  <div class="mb-3">
                    <vmd-input
                      type="password"
                      :class="{ 'is-invalid': !passwordValid }"
                      label="Password"
                      name="password"
                      v-model="password"
                      :isInvalid="!passwordValid"
                      errMsg="This field is required"
                    />
                  </div>
                  <div class="text-center">
                    <vmd-button class="my-4 mb-2" color="primary" fullWidth
                      >Sign in</vmd-button
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img
      class="col-lg-5	d-none d-md-none d-lg-block  p-0"
      style="height: 100vh; max-width: 100%"
      src="../assets/img/login.jpg"
    />
  </div>
</template>
