<template>
  <div class="container-fluid">
    <VmdAlert class="font-weight-light show" color="danger" :dismissible="error.show"
      v-on:update="(e) => (error.show = e)">
      <span class="text-sm">{{ error.msg }}</span>
    </VmdAlert>

    <div class="row mt-3">
      <div class="col-12">
        <div class="mt-2">
          <div class="h5 text-secondary">Bank Setting</div>
          
        </div>
      </div>
      <div class="col-12">
        <button
          type="button"
          class="btn btn-secondary mb-0 me-1 d-md-inline  mb-1 mb-md-0"
          @click="sizeToFit()"
        >
          Size to Fit
        </button>
        <button
          type="button"
          class="btn btn-secondary mb-0 d-md-inline  mb-1 mb-md-0"
          @click="autoSize()"
        >
          Auto-Size
        </button>
        <button type="button" class="btn btn-primary mb-0 ms-1 mb-1 float-md-end" @click="$refs.editModal.showModal()">
            New Bank
          </button>
      </div>
    </div>
    <AgGridVue style="width: auto" class="ag-theme-alpine mt-1" domLayout="autoHeight" :rowData="grid.data"
      :columnDefs="grid.setting.columnDefs" :defaultColDef="grid.setting.defaultColDef" :modules="grid.setting.modules"
      :pagination="true" :paginationPageSize="grid.setting.pageSize" @grid-ready="onGridReady">
    </AgGridVue>
  </div>
  <EditBank ref="editModal" @complete="onEditComplete" />

  <!--------- Confirm Delete Modal --------->
  <div ref="deleteModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Bank</h5>
        </div>
        <div class="modal-body">
          Are you sure you want to delete?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Cancel
          </button>
          <SubmitButton type="button" class="btn btn-danger" @click="onConfirmDelete">
            Delete
          </SubmitButton>
        </div>
      </div>
    </div>
  </div>
  <!--------- Confirm Delete Modal END --------->
</template>

<script>
import VmdAlert from "../components/VmdAlert.vue";
import { AgGridVue } from "@ag-grid-community/vue3";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import EditBank from "./components/EditBank.vue";
import SubmitButton from "./components/Order/SubmitButton.vue";
import { Modal } from "bootstrap";

export default {
  components: { VmdAlert, AgGridVue, EditBank, SubmitButton },
  data() {
    return {
      grid: {
        api: null,
        columnApi: null,
        data: [],
        setting: {
          modules: [ClientSideRowModelModule],
          pageSize: 10,
          defaultColDef: { resizable: true, sortable: true },
          columnDefs: [
            {
              headerName: "Bank Name",
              field: "bankName",
              filter: "agTextColumnFilter",
            },
            {
              headerName: "Account Name",
              field: "accountName",
              filter: "agTextColumnFilter",
            },
            {
              headerName: "Account Number",
              field: "accountNumber",
              filter: "agTextColumnFilter",
            },
            {
              headerName: "Swift Code",
              field: "swiftCode",
              filter: "agNumberColumnFilter",
            },
            {
              headerName: "Bank Country",
              field: "bankCountry",
              filter: "agNumberColumnFilter",
            },
            {
              headerName: "Bank Address",
              field: "bankAddress",
              filter: "agNumberColumnFilter",
            },
            {
              headerName: "IBAN",
              field: "iban",
              filter: "agNumberColumnFilter",
            },
            {
              headerName: "Currency Symbol",
              field: "currencySymbol",
              filter: "agNumberColumnFilter",
            },
            {
              headerName: "",
              field: "id",
              sortable: false,
              cellRenderer: (params) => {
                const btnEdit = document.createElement("a"); // edit btn
                btnEdit.href = "#";
                btnEdit.className = "fa fa-pen d-block text-dark m-0 p-3";
                btnEdit.addEventListener("click", (e) => {
                  e.preventDefault();
                  this.onEdit(params.data.id);
                });

                const btnDelete = document.createElement("a"); // delete btn
                btnDelete.href = "#";
                btnDelete.className = "fa fa-trash d-block text-danger m-0 p-3";
                btnDelete.addEventListener("click", (e) => {
                  e.preventDefault();
                  this.onDelete(params.data.id);
                });
                const div = document.createElement("div");
                div.className = "d-flex";
                div.appendChild(btnEdit);
                div.appendChild(btnDelete);
                return div;
              },
            },
          ],
        },
      },
      dtl: { modal: null, id: "" },
      error: { show: false, msg: "" },
    };
  },
  methods: {
    async getBankList() {
      const res = await this.getApi("api/banksetting");

      if (res.flag === 1) {
        this.error.show = false;
        this.grid.data = res.data;

        this.autoSize();
        this.sizeToFit();
      } else {
        this.showError(res.msg);
      }
    },

    onGridReady(params) {
      this.grid.api = params.api;
      this.grid.columnApi = params.columnApi;

      this.getBankList();
    },
    sizeToFit() {
      this.grid.api.sizeColumnsToFit();
    },
    autoSize() {
      this.grid.columnApi.autoSizeAllColumns(false);
    },

    onEdit(id) {
      const [bank] = this.grid.data.filter((d) => d.id === id);
      if (bank)
        this.$refs.editModal.showModal({
          ...bank,
        });
    },
    async onEditComplete(data, stopLoading) {
      const [startDate, endDate] = data.validity || [];
      const res = await this.postApi("api/banksetting", { ...data });
      stopLoading();

      if (res.flag === 1) {
        this.getBankList();
        this.$refs.editModal.hideModal();
      } else {
        this.$refs.editModal.showModalError(res.msg);
      }
    },

    onDelete(id) {
      console.log("delete", id);
      this.dtl.id = id;
      this.dtl.modal.show();
    },
    async onConfirmDelete(stopLoading) {
      const res = await this.postApi("api/banksetting/delete", { id: this.dtl.id });
      stopLoading();
      this.dtl.modal.hide();
      if (res.flag === 1) {
        this.error.show = false;
        this.getBankList();
      } else {
        this.showError(res.msg);
      }
    },

    showError(msg) {
      this.error.show = true;
      this.error.msg = msg;
    },
  },

  created() {
    this.getBankList();
  },
  mounted() {
    this.dtl.modal = new Modal(this.$refs.deleteModal);
  },
};
</script>
