Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};
Date.prototype.format = function (format = 'dd MMM yyyy') {
    // eg: 2022-12-25 17:32:57
    // 'dd MMM yyyy' -> '25 Dec 2022'   'dd-MM-yy' -> '25-12-22'
    // 'hh:mm:ss tt' -> '05:32:57 PM'   'HH:mm:ss' -> '17:32:57'
    const date = new Date(this.valueOf());
    const locale = 'en-US';
    const formattedDate = format
        .replace('yyyy', date.toLocaleString(locale, { year: 'numeric' }))
        .replace('MMM', date.toLocaleString(locale, { month: 'short' }))
        .replace('yy', date.toLocaleString(locale, { year: '2-digit' }))
        .replace('MM', date.toLocaleString(locale, { month: '2-digit' }))
        .replace('dd', date.toLocaleString(locale, { day: '2-digit' }))

        .replace('HH', date.getHours().toString().padStart(2, '0'))
        .replace('hh', ((date.getHours() + 11) % 12 + 1).toString().padStart(2, '0'))
        .replace('mm', date.toLocaleString(locale, { minute: '2-digit' }).padStart(2, '0'))
        .replace('ss', date.toLocaleString(locale, { second: '2-digit' }).padStart(2, '0'))
        .replace('tt', date.getHours() >= 12 ? 'PM' : 'AM');

    return formattedDate;
}
Number.prototype.round = function (precision, toLocale = false) {
    const num = Math.round((this.valueOf() + Number.EPSILON) * Math.pow(10, precision)) / Math.pow(10, precision);
    return toLocale ? num.toLocaleString('en-US', { minimumFractionDigits: precision, maximumFractionDigits: precision }) : num.toFixed(precision);
}