<script>
// Import Vue FilePond
import vueFilePond, { setOptions } from "vue-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import $ from "jquery";
import "axios";
import "jquery/dist/jquery.min.js";
import VmdButton from "../components/VmdButton.vue";
import VmdAlert from "@/components/VmdAlert.vue";
import DragList from "./components/DragList.vue";
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginImageTransform,
  FilePondPluginImageResize
);

export default {
  name: "Gallery",
  data() {
    return {
      BannerList: [],
      url: null,
      postlist: [],
      filepond: [],
      allowUpload: true,
      deleteSeleted: null,
      emptyImage: [1, 2, 3, 4, 5],
      bannerDetail: [{ id: null, URl: null }],
      dangerAlert: false,
      SuccessAlert: false,
      Successmsg: null,
      dangermsg: null,
      modalupdateAlt: false,
      updatemsg: null,
      Uploadvldt: true,
      count: 0,

      isImgIdxChanged: false,
    };
  },

  components: {
    FilePond,
    VmdButton,
    VmdAlert,
    DragList
},
  methods: {
    async getdata() {
      await this.getApi("api/banner").then((result) => {
        if (result.flag === 1) {
          this.BannerList = result.data;
          if (this.BannerList.length >= 12) {
            this.allowUpload = false;
          } else {
            this.allowUpload = true;
          }
        }else
        {
        this.SuccessAlert = false;
        this.dangerAlert= true;
        this.dangermsg=result.msg;
        }
      });
    },
    async Delete() {
      await this.postApi("api/banner/delete", {
        id: this.deleteSeleted,
      }).then((result) => {
        if (result.flag === 1) {
          // console.log(result.data);
          this.getdata();
        }else
        {
                this.SuccessAlert = false;
        this.dangerAlert= true;
        this.dangermsg=result.msg;
        }
      });
    },
    async save() {
      if (this.count == 0) {
        this.Uploadvldt = false;
        this.dangerAlert = true;
        this.dangermsg = "Please select an image to upload. If you have selected the image to upload, please wait until it finished the upload before click \"SAVE\".";
        return;
      } else {
        this.dangerAlert = false;
        this.Uploadvldt = true;
      }

      if (this.url != null) {
        if (!this.is_url(this.url)) {
          this.dangerAlert = true;
          this.dangermsg = "Invalid url format";

          return;
        } else {
          if (this.url[0] == "w") {
            this.url = "https://" + this.url;
          }
        }
      }

      JSON.parse(JSON.stringify(this.filepond));
      Object.values(this.filepond).forEach((val) => {
        this.postlist.push({
          PhotoId: val.FileId || "",
          PhotoName: val.fileName,
          PhotoSize: val.fileSize,
          PhotoPath: val.filePath,
        });
      });
      await this.postApi("api/banner/save", {
        URL: this.url,
        list: this.postlist,
      }).then((result) => {
        if (result.flag === 1) {
          this.url = null;
          this.postlist = [];
          this.$refs.file_pond_2.removeFiles();
          this.dangerAlert = false;
          this.SuccessAlert = true;
          this.Successmsg = "Upload Banner Success";
          this.getdata();
        }else
        {
                  this.SuccessAlert = false;
        this.dangerAlert= true;
        this.dangermsg=result.msg;
        }
      });
    },
    is_url(str) {
      const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return regexp.test(str);
    },
    ItemsSelected(id) {
      this.deleteSeleted = id;
    },
    handleFilePondInit: function () {
      console.log("FilePond has initialized");
      // example of instance method call on pond reference
      this.$refs.file_pond_2.getFiles();
    },
    setOptionsOnAddFile() {
      setOptions({
        server: {
          url: process.env.VUE_APP_UPLOAD_URL + "api/wkrUpload/upload",
          process: (fieldName, file, metadata, load, error, progress, abort) => {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("fieldName", file.name);
            formData.append("cmd", 2);

            const request = new XMLHttpRequest();
            request.open("POST",  process.env.VUE_APP_UPLOAD_URL + "api/wkrUpload/upload");
            // Setting computable to false switches the loading indicator to infinite mode
            request.upload.onprogress = (e) => {
              progress(e.lengthComputable, e.loaded, e.total);
            };

            request.onload = function () {
              if (request.status >= 200 && request.status < 300) {
                load(request.responseText); // the load method accepts either a string (id) or an object
              } else {
                error("Error during Upload!");
              }
            };

            request.send(formData);
            //expose an abort method so the request can be cancelled
            return {
              abort: () => {
                // This function is entered if the user has tapped the cancel button
                request.abort();
                // Let FilePond know the request has been cancelled
                abort();
              },
            };
          }, // we've not implemented these endpoints yet, so leave them null!
        },
      });
    },
    onprocessfile(error, file) {
      // file success uploaded
      if (error == null) {
        const result = JSON.parse(file.serverId); //json return once upload
        this.filepond[file.id] = result.data;
        console.log(this.filepond);
        this.count++;
      } else {
        console.error(error);
      }
    },
    onremovefile(error, file) {
      if (error == null) {
        if (this.filepond[file.id] != null) {
          delete this.filepond[file.id];
          // let filePath = this.myFiles[file.id].FilePath;
          //deleteFile(filePath, '#errRereckUpload');
          this.count--;
        } else {
          this.BannerRemove.push({ PhotoId: file.getMetadata("FileID") });
          console.log(this.BannerRemove);
        }
      }
    },
    onactivatefile(file) {
      // when the file is clicked

      const path = this.extractFilePath(file);
      if (path.length > 0) window.open(path, "_blank");
    },
    async refresh(data) {
      console.log(data);
      this.$refs.file_pond_2.removeFiles();
      await data.forEach((file) => {
        this.$refs.file_pond_2.addFile(file.FilePath, {
          type: "local",
          file: {
            id: file.fileID,
            name: file.fileName,
            size: file.size,
            path: file.filePath,
          },
          metadata: { FilePath: file.filePath, url: file.filePath, FileID: file.fileID },
        });
      });
    },
    extractFilePath(file) {
      let imgPath = file.getMetadata("FilePath");
      if ($.trim(imgPath).length === 0) {
        const result = JSON.parse(file.serverId);
        imgPath = result ? result.data.FilePath : "";
      }

      return imgPath;
    },
    imgsrc(path) {
      let result = process.env.VUE_APP_IMG_URL + path;
      return result;
    },
    ModifySelected(id, url) {
      this.bannerDetail.id = id;
      this.bannerDetail.URL = url;
    },
    async SaveModify() {
      if (this.bannerDetail.URL != null) {
        if (!this.is_url(this.bannerDetail.URL)) {
          this.modalupdateAlt = true;
          this.updatemsg = "Invalid url format";

          return;
        } else {
          if (this.bannerDetail.URL[0] == "w") {
            this.bannerDetail.URL = "https://" + this.bannerDetail.URL;
          }
        }
      }

      await this.postApi("api/banner/modifyUrl", {
        id: this.bannerDetail.id,
        url: this.bannerDetail.URL,
      }).then((result) => {
        if (result.flag === 1) {
          this.SuccessAlert = true;
          this.Successmsg = "Url update success";
          this.bannerDetail.id = null;
          this.bannerDetail.URL = null;
          this.$refs.btnmodalupdate.click();
          this.getdata();
        } else {
          this.modalupdateAlt = true;
          this.updatemsg = result.msg;
        }
      });
    },
    UpdateAlert(value) {
      this.SuccessAlert = value;
      this.dangerAlert = value;
      this.modalupdateAlt=value;
    },

    async saveArrangement(){
      const items = this.$refs.dragList.items();
      const data = [...items.values()].map((e, idx) => ({
        id: e.getAttribute("data-id"),
        idx,
      }));

      const res = await this.postApi("api/banner/save/idx", data);
      // console.log("api/gallery/save/idx", res);
      if (res.flag === 1) {
        this.dangerAlert = false;
        this.SuccessAlert = true;
        this.Successmsg = "Save Image Arrangement Success";
        this.isImgIdxChanged = false;
        this.getdata();
      } else {
        this.dangerAlert = true;
        this.SuccessAlert = false;
        this.dangermsg = result.msg;
      }
    },
    handleArrangementChange(event, dragItem, dropItem) {
      this.isImgIdxChanged = true;
      let drag = dragItem.getAttribute("data-id");
      let drop = dropItem.getAttribute("data-id");
      // get index
      drag = this.BannerList.findIndex((photo) => photo.id === drag);
      drop = this.BannerList.findIndex((photo) => photo.id === drop);
      // get object
      dragItem = this.BannerList[drag];
      dropItem = this.BannerList[drop];
      // swap object
      this.BannerList.splice(drop, 1, dragItem);
      this.BannerList.splice(drag, 1, dropItem);
    },
  },
  async beforeMount() {
    await this.getdata();
  },

  computed: {
    infoOne: function () {
      return this.emptyImage.filter((i) => i > this.BannerList.length);
    },
  },
};
</script>

<template>
  <div class="card w-100">
    <div class="card-body">
      <vmd-alert
        class="font-weight-light"
        color="danger"
        :dismissible="dangerAlert"
        v-on:update="UpdateAlert($event)"
      >
        <span class="text-sm">{{ dangermsg }}</span>
      </vmd-alert>
      <vmd-alert
        class="font-weight-light"
        color="success"
        :dismissible="SuccessAlert"
        v-on:update="UpdateAlert($event)"
      >
        <span class="text-sm">{{ Successmsg }}</span>
      </vmd-alert>
      <div class="col-12 mb-2">
        <div v-if="allowUpload" class="col-12">
          <h5 class="card-title">Upload Banner Image</h5>
          <div class="col-12 col-lg-6 mb-3">
            <label for="inputPassword5" class="form-label">Url Redirect (Optional)</label>
            <input
              type="text"
              v-model="url"
              placeholder="https://Cmai.my/#packages"
              class="form-control"
              aria-describedby="clickURl"
            />
          </div>
          <div>Min. dimensions: 1280 pixels (Width) x 430 pixels (Height), max. size 3MB</div>
          <div class="d-lg-flex align-items-end">
            <div
              class="border-style col-12 col-lg-6 p-2"
              :class="{ 'border-danger': !Uploadvldt }"
            >
              <file-pond
                class=""
                ref="file_pond_2"
                label-idle="Drop image here..."
                v-bind:allow-multiple="false"
                accepted-file-types="image/jpeg, image/png"
                v-bind:files="filepond"
                imageResizeTargetWidth="1280"
                imageResizeTargetHeight="430"
                imageResizeMode="cover"
                maxFileSize="3MB"
                v-on:init="handleFilePondInit"
                label-max-file-size-exceeded="That exceeds max allowed limit"
                label-max-file-size="No more than 3MB please"
                v-on:processfile="onprocessfile"
                v-on:removefile="onremovefile"
                v-on:activatefile="onactivatefile"
                v-on:addfile="setOptionsOnAddFile"
              />
            </div>
            <VmdButton
              v-on:click="save"
              style="white-space: nowrap"
              class="m-2 mb-2 m-lg-0 ms-lg-3 col-12 col-lg-1"
              color="primary"
              >Save</VmdButton
            >
          </div>
          <hr />
        </div>
      </div>
      <div class="mt-3 d-flex align-items-center">
        <div>
          <h5 class="card-title mb-0">Banner Image</h5>
          <div><small>* Click image to modify url</small></div>
          <div><small>* Drag and Drop the image to arrange the position and click "SAVE ARRANGEMENT" to save the position.</small></div>
        </div>
        <button
          type="button"
          class="btn btn-primary m-0 ms-3"
          :disabled="BannerList.length === 0 || !isImgIdxChanged"
          @click="saveArrangement"
        >
          Save Arrangement
        </button>
      </div>
      <div class="col-12 m-2">
      <DragList ref="dragList" class="row" @change="handleArrangementChange">
        <div
          class="m-1 p-0 col-12 col-md-4 col-lg-3 position-relative"
          v-for="item in BannerList"
          :key="item.id"
          style="min-height: 94px"
          :data-id="item.id"
          :draggable="true"
        >
          <a
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#ModifyURL"
            @click="ModifySelected(item.id, item.url)"
            ><img class="img-fluid rounded" :src="this.formatImageUrl(item.path)"
          /></a>

          <button
            data-bs-toggle="modal"
            data-bs-target="#Deletebanner"
            v-on:click="ItemsSelected(item.id)"
            class="position-absolute top-0 end-0 btn-close-danger close btn-close"
          ></button>
        </div>
        <div
          v-for="item in infoOne"
          :key="item"
          style="min-height: 94px"
          class="col-12 col-md-4 col-lg-3 m-1 d-flex align-items-center justify-content-center border-style"
        >
          <span> Image {{ item }}</span>
        </div>
      </DragList>
      </div>

      <!-- BOF: Banner -->
      <h5 class="card-title mt-3">Preview Banner Image</h5>
      <div
        id="carouselExampleIndicators"
        class="carousel slide w-md-100 w-100 w-lg-75"
        style="margin: 0px auto"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            v-for="(item, index) in BannerList"
            :key="item.id"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            :data-bs-slide-to="index"
            :class="index == 0 ? 'active' : ''"
            :aria-current="index == 0"
            :aria-label="'Slide ' + index + 1"
          ></button>
        </div>
        <div class="carousel-inner">
          <div
            class="carousel-item"
            :class="index == 0 ? 'active' : ''"
            v-for="(item, index) in BannerList"
            :key="item.url"
          >
            <a :href="item.url" target="_blank">
              <img
                :src="this.formatImageUrl(item.path)"
                class="d-block w-100"
                alt="..."
              />
            </a>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <!-- EOF: Banner -->
    </div>
  </div>

  <div
    class="modal fade"
    id="Deletebanner"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Banner Image</h5>
        </div>
        <div class="modal-body">Are you sure you want to delete this image?</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
          <button
            type="button"
            class="btn btn-danger"
            v-on:click="Delete"
            data-bs-dismiss="modal"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="ModifyURL"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit Url Redirect</h5>
        </div>
        <div class="modal-body">
          <vmd-alert
            class="font-weight-light"
            color="danger"
            :dismissible="modalupdateAlt"
            v-on:update="UpdateAlert($event)"
          >
            <span class="text-sm">{{ updatemsg }}</span>
          </vmd-alert>
          <label for="inputPassword5" class="form-label">Url Redirect (Optional)</label>
          <input
            type="text"
            v-model="bannerDetail.URL"
            class="form-control"
            aria-describedby="clickURl"
          />
        </div>
        <div class="modal-footer">
          <button ref="btnmodalupdate" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="SaveModify"
     
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.border-style {
  border-style: dotted;
  border-radius: 10px;
}
.bannerimg {
  height: 200px;
  width: 200px;
  object-fit: cover;
}
.close.btn-close {
  height: 28px !important;
  width: 23px !important;
  background: transparent url("../assets/icon/red_close.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 28px 28px !important;
}
</style>
