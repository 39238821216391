<template>
  <div
    ref="NewAddressModal"
    id="new-address-modal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog modal-xl modal-fullscreen-xl-down">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ viewOnly ? "Delivery Address Details" : "Add New Address" }}
          </h5>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6 mb-3 mb-md-0">
              <div
                id="my-addr-map"
                class="h-100"
                style="min-height: 400px"
              ></div>
            </div>
            <div class="col-md-6">
              <div class="d-flex flex-column h-100 justify-content-between">
                <div class="row">
                  <div class="col-12 mb-3">
                    <div class="d-flex justify-content-between">
                      <div><label>Delivery Full Address</label></div>
                      <div v-if="specialHint" class="text-danger small">
                        (Please select an address to pin at the map)
                      </div>
                    </div>

                    <textarea
                      id="txtAddress"
                      class="form-control"
                      :class="[
                        formData.address.isValid == null
                          ? ''
                          : formData.address.isValid
                          ? 'is-valid'
                          : 'is-invalid',
                      ]"
                      rows="3"
                      placeholder="Enter your delivery full address"
                      v-model="formData.address.value"
                      :disabled="viewOnly"
                    />
                    <div
                      v-if="formData.address.isValid == false"
                      class="invalid-feedback"
                    >
                      {{ this.formData.address.invalidMsg }}
                    </div>
                    <div class="text-danger small">
                      Make sure the pin is your exact location. Click on map to
                      adjust the pin.
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label>Full Name</label>
                    <TextInput
                      placeholder="Name"
                      :trim="true"
                      :is-invalid="!formData.name.isValid"
                      @blur="validateName()"
                      v-model="formData.name.value"
                      err-msg="Invalid Name."
                      :maxLength="100"
                      :isDisable="viewOnly"
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="d-block">Phone Number</label>
                    <MobileInput
                      :is-invalid="!formData.mobile.isValid"
                      v-model="formData.mobile"
                      err-msg="Invalid mobile number."
                      :disable="viewOnly"
                    />
                  </div>

                  <div class="col-md-6 mb-3">
                    <label>Floor/Unit</label>
                    <TextInput
                      placeholder="Floor/Unit"
                      :trim="true"
                      :is-invalid="!formData.floorUnit.isValid"
                      v-model="formData.floorUnit.value"
                      err-msg="Floor/Unit is required."
                      :maxLength="20"
                      :isDisable="viewOnly"
                    />
                  </div>

                  <div class="col-md-6 mb-3">
                    <label>Building Name (Optional)</label>
                    <TextInput
                      placeholder="Building Name (Optional)"
                      :trim="true"
                      v-model="formData.buildingName.value"
                      :maxLength="100"
                      :isDisable="viewOnly"
                    />
                  </div>

                  <div class="col-12 mb-3" style="display: none">
                    <div class="form-check ps-0">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="cbDefault"
                        v-model="formData.isDefault.value"
                      />
                      <label class="form-check-label" for="cbDefault">
                        Set As Default Address
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex align-items-center"
                  :class="[viewOnly ? 'justify-content-between' : 'justify-content-end']"
                >
                  <a
                    v-if="addressId != null"
                    href="#"
                    style="color: red; text-decoration: none"
                    @click.prevent="deleteAddress"
                    >Delete This Address</a
                  >
                  <div>
                    <button
                      type="button"
                      class="btn btn-secondary mx-2 my-0"
                      data-bs-dismiss="modal"
                      @click="this.$emit('list-address')"
                    >
                      Back
                    </button>
                    <button
                      v-if="!viewOnly"
                      type="button"
                      class="btn btn-primary m-0"
                      @click="saveAddress"
                      :disabled="btnSaveAddressDisabled"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import $ from "jquery";
import TextInput from "@/components/TextInput.vue";
import MobileInput from "@/components/MobileInput.vue";

export default {
  components: { TextInput, MobileInput },
  props: {
    personId: { type: String, required: true },
  },
  data() {
    return {
      newAddressModal: null,
      formData: {
        name: { value: "", isValid: true },
        mobile: { value: "", dialCode: "60", isValid: true },
        address: {
          value: "",
          areaId: null,
          addressName: "",
          sublocality: "",
          locality: "",
          postalCode: "",
          country: "",
          lat: null,
          lng: null,
          isValid: null, // default null = no input yet
          invalidMsg: "",
        },
        buildingName: { value: "", isValid: true },
        floorUnit: { value: "", isValid: true },
        isDefault: { value: false, isValid: true },
      },
      btnSaveAddressDisabled: false,
      specialHint: false,
      addressId: null, //for view only
      gMap: {
        map: null,
        marker: null,
      },
      viewOnly: false,
    };
  },
  mounted() {
    this.newAddressModal = new Modal(this.$refs.NewAddressModal);
  },
  methods: {
    initGoogleMap() {
      if (this.gMap.map == null) {
        let pos = { lat: 3.138675, lng: 101.6169495 };
        this.gMap.map = new google.maps.Map(
          document.getElementById("my-addr-map"),
          {
            streetViewControl: false,
            mapTypeControl: false,
            center: pos,
            zoom: 17,
            gestureHandling: "greedy",
            clickableIcons: false,
          }
        );
        let map = this.gMap.map;
        this.gMap.marker = new google.maps.Marker({
          //position: pos,
          map: map,
        });
        let marker = this.gMap.marker;
        map.addListener("click", (mapsMouseEvent) => {
          if (!this.viewOnly) {
            if (this.formData.address.value.trim().length === 0) {
              this.formData.address.isValid = false;
              this.formData.address.invalidMsg =
                "Please enter delivery full address.";
              return;
            }
            //recheck lat lng in case not within coverage
            this.isValidAddress(mapsMouseEvent.latLng);
            marker.setPosition(mapsMouseEvent.latLng);
          }
        });

        const input = document.getElementById("txtAddress");
        const options = {
          componentRestrictions: { country: "my" },
          fields: [
            "formatted_address",
            "address_components",
            "geometry",
            "name",
          ],
          //types: ["establishment"],
        };
        const autocomplete = new google.maps.places.Autocomplete(
          input,
          options
        );

        autocomplete.addListener("place_changed", () => {
          this.specialHint = false;
          const place = autocomplete.getPlace();
          console.log(place);
          let lat = place.geometry.location.lat();
          let lng = place.geometry.location.lng();

          let addressName = place.name,
            sublocality = "",
            locality = "",
            postalCode = "",
            country = "";
          $.each(place.address_components, (idx, val) => {
            if ($.inArray("sublocality", val.types) > -1) {
              sublocality = val.long_name;
            } else if ($.inArray("locality", val.types) > -1) {
              locality = val.long_name;
            } else if ($.inArray("postal_code", val.types) > -1) {
              postalCode = val.long_name;
            } else if ($.inArray("country", val.types) > -1) {
              country = val.long_name;
            }
          });

          this.formData.address.value = place.formatted_address;

          this.postApi("api/area/GetDeliveryArea", {
            lat: lat,
            lng: lng,
          }).then((result) => {
            if (result.flag == 1) {
              if (result.data.dtArea.length == 1) {
                this.formData.address.isValid = true;
                this.formData.address.areaId = result.data.dtArea[0].id;
                this.formData.address.lat = lat;
                this.formData.address.lng = lng;
                this.formData.address.addressName = addressName;

                if (sublocality.length > 0)
                  this.formData.address.sublocality = sublocality;
                if (locality.length > 0)
                  this.formData.address.locality = locality;
                if (postalCode.length > 0)
                  this.formData.address.postalCode = postalCode;
                if (country.length > 0) this.formData.address.country = country;

                marker.setPosition({ lat: lat, lng: lng });
                map.setCenter({ lat: lat, lng: lng });
              } else {
                this.formData.address.isValid = false;
                this.formData.address.invalidMsg =
                  "Sorry, your address/pinned location is not within our delivery coverage.";
              }
            } else {
              alert(result.msg);
            }
          });
        });
      }
    },
    resetData() {
      this.formData = {
        name: { value: "", isValid: true },
        mobile: { value: "", dialCode: "60", isValid: true },
        address: {
          value: "",
          areaId: null,
          addressName: "",
          sublocality: "",
          locality: "",
          postalCode: "",
          country: "",
          lat: null,
          lng: null,
          isValid: null, // default null = no input yet
          invalidMsg: "",
        },
        buildingName: { value: "", isValid: true },
        floorUnit: { value: "", isValid: true },
        isDefault: { value: false, isValid: true },
      };
      this.btnSaveAddressDisabled = false;
      this.specialHint = false;
      this.addressId = null;
      this.viewOnly = false;
    },
    show(addressId) {
      this.resetData();
      this.initGoogleMap();
      this.newAddressModal.show();
      //bind address details, read only
      if (addressId) {
        this.addressId = addressId;
        this.viewOnly = true;
        this.bindAddress();
      } else {
        this.viewOnly = false;
      }
    },
    bindAddress() {
      this.postApi("api/UserAddress/GetAddress", {
        addressId: this.addressId,
      }).then((result) => {
        console.log(result);
        if (result.flag === 1) {
          this.addressId = result.data.dtAddress[0].id;

          this.formData.name.value = result.data.dtAddress[0].name;
          this.formData.mobile.dialCode = result.data.dtAddress[0].dialCode;
          this.formData.mobile.value = result.data.dtAddress[0].mobile;

          this.formData.address.areaId = result.data.dtAddress[0].areaId;
          this.formData.address.value = result.data.dtAddress[0].address;
          this.formData.address.addressName =
            result.data.dtAddress[0].addressName;
          this.formData.address.sublocality =
            result.data.dtAddress[0].sublocality;
          this.formData.address.locality = result.data.dtAddress[0].locality;
          this.formData.address.postalCode =
            result.data.dtAddress[0].postalCode;
          this.formData.address.country = result.data.dtAddress[0].country;
          this.formData.address.lat = result.data.dtAddress[0].lat;
          this.formData.address.lng = result.data.dtAddress[0].lng;
          this.formData.buildingName.value =
            result.data.dtAddress[0].buildingName;
          this.formData.floorUnit.value = result.data.dtAddress[0].floorUnit;
          this.formData.isDefault.value = result.data.dtAddress[0].isDefault;

          this.formData.address.isValid = true;

          if (this.gMap.marker) {
            const pos = {
              lat: result.data.dtAddress[0].lat,
              lng: result.data.dtAddress[0].lng,
            };
            this.gMap.marker.setPosition(pos);
            this.gMap.map.setCenter(pos);
          }
        } else {
          alert(result.msg);
        }
      });
    },
    saveAddress() {
      let isValid = true;
      if (this.formData.address.value.trim().length === 0) {
        this.formData.address.isValid = false;
        this.formData.address.invalidMsg = "Delivery Address is required.";
        isValid = false;
      } else {
        if (
          this.formData.address.isValid == null ||
          this.formData.address.isValid === false
        ) {
          this.formData.address.isValid = false;
          this.formData.address.invalidMsg =
            "Sorry, your address/pinned location is not within our delivery coverage.";
          isValid = false; //ensure is validated address & validated pin
        }
      }

      if (this.formData.name.value.length === 0) {
        this.formData.name.isValid = false;
        isValid = false;
      }
      if (this.formData.mobile.value.length === 0) {
        this.formData.mobile.isValid = false;
        isValid = false;
      } else if (!this.formData.mobile.isValid) {
        isValid = false; //invalid mobile number
      }
      if (this.formData.floorUnit.value.length === 0) {
        this.formData.floorUnit.isValid = false;
        isValid = false;
      }

      if (!isValid) return;

      this.postApi("api/UserAddress/UpdateAddress", {
        personId: this.personId,
        name: this.formData.name.value,
        dialCode: this.formData.mobile.dialCode,
        mobile: this.formData.mobile.value,
        areaId: this.formData.address.areaId,
        address: this.formData.address.value,
        addressName: this.formData.address.addressName,
        sublocality: this.formData.address.sublocality,
        locality: this.formData.address.locality,
        postalCode: this.formData.address.postalCode,
        country: this.formData.address.country,
        lat: this.formData.address.lat,
        lng: this.formData.address.lng,
        buildingName: this.formData.buildingName.value,
        floorUnit: this.formData.floorUnit.value,
        isDefault: this.formData.isDefault.value,
      }).then((result) => {
        console.log(result);
        if (result.flag === 1) {
          this.$emit("list-address");
          this.newAddressModal.hide();
          this.resetData();
        } else {
          alert(result.msg);
        }
      });
    },
    validateName() {
      const name = this.formData.name;
      const length = name.value.length;
      name.isValid = length >= 2 && length <= 100;
    },
    isValidAddress(latLng) {
      this.btnSaveAddressDisabled = true;
      this.postApi("api/area/GetDeliveryArea", {
        lat: latLng.lat(),
        lng: latLng.lng(),
      })
        .then((result) => {
          if (result.flag == 1) {
            if (result.data.dtArea.length == 1) {
              this.formData.address.areaId = result.data.dtArea[0].id; //in case diff area

              if (this.formData.address.value.length > 0) {
                this.formData.address.isValid = true;
                this.formData.address.lat = latLng.lat();
                this.formData.address.lng = latLng.lng();
              } else {
                this.formData.address.isValid = null;
              }
            } else {
              this.formData.address.isValid = false;
              this.formData.address.invalidMsg =
                "Sorry, your address/pinned location is not within our delivery coverage.";
            }
          } else {
            alert(result.msg);
          }
        })
        .finally(() => (this.btnSaveAddressDisabled = false));
    },
    deleteAddress() {
      if (confirm("Are you sure you want to delete this address?")) {
        if (this.addressId != null && this.addressId.length > 0) {
          this.postApi("api/UserAddress/DeleteAddress", {
            addressId: this.addressId,
          }).then((result) => {
            if (result.flag === 1) {
              this.$emit("list-address");
              this.newAddressModal.hide();
              this.resetData();
            } else {
              alert(result.msg);
            }
          });
        }
      }
    },
  },
};
</script>

<style>
.pac-container {
  z-index: 2000 !important;
}
</style>