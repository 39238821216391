<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <div>New Customers Last Month</div>
                  <h4 class="fw-bold">{{ newCustomerRegister }}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <div>Total Customers</div>
                  <h4 class="fw-bold">{{ totalCustomers }}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <div>Total Booking</div>
                  <h4 class="fw-bold">{{ totalBooking }}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <div>Total Booking Pending</div>
                  <h4 class="fw-bold">{{ totalPending }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "dashboard-default",
  data() {
    return {
      totalSales: "RM 0.00",
      totalCustomers: 0,
      totalOrders: 0,
      totalMeals: 0,
    };
  },
  mounted() {
    this.bindSummary();
  },
  methods: {
    bindSummary() {
      this.postApi("api/dashboard/GetSummary", {})
        .then((result) => {
          console.log(result);
          if (result.flag == 1) {
            this.totalBooking = result.data.dtSummary[0].totalBooking;
            this.totalCustomers = result.data.dtSummary[0].totalCustomers;
            this.newCustomerRegister = result.data.dtSummary[0].newCustomerRegister;
            this.totalPending = result.data.dtSummary[0].totalPending;
          } else {
            alert(result.msg);
          }
        })
        .finally(() => {});
    },
  },
};
</script>
