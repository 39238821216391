<script>
import { AgGridVue } from "@ag-grid-community/vue3";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "jquery/dist/jquery.min.js";
import VmdAlert from "@/components/VmdAlert.vue";
import "axios";
import { Modal } from "bootstrap";
import { left } from "@popperjs/core";


const ToggleComponent = {
  template: '<Toggle v-model="value" on-label="On" off-label="Off" />'
};

export default {
  data() {
    return {
      api: "api/testimonial",
      value: true,
      paginationPageSize: 20,
      rowHeight: 80,
      columnDefs: [],
      rowData: [],
      gridApi: null,
      columnApi: null,
      type: [],
      formData: {
        cmd: 1,
        testimonialId: null,
        f_visible: true,
        f_content: null,
      },
      Validation: {
        vldActivityname: true,
      },
      SuccessAlert: false,
      dangerAlert: false,
      returnmsg: null,
      userRemove: false,
      deleteActivityModal: null,
      deleteActivityName: "",
      deleteActivityError: { show: false, msg: "" },
      showTestiModal: null,
      selectedRowData: {
        imageUrl: [],
        testimonialMessage: "",
      },
    };
  },
  components: {
    AgGridVue,
    VmdAlert,
    ToggleComponent,
  },

  setup() {
    return {
      defaultColDef: {
        resizable: false,
      },
      modules: [ClientSideRowModelModule],
    };
  },
  methods: {
    async getData() {
      await this.getApi(this.api).then((result) => {
        if (result.flag === 1) {
          this.rowData = result.data;
        } else {
          alert(result.msg);
        }
      });
    },
    async deleteActivity() {
      await this.postApi("api/testimonial/delete", {
        testimonialId: this.formData.testimonialId,
        masterPassword: document.getElementById("txtMasterPassword").value,
      }).then((result) => {
        if (result.flag === 1) {
          this.getData();
          this.deleteActivityModal.hide();
          this.SuccessAlert = true;
          this.returnmsg = "Testimonial has been deleted.";
        } else {
          //alert(result.msg);
          this.deleteActivityError.show = true;
          this.deleteActivityError.msg = result.msg;
        }
      });
    },
    checkValidation() {
      var isEmpty = false;

      if (!this.formData.f_activityName.value) {
        this.Validation.vldActivityname = false;
        isEmpty = true;
      } else {
        this.Validation.vldActivityname = true;
        isEmpty = false;
      }

      return isEmpty;
    },
    onPageSizeChanged() {
      var value = document.getElementById("page-size").value;
      this.paginationPageSize = Number(value);
      this.$forceUpdate();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.ColumnApi = params.columnApi;
      this.sizeToFit();
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },

    autoSize() {
      this.ColumnApi.autoSizeAllColumns(false);
    },
    onEditorReady(e) {
      e.container.querySelector(".ql-blank").innerHTML = this.formData.f_content;
    },
    UpdateAlert(value) {
      this.SuccessAlert = value;
      this.dangerAlert = value;
    },

    clearfield() {
      this.userRemove = false;
      this.formData.cmd = 1;
    },
    async ActivitySelected() {
      await this.postApi("api/testimonial/TestimonialDetail", {
        id: this.formData.testimonialId,
      }).then(async (result) => {
        if (result.flag === 1) {
          var Data = result.data[0];

          console.log("TestimonialsMsg " + Data.testimonialsMsg);

          this.formData.f_content = Data.testimonialsMsg;
          this.selectedRowData.imageUrl = JSON.parse(Data.tPhoto);

        } else {
          alert(result.msg);
        }
      });
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
  },

  async beforeMount() {
    await this.getData();
    this.columnDefs = [
      {
        headerName: "Member",
        field: "name",
        sortable: true
      },
      {
        headerName: "Testimonial Message",
        field: "testimonialsMsg",
        filter: "agTextColumnFilter",
        width: 480,
        minWidth: 200,
        cellRenderer: (params) => {
          return `<b>${params.value}</b>`;
        },
      },
      {
        headerName: "Publish",
        field: "status",
        cellRenderer: (params) => {
          var div = document.createElement("div");
          div.className = "switchToggle";
          var input = document.createElement("input");
          input.setAttribute('id', params.data.id);
          input.type = "checkbox";
          input.checked = params.value == 1;
          input.addEventListener("change", async (e) => {

            await this.postApi("api/testimonial/visible", { testimonialId: params.data.id, isVisible: input.checked }).then((result) => {
              if (result.flag === 1) {
                this.SuccessAlert = true;
                this.returnmsg = "Update Successful"
              } else {
                this.dangerAlert = true;
                this.returnmsg = result.msg;
                input.checked = !input.checked;
              }
            });
          });

          var label = document.createElement("label");
          label.setAttribute('for', params.data.id);
          label.innerHTML = "Toggle";
          div.appendChild(input);
          div.appendChild(label);

          return div;
        },
      },
      {
        headerName: "Edit",
        field: "id",
        cellRenderer: (params) => {
          var link = document.createElement("a"); // edit btn
          link.href = "#";
          link.className = "fa fa-search d-block text-dark m-0 p-3";
          link.style.cssText = " border-right: 1px solid #c8c8d1;";

          link.addEventListener("click", (e) => {
            //this.$refs.btnAddActivity.click();
            this.formData.testimonialId = params.data.id;
            this.ActivitySelected();
            this.showTestiModal.show();
          }); // onClick function

          var linkdelete = document.createElement("a"); // delete btn
          linkdelete.href = "#";
          linkdelete.className = "fa fa-trash d-block text-danger m-0 p-3";

          // linkdelete.setAttribute("data-bs-toggle", "modal");
          // linkdelete.setAttribute("data-bs-target", "#DeleteModal");
          linkdelete.addEventListener("click", (e) => {
            e.preventDefault();
            this.formData.testimonialId = params.data.id;
            this.deleteActivityName = params.data.name;
            document.getElementById("txtMasterPassword").value = "";
            this.deleteActivityError.show = false;
            this.deleteActivityError.msg = "";
            this.deleteActivityModal.show();
          }); // onClick function
          var div = document.createElement("div");
          div.className = "d-flex";
          div.appendChild(link);
          div.appendChild(linkdelete);
          return div;
        },
      },
    ];
  },
  computed: {
    formattedContent() {
      return this.formData.f_content;
    },
  },
  mounted() {
    this.deleteActivityModal = new Modal(this.$refs.DeleteModal);
    this.showTestiModal = new Modal(this.$refs.ShowModal);
  },
};
</script>

<template>
  <div class="py-4 container-fluid">
    <vmd-alert class="font-weight-light" color="success" :dismissible="SuccessAlert" v-on:update="UpdateAlert($event)">
      <span class="text-sm">{{ returnmsg }}</span>
    </vmd-alert>

    <div class="d-md-flex justify-content-between mx-3 align-items-center">
      <div class="d-md-flex align-items-center my-2">
        <div class="d-flex align-items-center ">
          <span class="d-block text-nowrap me-1">Page Size:</span>
          <select v-on:change="onPageSizeChanged()" id="page-size" class="form-select" style="width:80px">
            <option value="20" selected="">20</option>
            <option value="100">100</option>
            <option value="150">150</option>
            <option value="200">200</option>
          </select>
        </div>
        <div class="row ms-md-2  mt-3  mb-3 mt-md-0 mb-md-0">
          <div class="col-12">
            <button class="btn btn-secondary mb-0 me-1" @click="sizeToFit()">
              Size to Fit
            </button>
            <button class="btn btn-secondary mb-0" @click="autoSize()">
              Auto-Size
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="grid-wrapper">
      <ag-grid-vue style="width: auto; height: 70vh" class="ag-theme-alpine" :columnDefs="columnDefs"
        :rowHeight="rowHeight" :defaultColDef="defaultColDef" :rowData="rowData" :modules="modules"
        :key="paginationPageSize" :pagination="true" :paginationPageSize="paginationPageSize" @grid-ready="onGridReady"
        @first-data-rendered="onFirstDataRendered">
      </ag-grid-vue>
    </div>

    <!-- Testimonial Modal -->
    <div class="modal fade" ref="ShowModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog custom-width modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Testimonial Details</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="image-gallery">
              <div v-for="(image, index) in selectedRowData.imageUrl" :key="index" class="image-container">
                <img :src="this.formatImageUrl(image.filePath)" :alt="image.fileName" />
              </div>
            </div>
            <div class="t-content" v-html="formattedContent"></div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Delete Activity Modal -->
    <div class="modal fade" ref="DeleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Delete Testimonial</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <VmdAlert class="font-weight-light show" color="danger" :dismissible="deleteActivityError.show"
              v-on:update="(e) => (deleteActivityError.show = e)">
              <span class="text-sm">{{ deleteActivityError.msg }}</span>
            </VmdAlert>
            Are you sure you want to delete this testimonial ({{ deleteActivityName }})? Enter master password
            to proceed.
            <input type="password" class="form-control mt-2" placeholder="Master Password" maxlength="20"
              id="txtMasterPassword" autocomplete="off" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button type="button" class="btn btn-danger" v-on:click="deleteActivity">
              Confirm Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

.image-gallery {
  display: flex;
  flex-wrap: wrap; /* Allow images to wrap to the next row */
  justify-content: left; /* Center items horizontally */
}

.image-container {
  flex: 0 0 calc(33.33% - 20px); /* Adjust the width based on your desired number of columns (e.g., 3 columns) */
  margin: 10px; /* Add some spacing between images */
  text-align: center; /* Center images horizontally */
}

.image-container img {
  max-width: 100%;
  height: auto;
}

.custom-width {
  max-width: 560px;
  min-width: 420px;
}

.t-content {
  word-wrap: break-word;
}


.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.fa::before {
  margin-right: 10px !important;
}

.form-control:focus {
  border-color: var(--bs-primary) !important;
}

.form-control {
  border: 1px solid var(--bs-secondary) !important;

  padding: 0.5rem 0.75rem !important;
}

.form-control.is-invalid {
  border-color: #fd5c70 !important;
}

.form-select {
  border: 1px solid var(--bs-secondary) !important;
  padding: 0.5rem 0.75rem !important;
  background-position: right 7px center !important;
}

.form-select:focus {
  border-color: var(--bs-primary) !important;
}

.switchToggle input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.switchToggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 70px;
  max-width: 70px;
  height: 30px;
  background: #d1d1d1;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switchToggle label:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.switchToggle input:checked+label,
.switchToggle input:checked+input+label {
  background: #4EA952;
}

.switchToggle input+label:before,
.switchToggle input+input+label:before {
  content: 'Off';
  position: absolute;
  top: -6px;
  left: 35px;
  width: 26px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}

.switchToggle input:checked+label:before,
.switchToggle input:checked+input+label:before {
  content: 'On';
  position: absolute;
  top: -6px;
  left: 10px;
  width: 26px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}

.switchToggle input:checked+label:after,
.switchToggle input:checked+input+label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switchToggle label:active:after {
  width: 60px;
}

#ModelToggleSwitch input+label:before,
.switchToggle input+input+label:before {
  top: 5px !important;
}

#ModelToggleSwitch input+label:before,
.switchToggle input+input+label:before {
  top: 5px !important;
}

#ModelToggleSwitch label {
  margin-bottom: 5px;
}

.quill-container {
  height: 300px;
}
</style>
