<template>
  <input
    type="text"
    ref="txtPhone"
    class="form-control"
    id="mobile"
    :value="removeNonNumber(modelValue.value)"
    :class="[isInvalid && 'is-invalid']"
    @change="onChange"
    @keypress="onKeypress"
    :disabled="disable"
    placeholder="12345678"
  />

  <div class="invalid-feedback" :class="[isInvalid && 'd-block']">
    {{ errMsg }}
  </div>
</template>

<script>
import intlTelInput from "intl-tel-input";
import * as intlUtils from "intl-tel-input/build/js/utils.js";
export default {
  data() {
    return {
      iti: undefined,
      modelValues: this.modelValue,
    };
  },

  props: {
    modelValue: Object,
    isInvalid: Boolean,
    errMsg: String,
    disable: { type: Boolean, default: false }, // trim input value
  },
  mounted() {
    const input = this.$refs.txtPhone;
    this.iti = intlTelInput(input, {
      formatOnDisplay: true, //prevent formatting during bind
      onlyCountries: ["my"],
      separateDialCode: true,
      utilsScript: intlUtils,
      allowDropdown: false,
    });
  },
  methods: {
    removeNonNumber(str) {
      return str?.replaceAll(/\D/g, "");
    },
    onChange(event) {
      const val = this.removeNonNumber(event.target.value);
      this.modelValue.value = val;
      this.modelValue.isValid = this.iti.isValidNumber();
      this.$emit("update:modelValue", this.modelValue);
      if (val.length !== event.target.value.length) {
        event.target.value = val;
      }
    },
    onKeypress(event) {
      if (!/^[0-9]$/g.test(event.key)) event.preventDefault();
    },
  },
};
</script>

<style>
@import "../../node_modules/intl-tel-input/build/css/intlTelInput.css";
.iti {
  width: 100%;
}
.iti--separate-dial-code .iti__selected-flag {
  background-color: var(--bs-primary) !important;
  color: white;
}
#mobile {
  padding-left: 73px !important;
}
</style>
