<template>
  <div ref="modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Order Item</h5>
        </div>
        <div class="modal-body">
          <VmdAlert
            class="font-weight-light show"
            color="danger"
            :dismissible="error.show"
            v-on:update="(e) => (error.show = e)"
          >
            <span class="text-sm" v-html="error.msg"></span>
          </VmdAlert>
          <div class="row">
            <div class="col-12" v-show="changeDelivery">
              <DeliveryCalendar
                ref="deliveryCalendar"
                @complete="onCompleteChangeDeliveryTime"
                @cancel="onCancelChangeDeliveryTime"
                @error="(msg) => showError(msg)"
              />
            </div>
            <div class="col-12" v-show="!changeDelivery">
              <div class="row mb-2">
                <div class="col-12 mb-2 d-flex">
                  <img :src="item?.image" class="package-image" />
                  <div class="h5 d-lg-inline-block py-3 ms-3 m-0">
                    {{ item?.packageTitle }}<br />
                    {{
                      item?.discountedItemPrice
                        ? "RM" + item.discountedItemPrice.round(2)
                        : ""
                    }}
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <label>Pax</label>
                  <select class="form-select" v-model="formData.pax">
                    <option value="" hidden selected>Pax</option>
                    <option v-for="pax in formSetting.pax" :key="pax" :value="pax">
                      {{ formatPaxText(pax) }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 mb-3">
                  <label>Packing</label>
                  <select class="form-select" v-model="formData.packing" :disabled="this.item.productType==2 || this.item.productType==3">
                    <option value="" hidden selected>Tiffin/Container</option>
                    <option
                      v-for="pack in formSetting.packing"
                      :key="pack"
                      :value="pack.id"
                    >
                      {{ pack.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 mb-3">
                  <label>White Rice</label>
                  <select class="form-select" v-model="formData.whiteRice">
                    <option value="" hidden selected>White Rice</option>
                    <option
                      v-for="rice in formSetting.whiteRice"
                      :key="rice"
                      :value="rice"
                    >
                      {{ rice }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 mb-3">
                  <label>Delivery Time</label>
                  <SubmitButton
                    class="btn btn-outline-primary d-block col-12 form-control"
                    @click="onSelectDeliveryTime"
                  >
                    Change Delivery Date & Time
                  </SubmitButton>

                  <div
                    class="invalid-feedback"
                    :class="
                      formData.mealSelected != formSetting.totalMeal &&
                      clickedSubmit &&
                      'is-invalid'
                    "
                  >
                    Change delivery date & time
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label>Pick Meal Requirements</label>
                  <Multiselect
                    v-model="formData.mealReq"
                    :options="formSetting.mealReq"
                    :searchable="true"
                    :can-clear="false"
                    :close-on-select="false"
                    placeholder="(No Pork, No Spicy, Etc)"
                    mode="tags"
                    :max="5"
                  />
                </div>
                <div class="col-12 mb-3">
                  <label>Handover Method</label>
                  <select class="form-select" v-model="formData.handover">
                    <option value="" hidden selected>
                      (Contactless Delivery, Put On Table, Etc)
                    </option>
                    <option v-for="h in formSetting.handover" :key="h" :value="h.id">
                      {{ h.name }}
                    </option>
                  </select>
                  <div class="invalid-feedback">Please choose a handover method.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-show="!changeDelivery">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
          <SubmitButton class="btn btn-primary" @click="onSaveEdit">
            Save changes
          </SubmitButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import Multiselect from "@vueform/multiselect"; // https://github.com/vueform/multiselect
import DeliveryCalendar from "./DeliveryCalendar-1.vue";
import SubmitButton from "./SubmitButton.vue";
import VmdAlert from "../../../components/VmdAlert.vue";

export default {
  //inject: ["areaId"],
  props: ['area'],
  components: { Modal, Multiselect, DeliveryCalendar, SubmitButton, VmdAlert },
  data() {
    return {
      modal: null,
      //area: this.areaId,
      //area: null,
      changeDelivery: false,
      item: {},
      formData: {
        packing: null,
        delivery: {},
        mealReq: [],
        handover: null,
        pax: null,
        whiteRice: null,
      },
      formSetting: {
        packing: [],
        mealReq: [],
        mealReqOri: [],
        handover: [],
        pax: [],
        whiteRice: [],
      },
      error: { show: false, msg: "" },
    };
  },
  methods: {
    showModal(item) {
      this.hideError();

      //rebind meal req
      if (item.productType == "2" || item.productType == "3") this.formSetting.mealReq = this.formSetting.mealReq.filter(a => a.bento == 1); //bento meal req
      else this.formSetting.mealReq = this.formSetting.mealReqOri; //tiffin meal req

      this.item = item;
      this.formData.packing = item.pkid;
      this.formData.handover = item.hoid;
      this.formData.mealReq = item.mealReq.map((mr) => mr.mid);
      this.formData.pax = item.pax;
      this.formData.whiteRice = item.whiteRice;

      let delivery = {};
      item.delivery.forEach(
        (d) => (delivery[new Date(d.deliveryDate).toDateString()] = d.meal)
      );
      this.formData.delivery = delivery;

      //rebind tiffin package max 5 pax
      if (this.item.productType == 1) this.formSetting.pax =  [...Array(5).keys()].map((k) => k + 1);
      else if (this.item.productType == 3) this.formSetting.pax =  [2,3,4,5,6,7,8,9,10];

      this.modal.show();
    },

    async onSelectDeliveryTime(stopLoading) {
      this.changeDelivery = true;
      await this.$refs.deliveryCalendar.bind(
        this.formData.delivery,
        this.item.totalMeal,
        this.area,
        this.item.id
      );

      stopLoading();
    },
    onCompleteChangeDeliveryTime(data) {
      this.changeDelivery = false;
      this.formData.delivery = data.dateList;
    },
    onCancelChangeDeliveryTime() {
      this.changeDelivery = false;
    },

    async onSaveEdit(stopLoading) {
      const delivery = Object.keys(this.formData.delivery).map((d) => ({
        date: new Date(d).format("yyyy-MM-dd"),
        time: this.formData.delivery[d],
      }));
      const mealReq = this.formData.mealReq.map((m) => ({ id: m }));
      const data = {
        itemId: this.item.id,
        areaId: this.area,
        packing: this.formData.packing,
        delivery,
        mealReq,
        handover: this.formData.handover,
        pax: this.formData.pax,
        whiteRice: this.formData.whiteRice
      };

      const res = await this.postApi("api/order/save-item", data);
      //   console.log("order/save-item", res);
      if (res.flag === 1) {
        this.$router.go();
      } else {
        this.showError(res.msg);
      }

      stopLoading();
    },

    formatPaxText(pax) {
      if (this.item.productType == 1) {
        if (pax === 1) return `S Size (1-2 Adults)`;
        else if (pax === 2) return `M Size (2-3 Adults)`;
        else if (pax === 3) return `L Size (4-5 Adults)`;
        else if (pax === 4) return `XL Size (5-6 Adults)`;
        else if (pax === 5) return `XXL Size (6-7 Adults)`;
        else return pax;
      }
      else {
        return pax + ' Pax';
      }
    },

    showError(msg) {
      this.error.show = true;
      this.error.msg = msg;
    },
    hideError() {
      this.error.show = false;
    },
  },

  async created() {
    let res = await this.getApi('api/order/edit-setting');
    // console.log("order/edit-setting", res);
    if (res.flag === 1) {
      const ds = res.data.ds;
      const setting = res.data.setting;
      this.formSetting.packing = ds.packing;
      this.formSetting.mealReq = ds.mealReq.map((m) => ({
        value: m.id,
        label: m.name,
        bento: m.bento
      }));
      this.formSetting.mealReqOri = this.formSetting.mealReq;
      this.formSetting.handover = ds.handover;

      this.formSetting.pax = [...Array(setting.MAX_PAX).keys()].map((k) => k + 1);
      this.formSetting.whiteRice = [...Array(setting.MAX_WHITE_RICE + 1).keys()].map((k) => k);
    } else {
      this.showError(res.msg);
    }
  },

  mounted() {
    this.modal = new Modal(this.$refs.modal, {
      backdrop: "static",
      keyboard: false,
    });
  },
};
</script>

<style scoped>
.package-image {
  object-fit: cover;
  border-radius: 5px;
  width: 100px;
  height: 100px;
}
</style>
