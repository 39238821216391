<template>
<div class="mb-1">
    <div  class=" paxRow">
        <span >{{ label }}</span><input type="number"   :value="modelValue" min="0"   @change="onChange"  class="form-control w-50" :class="`${getStatus(isInvalid)}`" placeholder="Price" step=".10" />
          
    </div>
    <div class="invalid-feedback text-end  mt-0"  v-bind:class="{ 'd-block': !isInvalid }">This field is required</div>
</div>
</template>
<style >
.paxRow{
  display: flex;
  justify-content: space-between;
  align-items: center ;
}
</style>
<script>
import setMaterialInput from "@/assets/js/material-input.js";

export default {
  name: "vmd-input",
  props: {
    isInvalid:{ type: Boolean,
      default: true,
    }, 
    label: String,
    modelValue: Number,
  },
  methods: {

   onChange(event) {
    const value = event.target.value;
      event.target.value = this.trim ? value.trim() : value;
      this.$emit("update:modelValue", parseFloat(  event.target.value));
    },
    getStatus: (isInvalid) => {
      let isValidValue;

      if (isInvalid) {
        isValidValue = "";
      } else {
        isValidValue = "is-invalid";
      } 

      return isValidValue;
    },
  },
  mounted() {
    setMaterialInput();
  },
};
</script>
