export function OrderStatus(status) {
    switch (status) {
        case 0: return 'pending';
        case 1: return 'ongoing';
        case 2: return 'complete';
        case -2: return 'expired';
        default: return null;
    }
}

export function OrderItemStatus(status) {
    switch (status) {
        case 1: return 'pending';
        case 2: return 'ongoing';
        case 3: return 'complete';
        case 9: return 'expired';
        default: return null;
    }
}

export function PaymentStatus(status) {
    switch (status) {
        case 0: return 'pending';
        case 1: return 'complete';
        case 9: return 'expired';
        default: return null;
    }
}