
<style>
.iti__flag-container {
  padding: 0;
}
.iti--separate-dial-code .iti__selected-flag {
  background-color: var(--color-primary);
  color: white;
}
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}
.fa::before {
    margin-right: 0px !important;
}
</style>
<script>
import TextInput from "../components/TextInput.vue";
import MobileInput from "../components/MobileInput.vue";
import PasswordInput from "../components/PasswordInput.vue";
import VmdAlert from "@/components/VmdAlert.vue";
var equalsFilterParams = {
   suppressAndOrCondition: true,

  filterOptions: [
    'equals',
    {
      displayKey: 'betweenExclusive',
      displayName: 'Between (Exclusive)',
      predicate: ([fv1, fv2], cellValue) => {
        if (cellValue == null) return true;
        var parts = cellValue.split('/');
        var cellDate = new Date(
          Number(parts[2]),
          Number(parts[1] - 1),
          Number(parts[0])
        );
        return (
          cellDate.getTime() > fv1.getTime() &&
          cellDate.getTime() < fv2.getTime()
        );
      },
      numberOfInputs: 2,
    },
  ],
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split('/');
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};



import { AgGridVue } from "@ag-grid-community/vue3";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
export default {
    setup() {
    return {
      defaultColDef: {
        resizable: true,
      },
      modules: [ClientSideRowModelModule],
    };
  },
  data() {
    return { 
          api: "api/admin",
          cmd:1,
      paginationPageSize: 20,
      rowHeight: 80,
      columnDefs: [],
      rowData: [],
      gridApi: null,
      columnApi: null,    
        formMsg: { show: false, success: false, msg: "" },
      formData: {
        name: { value: "", isValid: true },
        email: { value: "", isValid: true },
        mobile: { value: "", dialCode: "60", isValid: true },
        password: { value: "", isValid: true },
        confirmPassword: { value: "", isValid: true },
      },
      IdSelected:null,
        dangerAlert: false,
      SuccessAlert: false,
      Successmsg: null,
      dangermsg: null,
             modaldangerAlert: false,
                   Modaldangermsg: null,

    }
  },
  components:{
    AgGridVue,
    VmdAlert,
    TextInput,
    PasswordInput,
    MobileInput
  },
  methods:{
        async getdata() {
      await this.getApi(this.api).then((result) => {
        if (result.flag === 1) {
          // console.log(result.data);
          this.rowData = result.data;
        }
        else{
          this.dangerAlert=true;
          this.dangermsg=result.msg;
        }
      });
    },
    async deleteAdmin() {
      await this.postApi("api/admin/delete", {
        id: this.IdSelected,
      }).then((result) => {
        if (result.flag === 1) {
          this.IdSelected=null;
          this.getdata();
        }else{
          this.SuccessAlert=false;
            this.dangerAlert=true;
            this.dangermsg=result.msg
        }
      });
    },

        onPageSizeChanged() {
      var value = document.getElementById("page-size").value;
      this.paginationPageSize = Number(value);
      console.log(this.paginationPageSize);
      this.$forceUpdate();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.ColumnApi = params.columnApi;
      this.sizeToFit();
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },

    autoSize() {
      this.ColumnApi.autoSizeAllColumns(false);
    },
        UpdateAlert(value) {
      this.SuccessAlert = value;
      this.dangerAlert = value;
        this.modaldangerAlert=value

    },
        validateName() {
      const name = this.formData.name;
      const length = name.value.length;
      name.isValid = length >= 2 && length <= 100;
    },
    validateEmail() {
      const email = this.formData.email;
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      email.isValid = regex.test(email.value);
    },
    resetForm() {
      this.IdSelected=null;
      this.cmd=1;
      this.formData = {
        name: { value: "", isValid: true },
        email: { value: "", isValid: true },
        mobile: { value: "", dialCode: "60", isValid: true },
        password: { value: "", isValid: true },
        confirmPassword: { value: "", isValid: true },
      };
    },
       onSubmit() {
      // check empty field
      
      Object.keys(this.formData).forEach((k) => {
        if (this.formData[k].value.length === 0) this.formData[k].isValid = false;
        if(this.cmd==2&&this.formData[k].value==""&&k=="password" ) this.formData[k].isValid = true;
        if(this.cmd==2&&this.formData[k].value==""&&k=="confirmPassword" ) this.formData[k].isValid = true;
      });

      const isFormValid = Object.keys(this.formData).every(
        (k) => this.formData[k].isValid
      );

      if (!isFormValid) {
   
        return;
      }

      if(this.formData.password.value!=this.formData.confirmPassword.value)
      {
        this.formData.confirmPassword.isValid=false;
        return;
      }
  

      this.postApi("api/admin/insert", {
        cmd:this.cmd,
        selectedId:this.IdSelected,
        name: this.formData.name.value,
        email: this.formData.email.value,
        mobile: this.formData.mobile.value,
        dialCode: this.formData.mobile.dialCode,
        password: this.formData.password.value,
      }).then((result) => {
        if (result.flag === 1) {
          this.SuccessAlert = true;
          this.dangerAlert = false;
          this.Successmsg="Add new admin success"
             this.modaldangerAlert = false ;
                        this.$refs.btnClose.click();

          this.resetForm();
          this.getdata();
        } else {
          this.SuccessAlert = false;
          this.modaldangerAlert = true ;
          this.Modaldangermsg=result.msg;
        }
      });
    },
   async personDetail(){
    await this.postApi("api/admin/Detail", {
        id: this.IdSelected,
      }).then((result) => {
        if (result.flag === 1) {
          this.cmd=2
          this.formData.name.value=result.data[0].name
          this.formData.email.value=result.data[0].email
          this.formData.mobile.value=result.data[0].mobile
        }else{
          this.dangerAlert=true;
          this.SuccessAlert=false;
          this.dangermsg=result.msg
        }
      });

    }
  },
    async beforeMount() {
    await this.getdata();
    this.columnDefs = [
                 {
        headerName: "#",
        field: "row",
        sortable:true,
              cellRenderer: (params) => {
          //console.log(params);
          return `<b>${params.value}</b>`;
        },
        width:70
      },
           {
        headerName: "UserCode",
        field: "userCode",
      },
      {
        headerName: "Name",
        field: "name",
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          //console.log(params);
          return `<b>${params.value}</b>`;
        },
      //              floatingFilter: true,
      // floatingFilterComponentParams: {
      //   suppressFilterButton: true,
      // },

      },
 
      { headerName: "Email", field: "email" },
       
      { headerName: "Mobile", field: "mobile" },
      {
        headerName: "Register Date",
        field: "created",
        filter: "agDateColumnFilter",
        filterParams: equalsFilterParams
      },
      {
        headerName: "Edit",
        field: "id",
        cellRenderer: (params) => {
          var link = document.createElement("a"); // edit btn
          link.href = "#";
          link.className = "fa fa-pen d-block text-dark m-0 p-3";
              link.style.cssText =" border-right: 1px solid #c8c8d1;"
          link.addEventListener("click", (e) => {
            e.preventDefault();
            this.resetForm();
            this.$refs.btnAddProduct.click();
            this.IdSelected = params.data.id;
             this.personDetail();
          }); // onClick function

          var linkdelete = document.createElement("a"); // delete btn
          linkdelete.href = "#";
          linkdelete.className = "fa fa-trash d-block text-danger p-3";
 
          linkdelete.setAttribute("data-bs-toggle", "modal");
          linkdelete.setAttribute("data-bs-target", "#DeleteModal");
          linkdelete.addEventListener("click", (e) => {
            e.preventDefault();
           this.IdSelected = params.data.id
          }); // onClick function
          var div = document.createElement("div");
          div.className = "d-flex";
          div.appendChild(link);
          div.appendChild(linkdelete);
          return div;
        },
      },
    ];
  },
};
</script>
<template>
     
      <div class="container">
        <vmd-alert
        class="font-weight-light"
        color="danger"
        :dismissible="dangerAlert"
        v-on:update="UpdateAlert($event)"
      >
        <span class="text-sm">{{ dangermsg }}</span>
      </vmd-alert>
      <vmd-alert
        class="font-weight-light"
        color="success"
        :dismissible="SuccessAlert"
        v-on:update="UpdateAlert($event)"
      >{{ Successmsg }}</vmd-alert> 
      <div class="col-12 text-end d-flex justify-content-between">
               <div class="row mt-4 mb-2">
          <div class="col-12">
            <button class="btn btn-secondary mb-0 me-1" @click="sizeToFit()">
              Size to Fit
            </button>
            <button class="btn btn-secondary mb-0" @click="autoSize()">
              Auto-Size
            </button>
          </div>
        </div>
             <button
          class="btn  btn-primary"
          ref="btnAddProduct"
          data-bs-toggle="modal"
          data-bs-target="#AddModal"
          v-on:click="resetForm"
        >
          New Admin
        </button>
        </div>
    <ag-grid-vue
      style="width: auto; height: 70vh"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowHeight="rowHeight"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :modules="modules"
      :key="paginationPageSize"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>

    </div>

   <!--Delete product Modal -->
    <div
      class="modal fade"
      id="DeleteModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Delete Admin</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">Are you sure you want to delete this Admin?</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button
              type="button"
              class="btn btn-danger"
              v-on:click="deleteAdmin"
              data-bs-dismiss="modal"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>

<!-- 
insert data Modal  -->
   <div
      class="modal fade"
      id="AddModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
         
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header d-inline">
            <h5 class="modal-title ms-2" id="exampleModalLabel"> {{this.cmd==1?'Add New Admin':'Update Admin'}}</h5>
            <vmd-alert
              class="font-weight-light"
              color="danger"
              :dismissible="modaldangerAlert"
              v-on:update="UpdateAlert($event)"
            >
              <span class="text-sm">{{ Modaldangermsg }}</span>
            </vmd-alert>
          </div>
          <div class="modal-body">
          <div class="row">
   
          <div class="col-12 mb-2">
            <label>Full Name</label>
            <TextInput
              placeholder="Name"
              :trim="true"
              :is-invalid="!formData.name.isValid"
              @blur="validateName()"
              v-model="formData.name.value"
              err-msg="Invalid Name."
            />
          </div>
          <div class="col-12 mb-2">
            <label>Email Address</label>
            <TextInput
              placeholder="example@example.com"
              :trim="true"
              :is-invalid="!formData.email.isValid"
              @blur="validateEmail()"
              v-model="formData.email.value"
              err-msg="Invalid Email."
            />
          </div>
          <div class="col-12 mb-2">
            <label class="d-block">Phone Number</label>
            <MobileInput
              :is-invalid="!formData.mobile.isValid"
              v-model="formData.mobile"
              err-msg="Invalid mobile number."
            />
          </div>
          <div class="col-12 mb-2">
            <label>New Password</label>
            <PasswordInput
              :is-invalid="!formData.password.isValid"
              :show-criteria="true"
              :key="formData.password"
              v-model="formData.password"
              ref="password"
              err-msg="Invalid Password."
            />
          </div>
          <div class="col-12 mb-2">
            <label>Confirm New Password</label>
            <PasswordInput
            ref="confirmPassword"
                   :key="formData.confirmPassword"
              :is-invalid="!formData.confirmPassword.isValid"
              v-model="formData.confirmPassword"
              err-msg="Password entered does not match."
            />
          </div>
  
        </div>
            </div>  
          <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary me-2"
                ref="btnClose"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary"  @click="onSubmit">
               {{this.cmd==1?'Add':'Save'}}
              </button>
          </div>
        </div>
      </div>
    </div>



  

</template>





