<template>
  <div class="row mt-3">
    <div class="col-6">Item(s) Price:</div>
    <div class="col-6 text-end">{{ currencySymbol }} {{ Number(totalPrice).round(2) }}</div>
    <!-- <template v-if="promoRebate > 0">
      <div class="col-6">Promo Code Rebate:</div>
      <div class="col-6 text-end text-danger">- RM {{ promoRebate.round(2) }}</div>
    </template> -->
  </div>
  <hr />
  <div class="row mb-2">
    <div class="col-6">Total:</div>
    <div class="col-6 text-end fw-bold">{{ currencySymbol }} {{ amountToPay.round(2) }}</div>
  </div>
</template>

<script>
export default {
  props: {
    totalPrice: { type: [Number, String], default: 0 },
    totalDeliveryFee: { type: [Number, String], default: 0 },
    currencySymbol: { type: [String], default: "" },
    amountToPay: { type: [Number, String], default: 0 },
  },
};
</script>
