<template>
  <input
    type="text"
    class="form-control"
    :placeholder="placeholder"
    :class="[isInvalid && 'is-invalid']"
    :value="modelValue"
    @blur="$emit('blur')"
    @change="onChange"
    :maxlength="[maxLength > 0 ? maxLength : '']"
    :disabled="isDisable"
  />
  <div class="invalid-feedback">{{ errMsg }}</div>
</template>

<script>
export default {
  name: "TextInput",
  emits: ["blur"],
  props: {
    placeholder: String,
    modelValue: String,
    isInvalid: Boolean,
    trim: { type: Boolean, default: false }, // trim input value
    errMsg: { type: String, default: "Invalid value." }, // error message to display when fnValidate=false
    maxLength: { type: Number, default: -1 }, // -1 = no limit
    isDisable: { type: Boolean, default: false }, // true for disable input
  },
  methods: {
    onChange(event) {
      const value = event.target.value;
      event.target.value = this.trim ? value.trim() : value;
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<style></style>
