import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Product from "../views/Product.vue";
import Billing from "../views/Billing.vue";
import Notifications from "../views/Notifications.vue";
import Profile from "../views/Profile.vue";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import Banner from "../views/Banner.vue";
import Gallery from "../views/Gallery.vue";
import Booking from '../views/Booking.vue';
import BookingDetail from '../views/BookingDetail.vue';
import CreatedAdmin from "../views/CreatedAdmin.vue"
import Setting from "../views/Setting.vue"
import Payment from "../views/Payment.vue"
import Customer from '../views/Customer.vue'
import CustomerDetail from '../views/CustomerDetail.vue'
import PaymentDetail from '../views/PaymentDetail.vue'
import Activity from '../views/Activity.vue'
import Testimonial from '../views/Testimonial.vue'

const routes = [
  {
    path: "/",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: '/banner',
    name: "Banner",
    component: Banner,
  },
  {
    path: '/gallery',
    name: "Gallery",
    component: Gallery,
  },
  {
    path: '/booking',
    name: 'Booking',
    component: Booking
  },
  {
    path: '/booking/:bookingId/:personId',
    name: 'BookingDetail',
    component: BookingDetail
  },
  {
    path: '/createdAdmin',
    name: "Admin",
    component: CreatedAdmin,
  },
  {
    path: '/setting',
    name: "Settings",
    component: Setting,
  },
  {
    path: '/payment',
    name: "Payment",
    component: Payment,
  },
  {
    path: '/payment/:id',
    name: "Payment / Payment Detail",
    component: PaymentDetail,
  },
  {
    path: '/customer',
    name: "Customer",
    component: Customer,
  },
  {
    path: '/customer/:id',
    name: 'Customer / Customer Detail',
    component: CustomerDetail
  },
  {
    path: '/activity',
    name: "Activity",
    component: Activity,
  },
  {
    path: '/testimonial',
    name: "Testimonial",
    component: Testimonial,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
