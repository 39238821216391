<template>
  <div class="row mx-0 py-2 border-bottom">
    <div class="col-9">
      <slot name="product" :data="data">
        <OrderItemDetail :data="data" />
      </slot>
    </div>
    <div class="col-1 text-center">{{ data.adult + data.infant + data.children }}</div>
    <div class="col-2 text-nowrap text-center">
      {{ data.currencySymbolOrder + ' ' + data.totalPrice?.round(2) }}
    </div>
    <!-- <div class="col" v-if="showAction">
      <div class="d-flex justify-content-between align-content-center">
        <button type="button" class="btn btn-outline-secondary" @click="editOrderItem()">
          Edit
        </button>
      </div>
    </div> -->
  </div>
</template>

<script>
import OrderItemDetail from "./OrderItemDetail.vue";
import PackagePrice from "./PackagePrice.vue";

export default {
  emits: ["delete"],
  components: { OrderItemDetail, PackagePrice },
  props: {
    data: Object,
    showAction: { type: Boolean, default: true },
  },
  methods: {
    // editOrderItem() {
    //   this.$router.push({
    //     path: `/package/detail/${encodeURIComponent(this.data.bookingid)}`,
    //     query: { item: encodeURIComponent(this.data.id) },
    //   });
    // },
  },
};
</script>
