<template>
  <div class="container-fluid">
    <div class="row mb-4">
      <div class="col-12 mb-3">
        <a @click="$router.back()" class="text-primary fw-bold text-decoration-none cursor-pointer">
          <i class="fas fa-angle-left"></i> Back
        </a>
      </div>
      <div class="col-12">
        <VmdAlert class="font-weight-light show" color="danger" :dismissible="error.show"
          v-on:update="(e) => (error.show = e)">
          <span class="text-sm">{{ error.msg }}</span>
        </VmdAlert>

        <div class="card">
          <div class="card-body p-0">
            <div class="card-title bg-primary text-white px-3 py-2 rounded-top d-flex">
              Booking Detail #{{ orderItems[0].bookingCode }}
              <OrderStatusTag :status="order.status" class="ms-lg-auto" />
            </div>
            <div class="row px-4 mb-4">
              <div class="col-lg-7">
                <span class="fw-bold">Booking Date </span>
                <div>{{ new Date(orderItems[0].bookingDate).format("dd MMM yyyy hh:mm tt") }}</div>
              </div>
              <div class="col-lg-5 mb-2 text-end">
                <span class="fw-bold">Payment Method</span>
                <div>Wire Transfer</div>
              </div>
              <hr />
              <CartItemList :items="orderItems">
                <template #header>
                  <div class="row text-center">
                    <div class="fw-bold col-lg-3"></div>
                    <div class="fw-bold col-lg-6 text-start px-3">Activity</div>
                    <div class="fw-bold col-lg-1 px-0">Pax</div>
                    <div class="fw-bold col-lg-2">Price</div>
                  </div>
                </template>
                <template #content="{ data }">
                  <CartItem :data="data" :show-action="false">
                    <template #product="{ data }">
                      <OrderItemDetail :data="data" :editable="isOrderItemEditable(data.status)"
                        @edit="onOrderItemEdit" />
                    </template>
                  </CartItem>
                </template>
              </CartItemList>

              <div class="col-lg-5 ms-auto mt-3">
                <label class="fw-bold d-block">Payment</label>
                <!-- <PaymentSummary
                  :total-price="order.totalPrice"
                  :promo-rebate="order.promoDiscount"
                  :amount-to-pay="order.totalPrice - order.promoDiscount"
                /> -->
                <PaymentSummary :total-price="orderItems[0].bookingTotalPrice" :totalDeliveryFee="0"
                  :currencySymbol="orderItems[0].currencySymbolOrder" :amount-to-pay="orderItems[0].bookingTotalPrice" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <EditOrderItem ref="editModal" :area="this.order.areaId" /> -->
  </div>
</template>

<script>
import OrderStatusTag from "./components/Order/OrderStatusTag.vue";
import CartItem from "./components/Order/CartItem.vue";
import CartItemList from "./components/Order/CartItemList.vue";
import OrderItemDetail from "./components/Order/OrderItemDetail.vue";
import PaymentSummary from "./components/Order/PaymentSummary.vue";
import { OrderItemStatus, OrderStatus } from "./components/Order/OrderStatus";
import EditOrderItem from "./components/Order/EditOrderItem.vue";
import { computed } from "vue";
import VmdAlert from "../components/VmdAlert.vue";
import DeliveryAddressPicker from "./components/Address/DeliveryAddressPicker.vue";

export default {
  // provide() {
  //   return {
  //     areaId: computed(() => this.order.areaId),
  //   };
  // },
  components: {
    OrderStatusTag,
    CartItem,
    CartItemList,
    OrderItemDetail,
    PaymentSummary,
    EditOrderItem,
    VmdAlert,
    // DeliveryAddressPicker
  },
  data() {
    return {
      order: {},
      orderItems: [],
      edit: {
        itemId: "",
      },
      error: { show: false, msg: "" },
    };
  },

  computed: {
    orderDate() {
      if (this.order.created)
        return new Date(this.order.created).format("dd MMM yyyy hh:mm tt");
      return null;
    },
    // totalProductPrice() {
    //   return this.orderItems?.reduce((acc, val) => acc += val.discountedItemPrice, 0) || 0;
    // },
  },
  methods: {
    isOrderItemEditable(status) {
      return status === "ongoing";
    },
    onOrderItemEdit(data, stopLoading) {
      this.$refs.editModal.showModal(data);
      stopLoading();
    },
  },

  async created() {
    const res = await this.getApi(
      `api/booking?bookingId=${encodeURIComponent(this.$route.params.bookingId)}&personId=${encodeURIComponent(this.$route.params.personId)}`
    );
    console.log(`api/booking?bookingId=${encodeURIComponent(this.$route.params.bookingId)}&personId=${encodeURIComponent(this.$route.params.personId)}`, res);

    if (res.flag === 1) {
      this.orderItems = res.data.bookingItem;
      console.log('this.orderItems', this.orderItems);
      this.orderItems.forEach((item) => {
        item.status = OrderItemStatus(item.status);
      });
    } else {
      this.error.show = true;
      this.error.msg = res.msg;
    }
  },
};
</script>
