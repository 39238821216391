<template>
  <div>
    <div class="d-flex align-items-center">
      <h2 class="text-primary">Delivery Address</h2>
      <button
        v-if="enableEdit"
        class="btn btn-outline-secondary m-0 ms-3"
        @click.prevent="listAddresses"
      >
        Change Address
      </button>
    </div>
    <div class="d-flex justify-content-between">
      <div class="fw-bold">{{ name }}</div>
      <!-- <div v-if="isDefault">Default</div> -->
    </div>
    <div>{{ mobile }}</div>
    <div>{{ buildingName }}</div>
    <div>{{ floorUnit }}</div>
    <div>{{ address }}</div>
  </div>

  <div
    class="modal fade"
    ref="AddressListModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div
      class="
        modal-dialog modal-lg modal-fullscreen-md-down modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Pick a delivery address</h5>
        </div>
        <div class="modal-body">
          <div
            v-for="address in dtAddress"
            :key="address.id"
            class="address-list-item my-2"
            :class="[this.selectedAddressId == address.id ? 'active' : '']"
            @click="clickAddress(address)"
          >
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <div class="fw-bold">{{ address.name }}</div>
                  <div v-if="address.isDefault">Default</div>
                </div>
                <div>+{{ address.dialCode + address.mobile }}</div>
                <div v-if="address.buildingName.length > 0">
                  {{ address.buildingName }}
                </div>
                <div>{{ address.floorUnit }}</div>
                <div>{{ address.address }}</div>
              </div>
            </div>
          </div>
          <div
            v-if="this.dtAddress == null || this.dtAddress.length === 0"
            class="text-muted"
          >
            No record found.
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <a href="#" @click.prevent="clickAddNewAddress">Add New Address</a>
          <div>
            <button
              type="button"
              class="btn btn-secondary mx-2"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="confirmDeliveryAddress"
              :disabled="this.selectedAddressId == null"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DeliveryAddressNew
    ref="DeliveryAddressNewModal"
    @list-address="listAddresses"
    :personId="personId"
  ></DeliveryAddressNew>
</template>

<script>
import { Modal } from "bootstrap";
import DeliveryAddressNew from "./DeliveryAddressNew.vue";

export default {
  components: { DeliveryAddressNew },
  props: {
    enableEdit: { type: Boolean, default: false }, //if order status != ongoing, hide change address
  },
  data() {
    return {
      name: "",
      mobile: "",
      address: "",
      buildingName: "",
      floorUnit: "",
      isDefault: false,
      addressListModal: null, //modal el
      dtAddress: null, //all addresses
      selectedAddressId: null, //selected address id
      selectedAddressObject: null, //selected address object
      personId: null,
    };
  },
  mounted() {
    this.addressListModal = new Modal(this.$refs.AddressListModal);
  },
  methods: {
    bindAddress(addressId) {
      this.postApi("api/UserAddress/GetAddress", {
        addressId: addressId,
      })
        .then((result) => {
          if (result.flag == 1) {
            if (result.data.dtAddress.length == 1) {
              //bind display
              this.name = result.data.dtAddress[0].name;
              this.mobile =
                "+" +
                result.data.dtAddress[0].dialCode +
                result.data.dtAddress[0].mobile;
              this.address = result.data.dtAddress[0].address;
              this.buildingName = result.data.dtAddress[0].buildingName;
              this.floorUnit = result.data.dtAddress[0].floorUnit;
              this.isDefault = result.data.dtAddress[0].isDefault;
              this.personId = result.data.dtAddress[0].personId;

              this.$emit("address-selected", result.data.dtAddress[0].id);
              this.$emit("area-selected", result.data.dtAddress[0].areaId);

              //bind selected address
              this.selectedAddressObject = result.data.dtAddress[0];
              this.selectedAddressId = result.data.dtAddress[0].id;
            }
          } else {
            alert(result.msg);
          }
        })
        .finally(() => {});
    },
    listAddresses() {
      this.postApi("api/UserAddress/GetAddresses", { personId: this.personId })
        .then((result) => {
          if (result.flag == 1) {
            this.dtAddress = result.data.dtAddress;
            this.addressListModal.show();
          } else {
            alert(result.msg);
          }
        })
        .finally(() => {});
    },
    clickAddress(address) {
      this.selectedAddressId = address.id;
      this.selectedAddressObject = address;
    },
    confirmDeliveryAddress() {
      if (
        this.selectedAddressId == null ||
        this.selectedAddressId.length == 0
      ) {
        return;
      }
      //bof: update order delivery address
      this.postApi("api/UserAddress/ChangeOrderAddress", {
        orderId: this.$route.params.id,
        areaId: this.selectedAddressObject.areaId,
        personAddressId: this.selectedAddressId,
      }).then((result) => {
        if (result.flag === 1) {
          this.addressListModal.hide();
          this.name = this.selectedAddressObject.name;
          this.mobile =
            "+" +
            this.selectedAddressObject.dialCode +
            this.selectedAddressObject.mobile;
          this.address = this.selectedAddressObject.address;
          this.isDefault = this.selectedAddressObject.isDefault;
          this.buildingName = this.selectedAddressObject.buildingName;
          this.floorUnit = this.selectedAddressObject.floorUnit;
        } else {
          alert(result.msg);
        }
      });
      //eof: update order delivery address
    },
    clickAddNewAddress() {
      this.addressListModal.hide();
      this.$refs.DeliveryAddressNewModal.show();
    },
  },
};
</script>


<style scoped>
.address-list-item .card {
  border: 1px solid rgb(222, 226, 230);
}
.address-list-item:hover .card,
.address-list-item.active .card {
  background-color: green;
  color: #fff;
}
</style>