<template>
  <div v-if="price">
    <span class="ori-price d-block text-danger" v-if="newPrice == null || newPrice != price">
      <span class="text-body">RM {{ price }}</span>
    </span>
    <span class="d-block">RM {{ newPrice ? newPrice : price }}</span>
  </div>
</template>

<script>
export default {
  props: ["price", "newPrice"],
};
</script>

<style scoped>
.ori-price {
  text-decoration: line-through;
}
</style>
