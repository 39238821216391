<script>
import { AgGridVue } from "@ag-grid-community/vue3";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "jquery/dist/jquery.min.js";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import paxinput from "../components/Paxinput.vue";
import priceinput from "../components/PriceInput.vue";
import $ from "jquery";
// Import Vue FilePond
import vueFilePond, { setOptions } from "vue-filepond";
import VmdAlert from "@/components/VmdAlert.vue";
// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageResize from "filepond-plugin-image-resize";

import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import "axios";
import { Modal } from "bootstrap";
import { left } from "@popperjs/core";


const ToggleComponent = {
  template: '<Toggle   v-model="value" on-label="On" off-label="Off" />'
};

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginImageTransform,
  FilePondPluginImageResize
);
setOptions({
  server: {
    url: process.env.VUE_APP_UPLOAD_URL + "api/wkrUpload/upload",
    process: (fieldName, file, metadata, load, error, progress, abort) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fieldName", file.name);
      formData.append("cmd", "1");

      const request = new XMLHttpRequest();
      request.open(
        "POST",
        process.env.VUE_APP_UPLOAD_URL + "api/wkrUpload/upload"
      );
      // Setting computable to false switches the loading indicator to infinite mode
      request.upload.onprogress = (e) => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      request.onload = function () {
        if (request.status >= 200 && request.status < 300) {
          load(request.responseText); // the load method accepts either a string (id) or an object
        } else {
          error("Error during Upload!");
        }
      };

      request.send(formData);
      //expose an abort method so the request can be cancelled
      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button
          request.abort();
          // Let FilePond know the request has been cancelled
          abort();
        },
      };
    }, // we've not implemented these endpoints yet, so leave them null!
  },
});
// Save the row order to localStorage
function saveRowOrder(rowOrder) {
  localStorage.setItem("rowOrder", JSON.stringify(rowOrder));
}

// Retrieve the saved row order from localStorage
function getSavedRowOrder() {
  const savedData = localStorage.getItem("rowOrder");
  return savedData ? JSON.parse(savedData) : null;
}

export default {
  data() {
    return {
      api: "api/activity",
      value: true,
      paginationPageSize: 20,
      rowHeight: 80,
      columnDefs: [],
      rowData: [],
      retModifiedRowData: [],
      gridApi: null,
      gridColumnApi: null,
      columnApi: null,
      myFiles: [],
      type: [],
      formData: {
        cmd: 1,
        activityid: null,
        Preview: 0,
        f_activityType: 1,
        f_activityName: { value: null },
        f_shortDescription: { value: null },
        f_visible: true,
        f_Discount: { value: 0 },
        f_adultPrice: { value: 0 },
        f_childPrice: { value: 0 },
        f_infantPrice: { value: 0 },
        photolist: [],
        photoRemove: [],
        f_content: null,
        f_seasonalAvailability: null,
      },
      Validation: {
        vldActivityname: true,
        vldAdultprice: true,
        vldChildprice: true,
        vldInfantprice: true,
        vldShortDesc: true,
      },
      SuccessAlert: false,
      dangerAlert: false,
      returnmsg: null,
      userRemove: false,
      deleteActivityModal: null,
      deleteActivityName: "",
      deleteActivityError: { show: false, msg: "" },
      isImgIdxChanged: false,
      gridOptions: {
        //rowDragManaged: true, // Enable row dragging
        suppressMovableColumns: false,
        columnDefs: [
          {
            headerName: "",
            field: "Seq",
            sortable: true,
            rowDrag: params => !params.node.group
          },
          {
            headerName: "Type",
            field: "activityType",
            sortable: true,
          },
          {
            headerName: "Name",
            field: "name",
            filter: "agTextColumnFilter",
            width: 400,
            minWidth: 200,
            cellRenderer: (params) => {
              return `<b>${params.value}</b>`;
            },
          },
          { headerName: "Price (THB)", field: "price" },
          {
            headerName: "Visible",
            field: "status",
            cellRenderer: (params) => {
              var div = document.createElement("div");
              div.className = "switchToggle";
              var input = document.createElement("input");
              input.setAttribute('id', params.data.id);
              input.type = "checkbox";
              input.checked = params.value == 1;
              input.addEventListener("change", async (e) => {

                await this.postApi("api/activity/visible", { activityId: params.data.id, isVisible: input.checked }).then((result) => {
                  if (result.flag === 1) {
                    this.SuccessAlert = true;
                    this.returnmsg = "Update Successful"
                    this.getdata();
                  } else {
                    this.dangerAlert = true;
                    this.returnmsg = result.msg;
                    input.checked = !input.checked;
                  }
                });
              });

              var label = document.createElement("label");
              label.setAttribute('for', params.data.id);
              label.innerHTML = "Toggle";
              div.appendChild(input);
              div.appendChild(label);
              return div;
            },
          },
          {
            headerName: "Edit",
            field: "id",
            cellRenderer: (params) => {
              var link = document.createElement("a"); // edit btn
              link.href = "#";
              link.className = "fa fa-pen d-block text-dark m-0 p-3";
              link.style.cssText = " border-right: 1px solid #c8c8d1;";

              link.addEventListener("click", (e) => {
                e.preventDefault();
                this.clearfield();
                this.$refs.btnAddActivity.click();
                this.formData.activityid = params.data.id;

                this.formData.cmd = 2;
                this.ActivitySelected();
              }); // onClick function

              var linkdelete = document.createElement("a"); // delete btn
              linkdelete.href = "#";
              linkdelete.className = "fa fa-trash d-block text-danger m-0 p-3";

              // linkdelete.setAttribute("data-bs-toggle", "modal");
              // linkdelete.setAttribute("data-bs-target", "#DeleteModal");
              linkdelete.addEventListener("click", (e) => {
                e.preventDefault();
                this.formData.activityid = params.data.id;
                this.deleteActivityName = params.data.name;
                document.getElementById("txtMasterPassword").value = "";
                this.deleteActivityError.show = false;
                this.deleteActivityError.msg = "";
                this.deleteActivityModal.show();
              }); // onClick function
              var div = document.createElement("div");
              div.className = "d-flex";
              div.appendChild(link);
              div.appendChild(linkdelete);
              return div;
            },
          },
        ],
        onGridReady: this.onGridReady,
        onRowDragEnd: this.onRowDragEnd, // Set the event handler for row dragging

      }
    };
  },
  components: {
    AgGridVue,
    QuillEditor,
    FilePond,
    paxinput,
    VmdAlert,
    ToggleComponent,
    priceinput
  },

  setup() {
    return {
      defaultColDef: {
        resizable: false,
      },
      modules: [ClientSideRowModelModule],
    };
  },
  methods: {
    async bindActivityType() {
      await this.getApi("api/activity/type").then((result) => {
        if (result.flag === 1) {
          this.type = result.data;
        } else {
          alert(result.msg);
        }
      });
    },
    async getdata() {
      await this.getApi(this.api).then((result) => {
        if (result.flag === 1) {
          this.rowData = result.data;
        } else {
          alert(result.msg);
        }
      });
    },
    async deleteActivity() {
      await this.postApi("api/activity/delete", {
        activityId: this.formData.activityid,
        masterPassword: document.getElementById("txtMasterPassword").value,
      }).then((result) => {
        if (result.flag === 1) {
          this.getdata();
          this.deleteActivityModal.hide();
          this.SuccessAlert = true;
          this.returnmsg = "Activity is Deleted.";
        } else {
          //alert(result.msg);
          this.deleteActivityError.show = true;
          this.deleteActivityError.msg = result.msg;
        }
      });
    },
    handleFilePondInit: function () {
      console.log("FilePond has initialized");

      // example of instance method call on pond reference
      this.$refs.pond.getFiles();
    },

    async saveArrangement() {

      console.log("data:", this.retModifiedRowData);
      console.log("jsondata:", JSON.stringify(this.retModifiedRowData));

      const jsondata = JSON.stringify(this.retModifiedRowData).toString();

      const res = await this.postApi("api/activity/saveidx", this.retModifiedRowData);
      // console.log("api/gallery/save/idx", res);
      if (res.flag === 1) {
        this.dangerAlert = false;
        this.SuccessAlert = true;
        this.returnmsg = "Save Arrangement Success";
        this.isImgIdxChanged = false;
        this.getdata();
      } else {
        this.dangerAlert = true;
        this.SuccessAlert = false;
        this.dangermsg = result.msg;
      }
    },

    async saveActivity(Preview) {
      this.formData.Preview = Preview;

      if (this.checkValidation()) {
        this.dangerAlert = true;
        this.returnmsg = "Please fill up all the required fields.";

        return;
      }
      JSON.parse(JSON.stringify(this.myFiles));
      Object.values(this.myFiles).forEach((val) => {
        this.formData.photolist.push({
          PhotoId: val.FileId || "",
          PhotoName: val.fileName,
          PhotoSize: val.fileSize,
          PhotoPath: val.filePath,
        });
      });

      await this.postApi("api/activity/save", this.formData).then((result) => {
        if (result.flag === 1) {
          if (this.formData.Preview == 9) {
            this.clearfield();
            this.formData.cmd = 2;
            this.ActivitySelected();
            window.open(
              process.env.VUE_APP_MEMBER_URL +
              "Package/Detail/" +
              encodeURIComponent(this.formData.activityid),
              "_blank"
            );
          } else {
            this.returnmsg = "Activity save success";
            this.SuccessAlert = true;
            this.$refs.btnClose.click();
            this.clearfield();
            this.getdata();
          }
        } else {
          this.dangerAlert = true;
          this.returnmsg = result.msg;
        }
      });
    },
    checkValidation() {
      var isEmpty = false;

      if (!this.formData.f_activityName.value) {
        this.Validation.vldActivityname = false;
        isEmpty = true;
      } else {
        this.Validation.vldActivityname = true;
        isEmpty = false;
      }

      if (!this.formData.f_adultPrice && this.formData.f_infantPrice < 0) {
        this.Validation.vldAdultprice = false;
        isEmpty = true;
      } else {
        this.Validation.vldAdultprice = true;
        isEmpty = false;
      }

      if (!this.formData.f_adultPrice && this.formData.f_infantPrice < 0) {
        this.Validation.vldAdultprice = false;
        isEmpty = true;
      } else {
        this.Validation.vldAdultprice = true;
        isEmpty = false;
      }

      if (!this.formData.f_childPrice && this.formData.f_infantPrice < 0) {
        this.Validation.vldChildprice = false;
        isEmpty = true;
      } else {
        this.Validation.vldChildprice = true;
        isEmpty = false;
      }

      if (!this.formData.f_infantPrice) {
        this.Validation.vldInfantprice = false;
        isEmpty = true;
      } else {
        this.Validation.vldInfantprice = true;
        isEmpty = false;
      }

      if (!this.formData.f_shortDescription.value) {
        this.Validation.vldShortDesc = false;
        isEmpty = true;
      } else {
        this.Validation.vldShortDesc = true;
        isEmpty = false;
      }

      return isEmpty;
    },

    onprocessfile(error, file) {
      // file success uploaded
      if (error == null) {
        const result = JSON.parse(file.serverId); //json return once upload
        this.myFiles[file.id] = result.data;
      } else {
        console.error(error);
      }
    },
    onremovefile(error, file) {
      if (error == null && this.userRemove == true) {
        if (this.myFiles[file.id] != null) {
          delete this.myFiles[file.id];
        } else {
          this.formData.photoRemove.push({
            PhotoId: file.getMetadata("FileID"),
          });
        }
      }
    },
    onactivatefile(file) {
      // when the file is clicked

      const path = this.extractFilePath(file);
      if (path.length > 0) window.open(this.formatImageUrl(path), "_blank");
    },
    async refresh(data) {
      await this.$refs.pond.removeFiles();

      await data.forEach(async (file) => {
        this.$refs.pond.addFile(file.FilePath, {
          type: "local",
          file: {
            id: file.fileID,
            name: file.fileName,
            size: file.size,
            path: file.filePath,
          },
          metadata: {
            FilePath: file.filePath,
            url: file.filePath,
            FileID: file.fileID,
          },
        });
      });
      setTimeout(
        () => console.log("userremove", (this.userRemove = true)),
        500
      );
    },
    extractFilePath(file) {
      let imgPath = file.getMetadata("FilePath");
      if ($.trim(imgPath).length === 0) {
        const result = JSON.parse(file.serverId);
        imgPath = result ? result.data.FilePath : "";
      }

      return imgPath;
    },
    // onPageSizeChanged() {
    //   var value = document.getElementById("page-size").value;
    //   this.paginationPageSize = Number(value);
    //   this.$forceUpdate();
    // },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.getdata();
    },
    onRowDragEnd(event) {
      const ModifiedRowData = [];
      this.gridApi.forEachNode(function (node, idx) {
        node.data.idx = idx + 1; // Adding 1 to make it 1-based
        ModifiedRowData.push(node.data);
      });

      this.retModifiedRowData = ModifiedRowData;
      console.log('Row Data:');
      console.table(this.retModifiedRowData);
      this.isImgIdxChanged = true;
      // const movedRowData = event.node.data;
      // const newPosition = event.overNode.childIndex;
      // Log the moved row data and its new position
      //console.log("Row moved:", movedRowData, "New Position:", newPosition, "A:", movedRowData["seq"]);
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },

    autoSize() {
      this.gridColumnApi.autoSizeAllColumns(false);
    },
    onEditorReady(e) {
      e.container.querySelector(".ql-blank").innerHTML = this.formData.f_content;
    },
    onEditorReady2(e) {
      e.container.querySelector(".ql-blank").innerHTML = this.formData.f_seasonalAvailability;
    },
    UpdateAlert(value) {
      this.SuccessAlert = value;
      this.dangerAlert = value;
    },

    clearfield() {
      this.userRemove = false;
      this.formData.activityid = null;
      this.formData.cmd = 1;
      this.formData.f_activityType = 1;
      this.formData.f_activityName.value = null;
      this.formData.f_shortDescription.value = null;
      this.formData.f_adultPrice = 0;
      this.formData.f_childPrice = 0;
      this.formData.f_infantPrice = 0;
      this.formData.photolist = [];
      this.formData.f_content = null;
      this.formData.f_seasonalAvailability = null;

      this.$refs.qeSeasonal.setHTML(this.formData.f_seasonalAvailability);
      this.$refs.qeContent.setHTML(this.formData.f_content);

      this.formData.photoRemove = [];
      this.myFiles = [];
    },
    async ActivitySelected() {
      await this.postApi("api/activity/Detail", {
        id: this.formData.activityid,
      }).then(async (result) => {
        if (result.flag === 1) {
          var Data = result.data[0];
          this.formData.f_activityType = Data.type;
          this.formData.f_activityName.value = Data.name;
          this.formData.f_shortDescription.value = Data.shortDesc;
          this.formData.f_adultPrice = Data.adultPrice;
          this.formData.f_childPrice = Data.childPrice;
          this.formData.f_infantPrice = Data.infantPrice;
          this.formData.f_content = Data.description;
          this.formData.f_visible = Data.visible == 1;
          this.formData.f_seasonalAvailability = Data.seasonalAvailability;

          this.$refs.qeSeasonal.setHTML(this.formData.f_seasonalAvailability);
          this.$refs.qeContent.setHTML(this.formData.f_content);
          await this.refresh(JSON.parse(Data.activityPhoto));
        } else {
          alert(result.msg);
        }
      });
    },

    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
  },

  async beforeMount() {
    this.bindActivityType();
    await this.getdata();
  },
  mounted() {
    this.deleteActivityModal = new Modal(this.$refs.DeleteModal);
  },
};
</script>

<template>
  <div class="py-4 container-fluid">
    <vmd-alert class="font-weight-light" color="success" :dismissible="SuccessAlert" v-on:update="UpdateAlert($event)">
      <span class="text-sm">{{ returnmsg }}</span>
    </vmd-alert>

    <div class="d-md-flex justify-content-between align-items-center">
      <div class="d-md-flex align-items-center">
        <!-- <div class="d-flex align-items-center ">
          <span class="d-block text-nowrap me-1">Page Size:</span>
          <select v-on:change="onPageSizeChanged()" id="page-size" class="form-select" style="width:80px">
            <option value="20" selected="">20</option>
            <option value="100">100</option>
            <option value="150">150</option>
            <option value="200">200</option>
          </select>
        </div> -->
        <div class="row mt-3  mb-3 mt-md-0 mb-md-0">
          <div class="col-12">
            <button class="btn btn-secondary mb-0 me-1" @click="sizeToFit()">
              Size to Fit
            </button>
            <button class="btn btn-secondary mb-0" @click="autoSize()">
              Auto-Size
            </button>
          </div>
        </div>
      </div>
      <div>
        <button type="button" class="btn btn-primary" :disabled="rowData.length === 0 || !isImgIdxChanged"
          @click="saveArrangement">
          Save Arrangement
        </button> &nbsp;
        <button class="btn btn-primary" ref="btnAddActivity" data-bs-toggle="modal" data-bs-target="#AddModal"
          v-on:click="clearfield">
          Add New
        </button>
      </div>
    </div>

    <div class="grid-wrapper">
      <ag-grid-vue ref="gridList" style="width: auto; height: 70vh" class="ag-theme-alpine" :gridOptions="gridOptions"
        :rowDragManaged="true" :rowHeight="rowHeight" :defaultColDef="defaultColDef" :rowData="rowData" :modules="modules"
        @grid-ready="onGridReady" @first-data-rendered="onFirstDataRendered">
      </ag-grid-vue>
      <!-- :key="paginationPageSize" :pagination="true" :paginationPageSize="paginationPageSize" -->
    </div>
    <!--Delete Activity Modal -->
    <div class="modal fade" ref="DeleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Delete Activity</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <VmdAlert class="font-weight-light show" color="danger" :dismissible="deleteActivityError.show"
              v-on:update="(e) => (deleteActivityError.show = e)">
              <span class="text-sm">{{ deleteActivityError.msg }}</span>
            </VmdAlert>
            Are you sure you want to delete this activity ({{ deleteActivityName }})? Enter master password
            to proceed.
            <input type="password" class="form-control mt-2" placeholder="Master Password" maxlength="20"
              id="txtMasterPassword" autocomplete="off" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button type="button" class="btn btn-danger" v-on:click="deleteActivity">
              Confirm Delete
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- add new activity Scrollable modal -->
    <div class="modal fade" id="AddModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header d-inline">
            <h5 class="modal-title ms-2" id="exampleModalLabel">
              {{ formData.cmd == 2 ? 'Edit Activity' : "Add New Activity" }}
            </h5>
            <vmd-alert class="font-weight-light" color="danger" :dismissible="dangerAlert"
              v-on:update="UpdateAlert($event)">
              <span class="text-sm">{{ returnmsg }}</span>
            </vmd-alert>
          </div>
          <div class="modal-body">
            <!-- start activity name -->
            <div class="row d-flex mb-2">
              <div class="col-12 col-lg-6">
                <label for="" class="form-label">Type of Activity</label>
                <select class="form-select" v-model="formData.f_activityType">
                  <option v-for="name, idx in type" :key="idx" :value="name.id">{{ name.typeName }}</option>
                </select>
              </div>

              <!--start visible row -->
              <div class="col-12 col-lg-6 mb-2 d-flex align-items-center">
                <div class="switchToggle" id="ModelToggleSwitch">
                  <input type="checkbox" id="ToggleSwitch" v-model="formData.f_visible">
                  <label for="ToggleSwitch"></label>
                </div>
                <div class="ms-3">Allow customer view this activity</div>
              </div>
              <!--end visible row -->


            </div>
            <!-- End activity name -->
            <div class="row align-items-end">
              <div class="col-12">
                <label for="Activityname" class="form-label">Activity Name</label>
                <input v-model="formData.f_activityName.value" type="text" class="form-control"
                  :class="{ 'is-invalid': !Validation.vldActivityname }" placeholder="Happy elephant" />
                <div class="invalid-feedback text-end mt-0" v-bind:class="{ 'd-block': !Validation.vldActivityname }">
                  This field is requierd
                </div>
              </div>
            </div>

            <!-- start price row -->
            <h6 class="ms-1 mt-4">Price (THB)</h6>
            <div class="row mb-2">
              <div class="col-12 col-lg-6">
                <priceinput v-model="formData.f_adultPrice" :isInvalid="Validation.vldAdultprice" label="Adult Price">
                </priceinput>
                <priceinput v-model="formData.f_childPrice" :isInvalid="Validation.vldChildprice" label="Children Price">
                </priceinput>
                <priceinput v-model="formData.f_infantPrice" :isInvalid="Validation.vldInfantprice" label="Infant Price">
                </priceinput>
              </div>
            </div>

            <!-- End price row -->

            <!-- start upload image -->
            <h6 class="ms-1 mt-4">Picture (Max. 8)</h6>
            <div>
              Min. dimensions: 600 pixels (Width) x 800 pixels (Height), max.
              size 3MB
            </div>
            <file-pond name="filepond" ref="pond" label-idle="Drop files here..." v-bind:allow-multiple="true"
              accepted-file-types="image/jpeg, image/png" v-bind:files="myFiles" imageResizeTargetWidth="600"
              imageResizeTargetHeight="800" imageResizeMode="contain" max-files="8" maxFileSize="3MB"
              v-on:init="handleFilePondInit" label-max-file-size-exceeded="That exceeds max allowed limit"
              label-max-file-size="No more than 3MB please" v-on:processfile="onprocessfile"
              v-on:removefile="onremovefile" v-on:activatefile="onactivatefile" />
            <!-- end upload image -->

            <!-- start html editor row -->
            <h6 class="ms-1 mt-4">Seasonal Availability (Optional)</h6>
            <div class="quill-container-short">
              <QuillEditor ref="qeSeasonal" theme="snow" class="mt-2" v-model:content="formData.f_seasonalAvailability"
                contentType="html" @ready="onEditorReady2($event)" />
            </div>
            <!-- End html editor row -->

            <!-- start short description-->
            <div class="col-12 mt-6">
              <h6 class="ms-1 mt-4">Short Description</h6>
              <input v-model="formData.f_shortDescription.value" type="text" class="form-control"
                :class="{ 'is-invalid': !Validation.vldShortDesc }" placeholder="Short Description" />
              <div class="invalid-feedback text-end mt-0" v-bind:class="{ 'd-block': !Validation.vldShortDesc }">
                This field is requierd
              </div>
            </div>
            <!-- end short description-->

            <!-- start html editor row -->
            <h6 class="ms-1 mt-4">Description (Optional)</h6>
            <div class="quill-container">
              <QuillEditor ref="qeContent" theme="snow" class="mt-2" v-model:content="formData.f_content"
                contentType="html" @ready="onEditorReady($event)" />
            </div>
            <!-- End html editor row -->
          </div>
          <div class="modal-footer d-flex justify-content-between">
            <div>
            </div>
            <div>
              <button type="button" class="btn btn-secondary me-2" ref="btnClose" data-bs-dismiss="modal">
                Close
              </button>
              <button type="button" class="btn btn-primary" v-on:click="saveActivity(1)">
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.fa::before {
  margin-right: 10px !important;
}

.form-control:focus {
  border-color: var(--bs-primary) !important;
}

.form-control {
  border: 1px solid var(--bs-secondary) !important;

  padding: 0.5rem 0.75rem !important;
}

.form-control.is-invalid {
  border-color: #fd5c70 !important;
}

.form-select {
  border: 1px solid var(--bs-secondary) !important;
  padding: 0.5rem 0.75rem !important;
  background-position: right 7px center !important;
}

.form-select:focus {
  border-color: var(--bs-primary) !important;
}

.switchToggle input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.switchToggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 70px;
  max-width: 70px;
  height: 30px;
  background: #d1d1d1;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switchToggle label:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.switchToggle input:checked+label,
.switchToggle input:checked+input+label {
  background: #4EA952;
}

.switchToggle input+label:before,
.switchToggle input+input+label:before {
  content: 'Off';
  position: absolute;
  top: -6px;
  left: 35px;
  width: 26px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}

.switchToggle input:checked+label:before,
.switchToggle input:checked+input+label:before {
  content: 'On';
  position: absolute;
  top: -6px;
  left: 10px;
  width: 26px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}

.switchToggle input:checked+label:after,
.switchToggle input:checked+input+label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switchToggle label:active:after {
  width: 60px;
}

#ModelToggleSwitch input+label:before,
.switchToggle input+input+label:before {
  top: 5px !important;
}

#ModelToggleSwitch input+label:before,
.switchToggle input+input+label:before {
  top: 5px !important;
}

#ModelToggleSwitch label {
  margin-bottom: 5px;
}

.quill-container {
  height: 300px;
}

.quill-container-short {
  height: 150px;
}
</style>
