import axios from "axios";
import Nprogress from 'nprogress'
axios.defaults.baseURL = process.env.VUE_APP_API_URL;


export let global = { token: null };
//  post value to  api 
export function postApi(api, body, token) {
    Nprogress.start(); //start loading animation

    const headers = token ? { Authorization: `Token ${token}` } : null;
    return axios.post(api, body, { headers }).then(response => {
        console.log(response);

        Nprogress.done();
        return response.data
    }).catch(error => {
        console.error('There was an error!', error);
    });
}
export function getApi(api, token) {
    Nprogress.start(); //start loading animation

    const headers = token ? { Authorization: `Token ${token}` } : null;
    return axios.get(api, { headers }).then(response => {
        console.log(response);

        Nprogress.done()
        return response.data
    }).catch(error => {
        console.error('There was an error!', error);
    });
}
