<template>
  <div class="container-fluid">
    <VmdAlert
      class="font-weight-light show"
      :dismissible="mainMsg.show"
      v-on:update="(e) => (mainMsg.show = e)"
    >
      <span class="text-sm">{{ mainMsg.msg }}</span>
    </VmdAlert>
    
        <div class="row mt-4 mb-2">
          <div class="col-12">
            <button class="btn btn-secondary mb-0 me-1" @click="sizeToFit()">
              Size to Fit
            </button>
            <button class="btn btn-secondary mb-0" @click="autoSize()">
              Auto-Size
            </button>
          </div>
        </div>
    <ag-grid-vue
      style="width: auto; height: 70vh"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowHeight="rowHeight"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :modules="modules"
      :key="paginationPageSize"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>
  </div>
  <!--Delete product Modal -->
  <div
    class="modal fade"
    id="SuspendModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Suspend Customer</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          Are you sure you want to suspend this user?
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-danger"
            v-on:click="SuspendUser"
            data-bs-dismiss="modal"
          >
            Suspend
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    tabindex="-1"
    aria-labelledby="DeleteModalTitle"
    aria-hidden="true"
    ref="DeleteModal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="DeleteModalTitle">Delete Customer</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <VmdAlert
            class="font-weight-light show"
            color="danger"
            :dismissible="deleteCustomerError.show"
            v-on:update="(e) => (deleteCustomerError.show = e)"
          >
            <span class="text-sm">{{ deleteCustomerError.msg }}</span>
          </VmdAlert>
          Are you sure you want to delete this customer (<span
            id="DeleteCustomerCode"
            >{{ deleteCustomerCode }}</span
          >)? Enter master password to proceed.
          <input
            type="password"
            class="form-control mt-2"
            placeholder="Master Password"
            maxlength="20"
            id="txtMasterPassword"
            autocomplete="off"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-danger" @click="confirmDelete">
            Confirm Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

.ag-row .ag-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ag-header-cell-label {
  justify-content: center;
}
.fa::before {
  margin-right: 10px !important;
}
.form-control:focus {
  border-color: var(--bs-primary) !important;
}

.form-control {
  border: 1px solid var(--bs-secondary) !important;
  -webkit-appearance: none;
  padding: 0.5rem 0.75rem !important;
}
.form-control.is-invalid {
  border-color: #fd5c70 !important;
}
.form-select {
  border: 1px solid var(--bs-secondary) !important;
  padding: 0.5rem 0.75rem !important;
  background-position: right 7px center !important;
}
.form-select:focus {
  border-color: var(--bs-primary) !important;
}
.lblprimary {
  display: inline-block;
  font-weight: 700;
  line-height: 1.667;
  color: white;
  text-align: center;
  vertical-align: middle;
  padding: 0.625rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 8rem;
  background: rgb(78, 167, 195);
  background: linear-gradient(
    13deg,
    rgba(78, 167, 195, 1) 35%,
    rgba(0, 255, 188, 1) 100%
  );
}
.lblsuspend {
  display: inline-block;
  font-weight: 700;
  line-height: 1.667;
  color: white;
  text-align: center;
  vertical-align: middle;
  padding: 0.625rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 8rem;
  background: rgb(163, 180, 186);
  background: linear-gradient(
    13deg,
    rgba(163, 180, 186, 1) 41%,
    rgba(102, 103, 103, 1) 83%
  );
}
</style>

<script>
import { AgGridVue } from "@ag-grid-community/vue3";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import VmdAlert from "@/components/VmdAlert.vue";
import { Modal } from "bootstrap";

export default {
  setup() {
    return {
      defaultColDef: {
        resizable: true,
      },
      modules: [ClientSideRowModelModule],
    };
  },
  data() {
    return {
      paginationPageSize: 20,
      rowHeight: 80,
      columnDefs: [],
      rowData: [],
      gridApi: null,
      columnApi: null,
      IdSelected: null,
      deleteCustomerModal: null,
      deleteCustomerCode: "",
      deleteCustomerError: { show: false, msg: "" },
      mainMsg: { show: false, msg: "" },
    };
  },
  components: {
    AgGridVue,
    VmdAlert,
  },
  methods: {
        sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    autoSize() {
      this.ColumnApi.autoSizeAllColumns(false);
    },
    async getdata() {
      await this.getApi("api/user").then((result) => {
        if (result.flag === 1) {
          console.log(result.data);
          this.rowData = result.data;
        }
      });
    },
    onPageSizeChanged() {
      var value = document.getElementById("page-size").value;
      this.paginationPageSize = Number(value);
      console.log(this.paginationPageSize);
      this.$forceUpdate();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.ColumnApi = params.columnApi;
      this.sizeToFit();
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    async confirmDelete() {
      //alert(document.getElementById('txtMasterPassword').value);
      await this.postApi("api/user/delete", {
        customerId: this.IdSelected,
        masterPassword: document.getElementById("txtMasterPassword").value,
      }).then((result) => {
        if (result.flag === 1) {
          this.IdSelected = null;
          this.getdata();
          this.deleteCustomerModal.hide();
          this.mainMsg.show = true;
          this.mainMsg.msg = "Customer is Deleted.";
        } else {
          this.deleteCustomerError.show = true;
          this.deleteCustomerError.msg = result.msg;
        }
      });
    },

    async SuspendUser() {
      await this.postApi("api/user/suspend", {
        id: this.IdSelected,
      }).then((result) => {
        if (result.flag === 1) {
          this.IdSelected = null;
          this.getdata();
        } else {
          alert(result.msg);
        }
      });
    },
  },
  async beforeMount() {
    await this.getdata();
    this.columnDefs = [
      {
        headerName: "#",
        field: "row",
        sortable: true,
        cellRenderer: (params) => {
          //console.log(params);
          return `<b>${params.value}</b>`;
        },
        maxWidth: 70,
      },
      {
        headerName: "Date & Time",
        field: "created",
      },
      {
        headerName: "Customer#",
        field: "userCode",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        headerName: "Name",
        field: "name",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        headerName: "Email",
        field: "email",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        headerName: "Phone",
        field: "mobile",
        // filter: "agDateColumnFilter",
        // filterParams: equalsFilterParams,sortable: true
        filter: "agTextColumnFilter",
        floatingFilter: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        headerName: "Status",
        field: "status",
        sortable: true,
        filter: "agSetColumnFilter",
        filterParams: {
          applyMiniFilterWhileTyping: true,
        },
        cellRenderer: (params) => {
          if (params.value === 1) {
            return `<div class="lblprimary">Active</div>`;
          } else {
            return `<div class="lblsuspend">Suspend</div>`;
          }
        },
      },
      {
        headerName: "Action",
        field: "id",
        cellRenderer: (params) => {
          var link = document.createElement("a"); // edit btn
          link.href = "#";
          link.className = "fa fa-pen d-block text-dark m-0 p-3";

          link.addEventListener("click", (e) => {
            e.preventDefault();
            window.location.href = `customer/${encodeURIComponent(
              params.data.id
            )}`;
          }); // onClick function

          var linksuspend = document.createElement("a"); // delete btn
          linksuspend.href = "#";
          linksuspend.className = "fa fa-lock d-block text-danger m-0 p-3";

          linksuspend.setAttribute("data-bs-toggle", "modal");
          linksuspend.setAttribute("data-bs-target", "#SuspendModal");
          linksuspend.addEventListener("click", (e) => {
            e.preventDefault();
            console.log((this.IdSelected = params.data.id));
          }); // onClick function
          var div = document.createElement("div");
          div.className = "d-flex";
          div.appendChild(link);

          if (params.data.status === 1) {
            link.style.cssText = " border-right: 1px solid #c8c8d1;";
            div.appendChild(linksuspend);
          }

          let linkdelete = document.createElement("a"); // delete btn
          linkdelete.href = "#";
          linkdelete.className = "fa fa-trash d-block text-danger m-0 p-3";
          linkdelete.style.cssText = "border-left: 1px solid #c8c8d1;";
          // linkdelete.setAttribute("data-bs-toggle", "modal");
          // linkdelete.setAttribute("data-bs-target", "#DeleteModal");
          linkdelete.addEventListener("click", (e) => {
            e.preventDefault();

            this.deleteCustomerCode = params.data.userCode;
            this.IdSelected = params.data.id;

            //reset delete modal form
            document.getElementById("txtMasterPassword").value = "";
            this.deleteCustomerError.show = false;
            this.deleteCustomerError.msg = "";
            this.deleteCustomerModal.show();
          });

          div.appendChild(linkdelete);

          return div;
        },
      },
    ];
  },
  mounted() {
    this.deleteCustomerModal = new Modal(this.$refs.DeleteModal);
  },
};
</script>
