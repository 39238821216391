<template>
  <button type="button" :class="btnClass" :disabled="btnDisabled" @click="onClick">
    <span
      v-show="isLoading"
      class="spinner-border spinner-border-sm me-1"
      :class="spinnerClass"
      role="status"
      aria-hidden="true"
    ></span>
    <span class="visually-hidden">Loading...</span>
    <span> <slot></slot> </span>
  </button>
</template>

<script>
export default {
  emits: ["click"],
  props: {
    class: { type: String, default: "btn btn-primary" },
    disabled: Boolean,
    spinnerClass: {
      type: String,
      default: "text-primary",
    },
  },
  data() {
    return { isLoading: false };
  },
  computed: {
    btnClass() {
      return this.class + (this.isLoading ? " is-loading" : "");
    },
    btnDisabled() {
      return this.isLoading || this.disabled;
    },
  },
  methods: {
    onClick() {
      this.isLoading = true;
      this.$emit("click", this.stopLoading);
    },
    stopLoading() {
      this.isLoading = false;
    },
  },
};
</script>
