<style>
.btn-outline-secondary:hover {
  color: white !important;
  background-color: #7b809a !important;
  border-color: #7b809a !important;
}

.accountstyle .fa-pen::before {
  margin-right: 0px !important;
}

.btn-cancel:hover {
  color: black !important;
}

.nav-active {

  background-color: var(--bs-primary) !important;
  color: white !important;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%) !important;
}

.lblprimary {
  display: inline-block;
  font-weight: 700;
  line-height: 1.667;
  color: white;
  text-align: center;
  vertical-align: middle;
  padding: 0.625rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 8rem;
  background: rgb(78, 167, 195);
  background: linear-gradient(13deg, rgba(78, 167, 195, 1) 35%, rgba(0, 255, 188, 1) 100%);
}
</style>
<template>
  <div class="container mb-1 ms-1">
    <a @click="$router.back()" class="text-primary fw-bold text-decoration-none cursor-pointer">
      <i class="fas fa-angle-left"></i> Back
    </a>
  </div>
  <div class="container mb-1 ms-1">
    <vmd-alert class="font-weight-light" color="danger" :dismissible="dangerAlert" v-on:update="UpdateAlert($event)">
      <span class="text-sm">{{ dangermsg }}</span>
    </vmd-alert>
    <vmd-alert class="font-weight-light" color="success" :dismissible="SuccessAlert" v-on:update="UpdateAlert($event)">{{
      Successmsg }}</vmd-alert>

    <div class="card rounded shadow mt-3">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-lg-4 mb-2">
            <label><b>Code</b><br>
              {{ formData.code.value }} </label>
          </div>
          <div class="col-12 col-lg-4 mb-2">
            <label><b>Status</b><br>
              {{ formData.statusText.value }} </label>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-4 mb-2">
            <label><b>Full Name</b><br>{{ formData.name.value }} </label>
          </div>
          <div class="col-12 col-lg-4 mb-2">
            <label><b>Email Address</b><br>{{ formData.email.value }} </label>
          </div>
          <div class="col-12 col-lg-4 mb-2">
            <label><b>Phone Number<br></b> {{ formData.mobile.value }} </label>
          </div>
          <div class="col-12 col-lg-4 mb-2">
            <label><b>Amount (THB)</b><br>{{ formData.amount.value }} </label>
          </div>
          <div class="col-12 col-lg-4 mb-2">
            <label><b>Amount ({{ formData.symbol.value }})</b><br>{{ formData.amount2.value }} </label>
          </div>
          <div class="col-12 h-100 mb-2">
            <a :href="uploadImage" target="_blank">
              <img :src="uploadImage" /></a>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12 col-lg-4 mb-2">
            <label><b>Final Amount (THB)</b></label>

            <input v-model="this.formData.finalAmount.value" type="number" class="form-control" id="finalAmount"
              :disabled="this.status != 1" :class="{ 'is-invalid': !Validation.finalAmount }"
              placeholder="Final Amount (THB)" @input="validateCurrentAmount('finalAmount')" />
            <div class="invalid-feedback text-end mt-0" v-bind:class="{ 'd-block': !Validation.finalAmount }">
              Invalid Amount
            </div>
          </div>
          <div class="col-12 col-lg-4 mb-2">
            <label><b>Final Amount ({{ formData.symbol.value }})</b></label>

            <input v-model="this.formData.finalAmount2.value" type="number" class="form-control" id="finalAmount2"
              :class="{ 'is-invalid': !Validation.finalAmount2 }" placeholder="Final Amount (THB)"
              :disabled="this.status != 1" @input="validateCurrentAmount('finalAmount2')" />
            <div class="invalid-feedback text-end mt-0" v-bind:class="{ 'd-block': !Validation.finalAmount2 }">
              Invalid Amount
            </div>
          </div>
          <div class="col-12 mb-2">
            <label><b>Remark (Optional)</b></label>
            <input v-model="this.formData.remark.value" type="text" class="form-control" placeholder="Remark" :trim="true"
              :disabled="this.status != 1" />
          </div>
        </div>

        <div id="divButton" class="modal-footer" v-show="this.status == 1">
          <button type="button" class="btn btn-danger" v-on:click="RejectRequest">
            Reject
          </button>
          <button type="button" class="btn btn-success" @click="onSubmit">
            Approve
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextInput from "../components/TextInput.vue";
import MobileInput from "../components/MobileInput.vue";
import PasswordInput from "../components/PasswordInput.vue";
import VmdAlert from "@/components/VmdAlert.vue";
export default {
  data() {
    return {
      status: 1,
      formData: {
        code: { value: "" },
        name: { value: "", isValid: true },
        email: { value: "", isValid: true },
        mobile: { value: "", dialCode: "60", isValid: true },
        amount: { value: "", isValid: true },
        amount2: { value: "", isValid: true },
        symbol: { value: "" },
        filePath: { value: "" },
        finalAmount: { value: 0, isValid: true },
        finalAmount2: { value: 0, isValid: true },
        remark: { value: "" },
        statusText: { value: "" }
      },
      Validation: {
        finalAmount: true,
        finalAmount2: true,
      },
      isEdit: true,
      SuccessAlert: false,
      dangerAlert: false,
      Successmsg: null,
      dangermsg: null
    }
  },
  props: {
    orderId: {
      type: String,
      default: null,
    },
  },
  computed: {
    uploadImage() {
      return process.env.VUE_APP_API_URL + this.formData.filePath;
    }
  },
  components: {
    TextInput,
    PasswordInput,
    MobileInput,
    VmdAlert,
  },
  methods: {
    UpdateAlert(value) {
      this.SuccessAlert = value;
      this.dangerAlert = value;
    },
    async RejectRequest() {
      await this.postApi("api/payment/update", {
        cmd: 2,
        id: decodeURIComponent(this.$route.params.id),
        finalamount: this.formData.finalAmount.value,
        finalamount2: this.formData.finalAmount2.value,
        remark: this.formData.remark.value,
      }).then((result) => {
        if (result.flag === 1) {
          if (result.data == 200) {
            this.SuccessAlert = true;
            this.dangerAlert = false;
            this.Successmsg = "Payment Rejected."
            this.getdata();
          } else {
            this.SuccessAlert = false;
            this.dangerAlert = true;
            this.dangermsg = "Invalid Payment."
          }
        } else {
          //alert(result.msg);
          this.deleteActivityError.show = true;
          this.deleteActivityError.msg = result.msg;
        }
      });
    },
    async getdata() {
      await this.postApi("api/payment/detail", {
        id: decodeURIComponent(this.$route.params.id),
      }).then(async (result) => {
        if (result.flag === 1) {
          this.formData.name.value = result.data[0].name;
          this.formData.email.value = result.data[0].email;
          this.formData.mobile.value = result.data[0].mobile;
          this.formData.code.value = result.data[0].code;
          this.formData.amount.value = result.data[0].totalPrice;
          this.formData.amount2.value = result.data[0].totalPrice2;
          this.formData.symbol.value = result.data[0].symbol;
          this.formData.filePath = result.data[0].filePath;
          this.formData.finalAmount.value = result.data[0].finalAmount;
          this.formData.finalAmount2.value = result.data[0].finalAmount2;
          this.status = result.data[0].status;
          this.formData.statusText.value = result.data[0].statusText;
          this.formData.remark.value = result.data[0].remark;
          this.dangerAlert = false;
        }
        else {
          this.dangerAlert = true;
          this.dangermsg = result.msg
        }

      });
    },
    onSubmit() {
      this.postApi("api/payment/update", {
        cmd: 1,
        id: decodeURIComponent(this.$route.params.id),
        finalamount: this.formData.finalAmount.value,
        finalamount2: this.formData.finalAmount2.value,
        remark: this.formData.remark.value,
      }).then((result) => {
        if (result.flag === 1) {
          if (result.data == 200) {
            this.SuccessAlert = true;
            this.dangerAlert = false;
            this.Successmsg = "Payment Approved."
            this.getdata();
          } else {
            this.SuccessAlert = false;
            this.dangerAlert = true;
            this.dangermsg = "Invalid Payment."
          }
        } else {
          this.SuccessAlert = false;
          this.dangerAlert = true;
          this.dangermsg = result.msg

        }
      });
    },
    validateAmount() {
      const amount = this.formData.amount;
      const length = amount.value.length;
      amount.isValid = length >= 2 && length <= 100;
    },

    validateCurrentAmount(inputName) {
      const amount = this.formData[inputName].value;
      if (isNaN(amount) || amount <= 0) {
        this.Validation[inputName] = false;
      } else {
        this.Validation[inputName] = true;
      }
    },
  },
  beforeMount() {
    this.getdata();
  },
};
</script>
