<template>
  <div class="container">
    <div class="card p-3 w-100 shadow rounded">
      <div class="nav-wrapper position-relative end-0 " style="text-align: -webkit-right">
        <ul class="nav nav-pills w-lg-50 w-sm-100 nav-fill p-1" role="tablist">
          <li class="nav-item mx-2" @click="active = 'activitytype'">
            <a
              class="nav-link mb-0 px-0 py-1"
              :class="{ 'nav-active': active === 'activitytype' }"
              data-bs-toggle="tab"
              href="#"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
            >
              Activity Type
            </a>
          </li>
          <li class="nav-item mx-2" @click="active = 'currency'">
            <a
              class="nav-link mb-0 px-0 py-1"
              :class="{ 'nav-active': active === 'currency' }"
              data-bs-toggle="tab"
              href="#"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
            >
              Currency
            </a>
          </li>
          <li class="nav-item mx-2" @click="active = 'banksetting'">
            <a
              class="nav-link mb-0 px-0 py-1"
              :class="{ 'nav-active': active === 'banksetting' }"
              data-bs-toggle="tab"
              href="#"
              role="tab"
              aria-selected="false"
            >
              Bank Setting
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- <DayOffVue v-if="active === 'dayoff'"></DayOffVue> -->
  <Currency v-if="active === 'currency'"></Currency>
  <ActivityType v-if="active === 'activitytype'"></ActivityType>
  <BankSetting v-if="active === 'banksetting'"></BankSetting>
  
</template>

<style>
.nav-active {
  background-color: var(--bs-primary) !important;
  color: white !important;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%) !important;
}
</style>
<script>
import Currency from "./Currency.vue";
import BankSetting from "./BankSetting.vue";
import ActivityType from "./ActivityType.vue";

export default {
  data() {
    return { active: "activitytype" };
  },
  components: {
    Currency,
    ActivityType,
    BankSetting,
},
};
</script>
