<template>
  <div class="container mt-4">
    <div class="mt-2 d-flex justify-content-between align-items-center">
      <div class="h5 text-secondary">Currency</div>
      <button
        class="btn btn-primary"
        ref="btnAddProduct"
        data-bs-toggle="modal"
        data-bs-target="#AddModal"
        v-on:click="resetForm"
      >
        add new
      </button>
    </div>
    <vmd-alert
      class="font-weight-light"
      color="danger"
      :dismissible="dangerAlert"
      v-on:update="UpdateAlert($event)"
    >
      <span class="text-sm">{{ dangermsg }}</span>
    </vmd-alert>
    <vmd-alert
      class="font-weight-light"
      color="success"
      :dismissible="SuccessAlert"
      v-on:update="UpdateAlert($event)"
      >{{ Successmsg }}</vmd-alert
    >
    <ag-grid-vue
      style="width: auto; height: 60vh"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowHeight="rowHeight"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :modules="modules"
      :key="paginationPageSize"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>
  </div>

     <!--Delete product Modal -->
    <div
      class="modal fade"
      id="DeleteModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Delete Currency</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">Are you sure you want to delete this Currency?</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button
              type="button"
              class="btn btn-danger"
              v-on:click="deleteMethod"
              data-bs-dismiss="modal"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>

  <div
    class="modal fade"
    id="AddModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header d-inline">
          <h5 class="modal-title ms-2" id="exampleModalLabel">
            {{ this.cmd == 1 ? "Add New Currency" : "Update Currency" }}
          </h5>
          <vmd-alert
            class="font-weight-light"
            color="danger"
            :dismissible="modaldangerAlert"
            v-on:update="UpdateAlert($event)"
          >
            <span class="text-sm">{{ Modaldangermsg }}</span>
          </vmd-alert>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Currency</label>
               <TextInput :isDisable="this.cmd == 1 ? false : true"
              placeholder="Currency"
              :trim="true"
              :is-invalid="!formData.symbol.isValid"
              @blur="validateName()"
              v-model="formData.symbol.value"
              err-msg="Invalid Currency."
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">Rate %</label>
                <TextInput
              placeholder="%"
              :trim="true"
              :is-invalid="!formData.rate.isValid"
              @blur="validateAbbr()"
              v-model="formData.rate.value"
              err-msg="Invalid Rate."
            />
          </div>
          
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary me-2"
            ref="btnClose"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary" @click="onSubmit">
            {{ this.cmd == 1 ? "Add" : "Save" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}
.fa::before {
  margin-right: 0px !important;
}
</style>
<script>
import { AgGridVue } from "@ag-grid-community/vue3";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import VmdAlert from "@/components/VmdAlert.vue";
import TextInput from "../components/TextInput.vue";
export default {
  setup() {
    return {
      defaultColDef: {
        resizable: true,
      },
      modules: [ClientSideRowModelModule],
    };
  },
  data() {
    return {
      api: "api/Currency",
      cmd: 1,
      IdSelected:null,
      paginationPageSize: 20,
      rowHeight: 60,
      columnDefs: [],
      rowData: [],
      gridApi: null,
      columnApi: null,
      dangerAlert: false,
      dangermsg: null,
      SuccessAlert: false,
      Successmsg: null,
      modaldangerAlert: false,
      Modaldangermsg: null,
      formData: {
        symbol: { value: "", isValid: true },
        rate: { value:"", isValid: true },
      },
    };
  },
  components: {
    AgGridVue,
    VmdAlert,
        TextInput,
  },
  methods: {
    async getdata() {
      await this.getApi(this.api).then((result) => {
        if (result.flag === 1) {
          // console.log(result.data);
          this.rowData = result.data;
        } else {
          this.dangerAlert = true;
          this.dangermsg = result.msg;
        }
      });
    },
    onPageSizeChanged() {
      var value = document.getElementById("page-size").value;
      this.paginationPageSize = Number(value);
      console.log(this.paginationPageSize);
      this.$forceUpdate();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.ColumnApi = params.columnApi;
      this.sizeToFit();
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    UpdateAlert(value) {
      this.SuccessAlert = value;
      this.dangerAlert = value;
      this.modaldangerAlert = value;
    },
    resetForm() {
      this.cmd = 1;
      this.selectedId=null;
            this.formData = {
        symbol: { value: "", isValid: true },
        rate: { value: "", isValid: true },

      };
    },
    validateName() {
      const symbol = this.formData.symbol;
      const length = symbol.value.length;
      symbol.isValid = length >= 2 && length <= 100;
    },
        validateAbbr() {
      const rate = this.formData.rate;
      const ratelength = rate.value.length;
      rate.isValid = ratelength >= 1 && ratelength <= 100;
    },    onSubmit() {
      // check empty field
      
      Object.keys(this.formData).forEach((k) => {
        if (this.formData[k].value.length === 0) this.formData[k].isValid = false;
      });

      const isFormValid = Object.keys(this.formData).every(
        (k) => this.formData[k].isValid
      );

      if (!isFormValid) {
   
        return;
      }

      this.postApi("api/Currency/insert", {
        cmd:this.cmd,
        selectedId:this.IdSelected,
        symbol: this.formData.symbol.value,
        rate: this.formData.rate.value,
      }).then((result) => {
        if (result.flag === 1) {
          this.SuccessAlert = true;
          this.dangerAlert = false;
          this.Successmsg=result.msg;
          this.modaldangerAlert = false ;
          this.$refs.btnClose.click();

          this.resetForm();
          this.getdata();
        } else {
          this.SuccessAlert = false;
          this.modaldangerAlert = true ;
          this.Modaldangermsg=result.msg;
        }
      });
    },

    async deleteMethod(){
            await this.postApi("api/Currency/delete", {
        id: this.IdSelected,
      }).then((result) => {
        if (result.flag === 1) {
          this.IdSelected=null;
          this.getdata();
        }else{
              this.SuccessAlert = false;
            this.dangerAlert=true;
            this.dangermsg=result.msg
        }
      });
    }
  },

  async beforeMount() {
    await this.getdata();
    this.columnDefs = [
      {
        headerName: "#",
        field: "row",
        sortable: true,
        cellRenderer: (params) => {
          //console.log(params);
          return `<b>${params.value}</b>`;
        },
        maxWidth: 70,
      },

      {
        flex: 1,
        sortable:true,
        headerName: "Currency",
        field: "symbol",
              floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          //console.log(params)
          return `<b>${params.value}</b>`;
        },
        //              floatingFilter: true,
        // floatingFilterComponentParams: {
        //   suppressFilterButton: true,
        // },
      },
      {
        flex: 1,
        headerName: "Rate %",
        field: "rate",
        filter: "agTextColumnFilter",
        cellRenderer: (params) => {
          //console.log(params)
          return `<b>${params.value}</b>`;
        },
        //              floatingFilter: true,
        // floatingFilterComponentParams: {
        //   suppressFilterButton: true,
        // },
      },
      {
        flex: 1,
        headerName: "Modified",
        field: "modified",
        cellRenderer: (params) => {
          return `<b>${params.value}</b>`;

        },
        //              floatingFilter: true,
        // floatingFilterComponentParams: {
        //   suppressFilterButton: true,
        // },
      },
      {
        maxWidth: 150,
        headerName: "Edit",
        field: "id",
        cellRenderer: (params) => {
          var link = document.createElement("a"); // edit btn
          link.href = "#";
          link.className = "fa fa-pen d-block text-dark m-0 p-3";
          link.style.cssText = " border-right: 1px solid #c8c8d1;";
          link.addEventListener("click", (e) => {
            e.preventDefault();
            this.resetForm();
            this.$refs.btnAddProduct.click();
            this.IdSelected = params.data.id;
              this.formData.symbol.value=params.data.symbol
              this.formData.rate.value=params.data.rate
              this.cmd=2
          }); // onClick function

          var linkdelete = document.createElement("a"); // delete btn
          linkdelete.href = "#";
          linkdelete.className = "fa fa-trash d-block text-danger p-3";

          linkdelete.setAttribute("data-bs-toggle", "modal");
          linkdelete.setAttribute("data-bs-target", "#DeleteModal");
          linkdelete.addEventListener("click", (e) => {
            e.preventDefault();
            this.IdSelected = params.data.id;
          }); // onClick function
          var div = document.createElement("div");
          div.className = "d-flex";
          div.appendChild(link);
          div.appendChild(linkdelete);
          return div;
        },
      },
    ];
  },
};
</script>
