<template>
  <div ref="modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ isEdit ? "Edit" : "New" }} Bank</h5>
        </div>
        <div class="modal-body">
          <VmdAlert
            class="font-weight-light show"
            color="danger"
            :dismissible="error.show"
            v-on:update="(e) => (error.show = e)"
          >
            <span class="text-sm" v-html="error.msg"></span>
          </VmdAlert>
          <div class="row">
            <div class="col-12 mb-2">
              <label class="m-0 fw-bold"
                >Bank Name <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                :class="[formError.bankName.length > 0 && 'is-invalid']"
                placeholder="Bank Name"
                maxlength="50"
                v-model.trim="form.bankName"
              />
              <div class="invalid-feedback">{{ formError.bankName }}</div>
            </div>
            <div class="col-12 mb-2">
              <label class="m-0 fw-bold">Account Name <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control"
                :class="[formError.accountName.length > 0 && 'is-invalid']"
                placeholder="Account Name"
                v-model="form.accountName"
              />
              <div class="invalid-feedback">{{ formError.accountName }}</div>
            </div>
            <div class="col-12 mb-2">
              <label class="m-0 fw-bold">Account Number <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control"
                :class="[formError.accountNumber.length > 0 && 'is-invalid']"
                placeholder="Account Number"
                v-model="form.accountNumber"
              />
              <div class="invalid-feedback">{{ formError.accountNumber }}</div>
            </div>
            <div class="col-md-6 mb-2">
              <label class="m-0 fw-bold">Currency Symbol <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control"
                :class="[formError.currencySymbol.length > 0 && 'is-invalid']"
                placeholder="Currency Symbol"
                v-model="form.currencySymbol"
              />
              <div class="invalid-feedback">{{ formError.currencySymbol }}</div>
            </div>
            <div class="col-md-6 mb-2">
              <label class="m-0 fw-bold">Bank Country <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control"
                :class="[formError.bankCountry.length > 0 && 'is-invalid']"
                placeholder="Bank Country"
                v-model="form.bankCountry"
              />
              <div class="invalid-feedback">{{ formError.bankCountry }}</div>
            </div>
            <div class="col-12 mb-2">
              <label class="m-0 fw-bold">Bank Address <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control"
                :class="[formError.bankAddress.length > 0 && 'is-invalid']"
                placeholder="Bank Address"
                v-model="form.bankAddress"
              />
              <div class="invalid-feedback">{{ formError.bankAddress }}</div>
            </div>
            <div class="col-md-6 mb-2">
              <label class="m-0 fw-bold">Swift Code</label>
              <input
                type="text"
                class="form-control"
                :class="[formError.swiftCode.length > 0 && 'is-invalid']"
                placeholder="Swift Code"
                v-model="form.swiftCode"
              />
              <div class="invalid-feedback">{{ formError.swiftCode }}</div>
            </div>
            <div class="col-md-6 mb-2">
              <label class="m-0 fw-bold">IBAN</label>
              <input
                type="text"
                class="form-control"
                :class="[formError.iban.length > 0 && 'is-invalid']"
                placeholder="IBAN"
                v-model="form.iban"
              />
              <div class="invalid-feedback">{{ formError.iban }}</div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            ref="btnClose"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <SubmitButton
            class="btn btn-primary"
            spinner-class="text-dark"
            @click="onSave"
          >
            Save
          </SubmitButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import VmdAlert from "../../components/VmdAlert.vue";
import SubmitButton from "./Order/SubmitButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  emits: ["complete"],
  components: { VmdAlert, SubmitButton },
  data() {
    return {
      modal: null,
      isEdit: false,
      bankId: null,
      form: {
        bankName: "",
        accountName: "",
        accountNumber: "", 
        swiftCode: "",
        bankCountry: "",
        bankAddress: "",
        iban: "", 
        currencySymbol: "",
      },
      formError: {
        bankName: "",
        accountName: "",
        accountNumber: "", 
        swiftCode: "",
        bankCountry: "",
        bankAddress: "",
        iban: "", 
        currencySymbol: "",
      },
      error: { show: false, msg: "" },
    };
  },
  methods: {
    setForm(form = {}) {
      this.form.bankName = form.bankName || "";
      this.form.accountName = form.accountName || "";
      this.form.accountNumber = form.accountNumber || "";
      this.form.swiftCode = form.swiftCode || "";
      this.form.bankCountry = form.bankCountry || "";
      this.form.bankAddress = form.bankAddress || "";
      this.form.iban = form.iban || "";
      this.form.currencySymbol = form.currencySymbol || "";
    },

    showModal(data = {}) {
      this.error.show = false;
      this.isEdit = Object.keys(data).length > 0;
      if (!this.isEdit) {
        this.bankId = null;
        this.setForm();
      } else {
        this.bankId = data.id;
        this.setForm(data);
      }

      this.modal.show();
    },
    hideModal() {
      this.error.show = false;
      this.modal.hide();
    },
    showModalError(msg) {
      this.error.show = true;
      this.error.msg = msg;
    },

    onSave(stopLoading) {
      if (!this.validate()) {
        stopLoading();
        return;
      }
      this.$emit("complete", { ...this.form, id: this.bankId }, stopLoading);
    },

    validate() {
      this.formError.bankName =
        this.form.bankName.length === 0 ? "This field is required." : "";

        this.formError.accountName =
        this.form.accountName.length === 0 ? "This field is required." : "";

        this.formError.accountNumber =
        this.form.accountNumber.length === 0 ? "This field is required." : "";

        this.formError.bankCountry =
        this.form.bankCountry.length === 0 ? "This field is required." : "";
        
        this.formError.bankAddress =
        this.form.bankAddress.length === 0 ? "This field is required." : "";
        
        this.formError.currencySymbol =
        this.form.currencySymbol.length === 0 ? "This field is required." : "";

      // true if all fields are valid
      return Object.keys(this.formError).every(
        (k) => this.formError[k].length === 0
      );
    },
  },

  mounted() {
    this.modal = new Modal(this.$refs.modal);
  },
};
</script>
