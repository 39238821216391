<template>
  <div
    class="w-auto h-auto collapse navbar-collapse h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="/dashboard"
          navText="Dashboard">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="/activity"
          navText="Activity"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">camera</i>
          </template>
        </sidenav-collapse>
      </li>
      
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="/booking"
          navText="Booking"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">event</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="/customer"
          navText="Customer"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">person</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="/payment"
          navText="Payment"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">paid</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="/banner"
          navText="Banner"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">view_carousel</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="/gallery"
          navText="Gallery"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">collections</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="/setting"
          navText="Settings"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">settings</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="level == 9" :key="level">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="/createdAdmin"
          navText="Admin"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">manage_accounts</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="/testimonial"
          navText="Testimonial"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">camera</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" @click.prevent="signOut">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="/"
          navText="Sign Out"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">login</i>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
    <div class="sidenav-footer position-absolute w-100 bottom-0"></div>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      level: 0,
    };
  },
  components: {
    SidenavCollapse,
  },
  beforeMount() {
    this.level = this.$cookie.getCookie("LEVEL");
  },
  methods: {
    signOut() {
      this.$cookie.removeCookie("ADMIN_TOKEN");
      this.$router.replace({ path: "/" });
    },
  },
};
</script>
