<template>
  <v-calendar
    ref="calendar"
    trim-weeks
    class="col"
    :masks="{ weekdays: 'WWW' }"
    :available-dates="cAvailableDates"
    :disabled-dates="cDisabledDates"
    :min-date="cMinDate"
    :max-date="maxDate"
    :first-day-of-week="1"
    :attributes="cAttributes"
    @dayclick="onDayClick"
  >
    <template v-slot:day-popover="{ attributes }">
      <div>
        {{ attributes[0]?.popover.label }}
      </div>
    </template>
  </v-calendar>
</template>

<script>
// https://vcalendar.io/
export default {
  emits: ["update:modelValue", "change"],
  props: {
    minDate: String,
    maxDate: String,
    disabled: { type: Boolean, default: false },
    marker: Array,
    dates: Array,
    disabledDates: Array,
    disabledWeekdays: Array,
  },
  data() {
    return {
      days: [],
    };
  },
  computed: {
    cMinDate() {
      const [firstSelected] = this.days;
      if (firstSelected)
        return new Date(this.minDate) < new Date(firstSelected.date)
          ? this.minDate
          : firstSelected.date.toDateString();
      else return this.minDate;
    },
    cAvailableDates() {
      return this.days.map((day) => day.date);
    },
    cDisabledDates() {
      const selectedDates = this.days.map((day) => day.date.toDateString());
      return this.disabled
        ? {}
        : this.disabledDates
            .filter((date) => !selectedDates.includes(date.toDateString()))
            .concat([{ weekdays: this.disabledWeekdays }])
            .concat([{ start: this.cMinDate, end: this.minDate }]);
    },
    cAttributes() {
      const minDate = new Date(this.minDate);
      return this.days
        .map((day) => ({
          highlight: {
            class: day.date < minDate ? "bg-secondary" : "bg-primary",
            style: { padding: "1.2rem" },
            contentClass: "text-white",
          },
          dates: day.date,
        }))
        .concat(this.marker || []);
    },
  },
  methods: {
    onDayClick(day) {
      if (day.isDisabled) return;

      // toggle day select
      const idx = this.days.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
          id: day.id, // 'yyyy-MM-dd'
          date: day.date, // Date object
        });
      }
      const selectedDates = this.days.map((day) => day.date);
      this.$emit("update:modelValue", selectedDates, day);
      this.$emit("change", selectedDates, day);
    },
    moveTo(date) {
      if (date) this.$refs.calendar.move(date);
    },
  },

  watch: {
    "$props.dates": function (newVal, oldVal) {
      this.days = this.dates.map((date) => ({
        id: date.format("yyyy-MM-dd"),
        date: date,
      }));
    },
  },

  created() {},
};
</script>

<style>
.vc-day .is-disabled:hover {
  cursor: not-allowed;
}

.vc-pane-layout .vc-weeks {
  grid-template-columns: repeat(5, 1fr);
}

.vc-day {
  margin: 2px 0;
}
.vc-day .vc-day-content {
  font-size: large;
  padding: 1.25rem;
}
.vc-day .vc-day-content:focus {
  font-weight: 600;
}

.vc-weekday:first-child,
.vc-weekday:nth-child(7),
.vc-day.weekday-7,
.vc-day.weekday-1 {
  display: none;
}
</style>
