<template>
  <div
    class="alert text-white alert-dismissible fade"
    role="alert"
    :class="getClasses(color, dismissible)"
  >
    <span class="alert-icon">
      <i :class="getIcon(icon)" />
    </span>
    <span class="alert-text">
      {{ icon && "&nbsp;" }}
      <slot />
    </span>
    <button
      v-if="dismissible"
      type="button"
      class="btn-close d-flex justify-content-center align-items-center"
      data-hide="alert"
      aria-label="Close"
      @click="reset"
    >
      <span aria-hidden="true" class="text-lg font-weight-bold">&times;</span>
    </button>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import $ from "jquery";
export default {
  name: "vmd-alert",
  props: {
    color: {
      type: String,
      default: "success",
    },
    icon: String,
    dismissible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getClasses: (color, dismissible) => {
      let colorValue, dismissibleValue;

      colorValue = color ? `alert-${color}` : null;

      dismissibleValue = dismissible ? "show d-block" : "d-none";

      return `${colorValue} ${dismissibleValue}`;
    },
    getIcon: (icon) => (icon ? icon : null),

    reset() {
      // reset alert msg show

      $(function () {
        $("[data-bs-dismiss]").on("click", function () {
          $(this)
            .closest("." + $(this).attr("data-bs-dismiss"))
            .hide();
        });
      });
      this.$emit("update", false);

      console.log("check");
    },
  },
};
</script>
