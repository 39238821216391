<template>
  <div class="input-group">
    <input
      ref="txtPassword"
      type="password"
      class="form-control"
      :class="[isInvalid && 'is-invalid']"
      :value="modelValues.value"
      @change="onChange"
      @input="onInput"
    />
    <i
      class="far fa-fw fa-eye fa-eye-slash btn btn-outline-primary rounded-end m-0"
      id="togglePassword"
      @click="togglePassword"
    ></i>
    <div class="invalid-feedback">{{ errMsg }}</div>
  </div>
  <div v-show="showCriteria" style="font-size: small">
    <label>Contains at least</label>
    <div class="row text-default">
      <div :class="['col-md-6', has8Char && 'text-valid']">
        <i class="fas fa-check"></i> 8 Characters
      </div>
      <div :class="['col-md-6', hasAlphabets && 'text-valid']">
        <i class="fas fa-check"></i> alphabets (a-z)
      </div>
      <div :class="['col-md-6', hasNumeric && 'text-valid']">
        <i class="fas fa-check"></i> numeric (0-9)
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordInput",
  data() {
    return {
      modelValues: this.modelValue,
    };
  },
  props: {
    modelValue: Object, // {value: '', isValid: true}
    errMsg: String,
    isInvalid: Boolean,
    showCriteria: Boolean,
  },
  methods: {
    togglePassword(event) {
      const txt = this.$refs.txtPassword;
      txt.type = txt.type === "password" ? "text" : "password";
      event.target.classList.toggle("fa-eye-slash");
    },
    onChange(event) {
      this.modelValues.value = event.target.value;
      this.modelValues.isValid = this.isValid;
      this.$emit("update:modelValue", this.modelValues);
    },
    onInput(event) {
      this.modelValues.value = event.target.value;
    },
  },

  computed: {
    isValid() {
      return this.has8Char && this.hasAlphabets && this.hasNumeric;
    },
    has8Char() {
      return this.modelValue.value.length >= 8;
    },
    hasAlphabets() {
      return /[a-zA-Z]/g.test(this.modelValue.value);
    },
    hasNumeric() {
      return /[0-9]/g.test(this.modelValue.value);
    },
  },
};
</script>

<style scoped>
.text-default {
  color: #aaaaaa;
}
.text-valid {
  font-weight: bold;
  color: #08c198;
}
.fa-fw {
  text-align: center !important;
  width: 5.25em;
}
</style>
