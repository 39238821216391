<template>
  <div class="container-fluid">
    <VmdAlert class="font-weight-light show" color="danger" :dismissible="error.show"
      v-on:update="(e) => (error.show = e)">
      <span class="text-sm">{{ error.msg }}</span>
    </VmdAlert>

    <!-- <OrderSearch ref="orderSearch" @search="onSearch" /> -->

    <div class="row mt-3">
      <div class="col-12">
        <button class="btn btn-secondary mb-0 me-1" @click="sizeToFit()">
          Size to Fit
        </button>
        <button class="btn btn-secondary mb-0" @click="autoSize()">
          Auto-Size
        </button>
      </div>
    </div>
    <AgGridVue style="width: auto" class="ag-theme-alpine mt-1 mb-3" domLayout="autoHeight" :rowData="gridData"
      :columnDefs="gridSetting.columnDefs" :defaultColDef="gridSetting.defaultColDef" :modules="gridSetting.modules"
      :pagination="true" :paginationPageSize="gridSetting.pageSize" @grid-ready="onGridReady">
    </AgGridVue>

    <div class="modal fade" ref="DeleteModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Order</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <VmdAlert class="font-weight-light show" color="danger" :dismissible="deleteOrderError.show"
              v-on:update="(e) => (deleteOrderError.show = e)">
              <span class="text-sm">{{ deleteOrderError.msg }}</span>
            </VmdAlert>
            Are you sure you want to delete this Order
            <b>#{{ deleteOrderCode }}</b>? Enter master password to proceed.
            <input type="password" class="form-control mt-2" placeholder="Master Password" maxlength="20"
              id="txtMasterPassword" autocomplete="off" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button type="button" class="btn btn-danger" @click="onConfirmDelete">
              Confirm Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VmdAlert from "../components/VmdAlert.vue";
//import OrderSearch from "./components/Order/OrderSearch.vue";
import { AgGridVue } from "@ag-grid-community/vue3";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { PaymentStatus } from "./components/Order/OrderStatus.js";
import { Modal } from "bootstrap";

export default {
  components: { VmdAlert, /*OrderSearch,*/ AgGridVue },
  props: {
    personId: String,
  },
  data() {
    return {
      searchOptions: {},
      gridApi: null,
      gridColumnApi: null,
      gridData: [],
      gridSetting: {
        modules: [ClientSideRowModelModule],
        pageSize: 20,
        defaultColDef: { resizable: true, sortable: true },
        columnDefs: [
          // {
          //   headerName: "No.",
          //   field: "",
          //   suppressSizeToFit: true,
          //   suppressMovable: true,
          //   sortable: false,
          //   cellRenderer: (params) => params.rowIndex + 1,
          // },
          {
            headerName: "Order #",
            field: "code",
            filter: "agTextColumnFilter",
            floatingFilter: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true,
            },
            comparator: (a, b) => a.localeCompare(b),
          },
          {
            headerName: "User",
            field: "username",
            filter: "agTextColumnFilter",
            floatingFilter: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true,
            },
            cellRenderer: (params) =>
              `<a href="/booking/${encodeURIComponent(params.data.bookingId)}/${encodeURIComponent(params.data.personId)}" class="text-decoration-underline">${params.data.username
              }</a>`,
            comparator: (a, b) =>
              a.toLowerCase().localeCompare(b.toLowerCase()),
          },
          {
            headerName: "Phone",
            field: "mobileNo",
            filter: "agTextColumnFilter",
            floatingFilter: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true,
            },
            comparator: (a, b) => a.localeCompare(b),
          },
          {
            headerName: "Activity Type",
            field: "type",
            autoHeight: true,
            sortable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true,
            },
          },
          {
            headerName: "Pax",
            field: "pax",
            autoHeight: true,
            sortable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true,
            },
          },
          {
            headerName: "Payment Status",
            field: "status",
            filter: "agTextColumnFilter",
            floatingFilter: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true,
            },
            cellRenderer: (params) =>
              params.data.status === "pending"
                ? "Pending Payment"
                : params.data.status === "ongoing"
                  ? "Ongoing"
                  : params.data.status === "complete"
                    ? "Completed"
                    : "",
            comparator: (a, b) => {
              a = a === "ongoing" ? 0 : a === "pending" ? 1 : 2;
              b = b === "ongoing" ? 0 : b === "pending" ? 1 : 2;
              return a - b;
            },
          },
          {
            headerName: "Booking Date",
            field: "bookingDate",
            filter: "agTextColumnFilter",
            floatingFilter: true,
            floatingFilterComponentParams: {
              suppressFilterButton: true,
            },
            cellRenderer: (params) =>
              params.data.created.format("dd-MM-yyyy"),
            comparator: (a, b) => {
              return a.valueOf() - b.valueOf();
            },
          },
          /*{
            headerName: "Action",
            field: "bookingId",
            sortable: false,
            suppressMovable: true,
            cellRenderer: (params) => {
            `<a href="/booking/${encodeURIComponent(params.data.bookingId)}" class="text-decoration-underline">View</a>`
              const btnEdit = document.createElement("a"); // edit btn
              btnEdit.href = "#";
              btnEdit.className = "fa fa-pen d-block text-dark m-0 p-3";
              btnEdit.addEventListener("click", (e) => {
                e.preventDefault();
                window.location = `/booking/${encodeURIComponent(
                  params.data.bookingId
                )}`;
              });
              const btnDelete = document.createElement("a"); // delete btn
              btnDelete.href = "#";
              btnDelete.className = "fa fa-trash d-block text-danger m-0 p-3";
              btnDelete.addEventListener("click", (e) => {
                e.preventDefault();
                this.onDelete(params.data.bookingId, params.data.code);
              });
              const div = document.createElement("div");
              div.className = "d-flex";
              div.appendChild(btnEdit);
              div.appendChild(btnDelete);
              return div;
            },
          },*/
        ],
      },
      error: { show: false, msg: "" },
      deleteOrderModal: null,
      deleteOrderCode: "",
      deleteOrderId: null,
      deleteOrderError: { show: false, msg: "" },
    };
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      this.onSearch({ customerId: this.customerId });
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    autoSize() {
      this.gridColumnApi.autoSizeAllColumns(false);
    },

    onSearch(searchValues, stopLoading) {
      const data = { ...searchValues };
      if (searchValues.date) {
        data.date = searchValues.date.map((d) => d.format("yyyy-MM-dd"));
      }
      this.postApi("api/booking/search", data).then((res) => {
         console.log("order/search", res);
        if (res.flag === 1) {
          this.error.show = false;
          res.data.forEach((d) => {
            d.status = PaymentStatus(d.paymentStatus);
            d.created = new Date(d.created);
          });
          this.gridData = res.data;
          this.autoSize();
          this.sizeToFit();
        } else {
          this.showError(res.msg);
        }

        if (stopLoading) stopLoading();
      });
    },

    showError(msg) {
      this.error.show = true;
      this.error.msg = msg;
    },

    onDelete(id, code) {
      this.deleteBookingId = id;
      this.deleteOrderCode = code;
      document.getElementById("txtMasterPassword").value = "";
      this.deleteOrderError.show = false;
      this.deleteOrderError.msg = "";
      this.deleteOrderModal.show();
    },
    async onConfirmDelete() {
      const res = await this.postApi("api/booking/delete", {
        bookingId: this.deleteBookingId,
        masterPassword: document.getElementById("txtMasterPassword").value,
      });
      if (res.flag === 1) {
        this.deleteBookingId = null;
        this.deleteOrderModal.hide();
        this.onSearch({ customerId: this.customerId });
      } else {
        this.deleteOrderError.show = true;
        this.deleteOrderError.msg = res.msg;
      }
    },
  },

  async created() {
    // let res = await this.getApi("api/order/search-option");
    // console.log("search-option", res);
    // if (res.flag === 1) {
    //   this.error.show = false;
    //   this.searchOptions = {
    //     orderCode: res.data.order.map((o) => ({ label: o.code, value: o.id })),
    //     customer: res.data.customer.map((c) => ({ label: c.customer, value: c.id })),
    //     phone: res.data.customer.map((c) => ({ label: c.phone, value: c.id })),
    //   };
    //   this.$refs.orderSearch.bind(this.searchOptions);
    // } else {
    //   this.showError(res.msg);
    //   return;
    // }
  },
  mounted() {
    this.deleteOrderModal = new Modal(this.$refs.DeleteModal);
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
