<style>
.btn-outline-secondary:hover {
  color: white !important;
  background-color: #7b809a !important;
  border-color: #7b809a !important;
}

.accountstyle .fa-pen::before {
  margin-right: 0px !important;
}

.btn-cancel:hover {
  color: black !important;
}
</style>
<template>
  <vmd-alert class="font-weight-light" color="danger" :dismissible="dangerAlert" v-on:update="UpdateAlert($event)">
    <span class="text-sm">{{ dangermsg }}</span>
  </vmd-alert>
  <vmd-alert class="font-weight-light" color="success" :dismissible="SuccessAlert" v-on:update="UpdateAlert($event)">{{
    Successmsg }}</vmd-alert>
  <div class="col-12 d-flex justify-content-end accountstyle">
    <button class="btn btn-outline-secondary" @click="EnableEdit">
      <span class="fa fa-pen"></span> Edit
    </button>
  </div>

  <div class="row">
    <div class="col-12 col-lg-4 mb-2">
      <label>Full Name</label>
      <TextInput placeholder="Name" :trim="true" :is-invalid="!formData.name.isValid" @blur="validateName()"
        v-model="formData.name.value" err-msg="Invalid Name." :isDisable="isEdit" />
    </div>
    <div class="col-12 col-lg-4 mb-2">
      <label>Email Address</label>
      <TextInput placeholder="example@example.com" :trim="true" :is-invalid="!formData.email.isValid"
        @blur="validateEmail()" v-model="formData.email.value" err-msg="Invalid Email." :isDisable="isEdit" />
    </div>
    <div class="col-12 col-lg-4 mb-2">
      <label class="d-block">Phone Number</label>
      <MobileInput :is-invalid="!formData.mobile.isValid" v-model="formData.mobile" err-msg="Invalid mobile number."
        :disable="isEdit" />
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12 mb-2">
      <label>Admin's Remarks</label>
      <TextInput placeholder="VIP Customer" :trim="true" :is-invalid="false" v-model="formData.remark.value"
        err-msg="Invalid Name." :isDisable="isEdit" />
    </div>
  </div>

  <div class="row mt-2">
    <h6 class="text-secondary">Customer's Master Settings</h6>
    <div class="col-12 col-lg-4 mb-2">
      <label>Soup</label>
      <select :disabled="isEdit" class="form-select" v-model="formData.SoupselectedValue.value">
        <option value="">System Default</option>
        <option value="B16">B16</option>
        <option value="S1">S1</option>
      </select>
    </div>
    <div class="col-12 col-lg-4 mb-2">
      <label>Soup</label>
      <select :disabled="isEdit" class="form-select" v-model="formData.RiceselectedValue.value">
        <option value="">System Default</option>
        <option value="R1">R1</option>
      </select>
    </div>
  </div>
  <div v-if="!isEdit" class="d-flex justify-content-end mt-3">
    <button class="btn btn-cancel me-3" @click="getdata(true)"><u> Cancel</u></button> <button class="btn btn-primary "
      @click="onSubmit"> Save</button>
  </div>
</template>
<script>
import TextInput from "../components/TextInput.vue";
import MobileInput from "../components/MobileInput.vue";
import PasswordInput from "../components/PasswordInput.vue";
import VmdAlert from "@/components/VmdAlert.vue";
export default {
  data() {
    return {
      formData: {
        name: { value: "", isValid: true },
        email: { value: "", isValid: true },
        mobile: { value: "", dialCode: "60", isValid: true },
        remark: { value: "", isValid: true },
        SoupselectedValue: { value: "", isValid: true },
        RiceselectedValue: { value: "", isValid: true },
      },
      isEdit: true,
      SuccessAlert: false,
      dangerAlert: false,
      Successmsg: null,
      dangermsg: null
    };
  },
  props: {
    userId: {
      type: String,
      default: null,
    },
  },
  components: {
    TextInput,
    PasswordInput,
    MobileInput,
    VmdAlert,
  },
  methods: {

    UpdateAlert(value) {
      this.SuccessAlert = value;
      this.dangerAlert = value;

    },
    async getdata(CancelClick = false) {
      await this.postApi("api/user/Detail", {
        id: decodeURIComponent(this.$route.params.id),
      }).then(async (result) => {
        if (result.flag === 1) {
          this.formData.name.value = result.data[0].name;
          this.formData.email.value = result.data[0].email;
          this.formData.mobile.value = result.data[0].mobile;
          this.formData.remark.value = result.data[0].remark == null ? "" : result.data[0].remark;
          this.formData.SoupselectedValue.value = result.data[0].soupPackingType;
          this.formData.RiceselectedValue.value = result.data[0].ricePackingType;
          this.dangerAlert = false;
          if (CancelClick) {

            this.isEdit = true;
          }
        }
        else {
          this.dangerAlert = true;
          this.dangermsg = result.msg
        }
      });
    },
    onSubmit() {
      // check empty field

      Object.keys(this.formData).forEach((k) => {
        if (this.formData[k].value.length === 0) this.formData[k].isValid = false;
        if (this.formData[k].value == "" && k == "remark") this.formData[k].isValid = true;
        if (this.formData[k].value == "" && k == "SoupselectedValue") this.formData[k].isValid = true;
        if (this.formData[k].value == "" && k == "RiceselectedValue") this.formData[k].isValid = true;
      });

      const isFormValid = Object.keys(this.formData).every(
        (k) => this.formData[k].isValid
      );

      if (!isFormValid) {
        return;
      }

      this.postApi("api/user/update", {

        selectedId: decodeURIComponent(this.$route.params.id),
        name: this.formData.name.value,
        email: this.formData.email.value,
        mobile: this.formData.mobile.value,
        remark: this.formData.remark.value,
        soup: this.formData.SoupselectedValue.value,
        rice: this.formData.RiceselectedValue.value
      }).then((result) => {
        if (result.flag === 1) {
          this.SuccessAlert = true;
          this.dangerAlert = false;
          this.Successmsg = "Update Successful"
          this.getdata();
        } else {
          this.SuccessAlert = false;
          this.dangerAlert = true;
          this.dangermsg = result.msg

        }
      });
    },
    EnableEdit() {
      this.isEdit = !this.isEdit;
    },
    validateName() {
      const name = this.formData.name;
      const length = name.value.length;
      name.isValid = length >= 2 && length <= 100;
    },
    validateEmail() {
      const email = this.formData.email;
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      email.isValid = regex.test(email.value);
    },
  },
  beforeMount() {
    this.getdata();
  },
};
</script>
