import { createApp } from "vue";
import { VueCookieNext } from 'vue-cookie-next'
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import MaterialDashboard from "./material-dashboard";
import 'v-calendar/dist/style.css';
import VCalendar from "v-calendar";
import * as axios from './axios';
import './common';


const Common = {
  methods: {
    postApi(api, body) {
      return axios.postApi(api, body, this.$cookie.getCookie("ADMIN_TOKEN")).then(res => {
        this.$cookie.setCookie("ADMIN_TOKEN", res.tkn);
        return res;
      }
      );
    },
    getApi(api) {
      return axios.getApi(api, this.$cookie.getCookie("ADMIN_TOKEN")).then(res => {
        this.$cookie.setCookie("ADMIN_TOKEN", res.tkn);
        return res;
      });
    },
    formatImageUrl(path) {
      return process.env.VUE_APP_IMG_URL + path;
    }
  },
}

const appInstance = createApp(App);

appInstance.config.globalProperties.$globalVariable = null // global variable
appInstance.mixin(Common);
appInstance.use(store);

appInstance.use(router);
appInstance.use(VueCookieNext);
appInstance.use(MaterialDashboard);
appInstance.use(VCalendar);
appInstance.mount("#app");
