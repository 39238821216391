<template>
  <div class="row m-0">
    <!-- LEFT -->
    <div class="col-md-6 row m-0 order-md-1">
      <div class="col-3 d-flex my-2">
        <div class="fw-bold my-auto">Date</div>
      </div>
      <div class="col-9 my-2">
        <DatePicker
          ref="datepicker"
          range
          autoApply
          month-name-format="long"
          locale="en-US"
          :clearable="true"
          :enable-time-picker="false"
          :week-start="0"
          :format="(dates) => dates.map((d) => d.format()).join(' - ')"
          :month-change-on-scroll="'inverse'"
          v-model="value.date"
        >
        </DatePicker>
      </div>
    </div>
    <div class="col-md-6 row m-0 order-md-2">
      <div class="col-3 d-flex my-2">
        <div class="fw-bold my-auto">Order ID</div>
      </div>
      <div class="col-9 my-2">
        <Multiselect
          placeholder="Order ID"
          :options="options.order"
          :searchable="true"
          :can-clear="true"
          :can-deselect="false"
          :close-on-select="true"
          v-model="value.orderId"
        />
      </div>
    </div>
    <!-- LEFT END -->

    <!-- RIGHT -->
    <div class="col-md-6 row m-0 order-md-1">
      <div class="col-3 d-flex my-2">
        <div class="fw-bold my-auto">Customer ID</div>
      </div>
      <div class="col-9 my-2">
        <Multiselect
          placeholder="Customer ID"
          :options="options.custNo"
          :searchable="true"
          :can-clear="true"
          :can-deselect="false"
          :close-on-select="true"
          v-model="value.custId"
        />
      </div>
    </div>
    <div class="col-md-6 row m-0 order-md-2">
      <div class="col-3 d-flex my-2">
        <div class="fw-bold my-auto">Customer Name</div>
      </div>
      <div class="col-9 my-2">
        <Multiselect
          placeholder="Customer Name"
          :options="options.custName"
          :searchable="true"
          :can-clear="true"
          :can-deselect="false"
          :close-on-select="true"
          v-model="value.custId"
        />
      </div>
    </div>
    <div class="col-md-6 row m-0 order-md-2">
      <div class="col-3 d-flex my-2">
        <div class="fw-bold my-auto">Status</div>
      </div>
      <div class="col-9 my-2">
      

        <Multiselect
      v-model="value.optionsStatus"
      :options="optionsStatus"
      :multiple="true"
      track-by="value"
      label="name"
      placeholder="Status"
    ></Multiselect>
        <!-- <Multiselect
          placeholder="Status"
          :options="options.statusText"
          :searchable="true"
          :can-clear="true"
          :can-deselect="false"
          :close-on-select="true"
          v-model="value.status"
        /> -->
      </div>
    </div>
    <!-- RIGHT END -->

    <div class="col-12 mt-3 text-md-end order-last px-4">
      <SubmitButton
        class="btn btn-primary me-md-2 col-12 col-md w-md-auto mb-md-0"
        @click="onSearch"
      >
        Search
      </SubmitButton>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect"; // https://github.com/vueform/multiselect
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import SubmitButton from "/src/views/components/Order/SubmitButton.vue";


export default {
  emits: ["search", "error"],
  components: { Multiselect, DatePicker, SubmitButton },
  data() {
    return {
      selectedStatus: null,
      optionsStatus: [
        { value: '', name: 'All Status' },
        { value: '0', name: 'Pending Upload' },
        { value: '1', name: 'Pending Approve' },
        { value: '2', name: 'Approved' },
        { value: '3', name: 'Rejected' },
      ],
      options: {
        custNo: [],
        custName: [],
        order: [],
      },
      value: {
        date: [],
        custId: "",
        orderId: "",
        optionsStatus: "1",
      },
    };
  },
  methods: {
    async getSearchOptions() {
      const res = await this.getApi("api/payment/search-option");
      // console.log("search-option", res);
      if (res.flag === 1) {
        this.options.order = res.data.map((order) => ({
          label: order.code,
          value: order.orderId,
        }));

        let customer = res.data.map((d) => [d.custId, d]);
        customer = [...new Map(customer).values()];
        this.options.custNo = customer.map((c) => ({
          label: c.userCode,
          value: c.custId,
        }));
        this.options.custName = customer.map((c) => ({
          label: `${c.name} (${c.userCode})`,
          value: c.custId,
        }));
      } else {
        this.$emit("error", res.msg);
      }

    },
    onSearch(stopLoading) {
      this.$emit("search", this.value, stopLoading);
    },
  },

  created() {
    this.getSearchOptions();
  },
  mounted() {
    this.onSearch();
  },
};
</script>
