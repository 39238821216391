<template>
  <div v-if="data != null">
    <div class="row">
      <div class="col-lg-4 h-100">
        <img :src="packageImage" class="package-image" v-if="packageImage" />
        <img src="/src/assets/05_Product.jpg" class="package-image" v-else />
        <div v-if="data.isOldProduct === 1" class="text-muted fst-italic mt-1" style="font-size:12px;">(Old Package)</div>
      </div>
      <div class="col-lg-8">
        <div class="h6 text-primary">{{ data.name }}</div>
        <div class="text-secondary">
          <small><b>Scheduled Date:</b>{{ new Date(data.scheduledDate).format("dd MMM yyyy") }}</small>
        </div>
        <div class="text-secondary">
          <small v-if="data.adult > 0" class="mr-1">
            {{ data.adult + '-Adult' }}
          </small>
        </div>
        <div class="text-secondary">
          <small v-if="data.children > 0" class="mr-1">
            {{ data.children + '-Children' }}
          </small>
        </div>
        <div class="text-secondary">
          <small v-if="data.infant > 0" class="mr-1">
            {{ data.infant + '-Infant' }}
          </small>
        </div>
        <!-- <div v-if="editable" class="mt-2">
          <SubmitButton
            class="btn btn-outline-secondary"
            spinner-class="text-secondary"
            @click="onEdit"
          >
            Edit
          </SubmitButton>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import SubmitButton from "./SubmitButton.vue";
export default {
  components: { SubmitButton },
  emits: ["edit"],
  props: {
    data: Object,
    editable: Boolean,
  },
  computed: {
    packageImage() {
      return this.data.image;
    },
    /*orderOptions() {
      return `${this.data.packing}, ${this.data.whiteRice} White Rice, ${this.data.handover}`;
    },
    orderMealReq() {
      const mq = this.data.mealReq?.reduce(
        (prev, curr) => (prev += curr.name + ", "),
        ""
      );
      return mq.substring(0, mq.lastIndexOf(", "));
    },*/
  },
  methods: {
    /*onEdit(stopLoading) {
      this.$emit("edit", this.data, stopLoading);
    },*/
  },
};
</script>

<style scoped>
.package-image {
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  max-width: 120px;
  max-height: 120px;
}
</style>
